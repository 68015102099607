import React, { useEffect, Component, Fragment, useState } from 'react'
import gql from 'graphql-tag'
import { Subscription, Mutation } from 'react-apollo'
import { useMutation } from '@apollo/react-hooks'
import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  LockOutlined,
  UnlockOutlined
} from '@ant-design/icons'
import { Checkbox, Alert, Table, Popconfirm, Tooltip, notification, Card, Form, Select, Input, Modal, Button, InputNumber, Tabs } from 'antd'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { 
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { StreamsTabular, StreamsSelect, StreamsRadio } from './StreamsTable'
import { FormItemWrapper, StrategyFormItem } from './common'


const { TabPane } = Tabs

const TRADE_RULES = gql`
  subscription {
    trade_rule(order_by: { priority: asc }) {
      id
      name
      label: name
      key: priority 
      priority
      strategy_type
      lock_type
      take_profit
      stop_loss
      trailing_trigger
      initial_step 
      trailing_step 
      delay
      spot
      spot_max
      spot_step
      spot_base
      execution_count_limit
      spread
      spread_base
      spread_step
      trailing_stop_step 
      timed_martingale_reset
      auto_price_adjust
      is_timed_reset 
      spot_increment_base
      spot_increment_step
      spot_increment_max
      spot_increment_min
      count_increment
      trade_mode
      stream_mode
      use_limit_order
      trade_rule_streams {
        id
        type
        order_type
        ttl
        depth
        stream {
          id
          account_name
          connector {
            id
            name
          }
        }
      }
    }
  }
`

const UPDATE_OR_CREATE_RULE = gql`
  mutation($name: String, $rule: trade_rule_insert_input!) {
    delete_trade_rule_stream(where: { trade_rule: { name: { _eq: $name } } }) {
      returning {
        id
      }
    }
    insert_trade_rule(
      objects: [$rule],
      on_conflict: {
        constraint: trade_rule_name_key,
        update_columns: [
          name
          spot
          strategy_type
          lock_type
          take_profit
          stop_loss
          trailing_trigger
          initial_step 
          trailing_step 
          delay
          spot_max
          spot_step
          spot_base
          execution_count_limit
          spread
          spread_base
          spread_step
          trailing_stop_step 
          timed_martingale_reset
          auto_price_adjust
          is_timed_reset 
          spot_increment_base
          spot_increment_step
          spot_increment_max
          spot_increment_min
          count_increment
          trade_mode
          stream_mode
          use_limit_order
      ] }
    ) {
      returning {
        id
      }
    }
  }
`

const DELETE_RULE = gql`
  mutation($id: Int) {
    delete_trade_rule(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`

const UPDATE_RULE_ORDER = gql`
mutation ($id: Int!, $priority: Int!){
  update_trade_rule(
    where: {id: {_eq: $id}},
    _set: {priority: $priority},
  ){
    returning{
      id
      priority
    }
  }
}
`

const MartingaleTable = (rule) => {
  let columns = []
  rule.lock_type === 'trailing-stop' ?
    columns = [
      { title: 'Layer', dataIndex: 'layer', key: 'layer', align: 'center' },
      { title: 'Spread', dataIndex: 'spread', key: 'spread', align: 'center' },
      { title: 'Volume', dataIndex: 'volume', key: 'volume', align: 'center' },
      { title: 'Trailing Trigger', dataIndex: 'trailing_trigger', key: 'trailing_trigger', align: 'center' },
      { title: 'Initial Step', dataIndex: 'initial_step', key: 'initial_step', align: 'center' },
      { title: 'Trailing Step', dataIndex: 'trailing_step', key: 'trailing_step', align: 'center' },
      { title: 'Stop Loss', dataIndex: 'stop_loss', key: 'stop_loss', align: 'center' },
      { title: 'Max Trades', dataIndex: 'max_trade', key: 'max_trade', align: 'center' },
    ]

    :
    columns = [
      { title: 'Layer', dataIndex: 'layer', key: 'layer', align: 'center' },
      { title: 'Spread', dataIndex: 'spread', key: 'spread', align: 'center' },
      { title: 'Volume', dataIndex: 'volume', key: 'volume', align: 'center' },
      { title: 'Max Trades/Layer', dataIndex: 'max_trade', key: 'max_trade', align: 'center' },
    ]

  const data = []
  for(let i = 0; i < rule.execution_count_limit; i++) {
    const trigger_step_index = (Math.min(rule.spot_base + i*rule.spot_step, rule.spot_max) - rule.spot_base)/rule.spot_step
    rule.lock_type === 'trailing-stop' ?
      data.push({
        'layer': i,
        'spread': Math.round((rule.spread_base + rule.spread_step*i) * 100000) / 100000 ,
        'volume': Math.min(rule.spot_base + i*rule.spot_step, rule.spot_max),
        'trailing_trigger': Math.round((rule.trailing_trigger + rule.trailing_stop_step*trigger_step_index) * 100000) / 100000 ,
        'initial_step': Math.round((rule.initial_step + rule.trailing_stop_step*trigger_step_index)* 100000) / 100000 ,
        'trailing_step': Math.round((rule.trailing_step + rule.trailing_stop_step*trigger_step_index) * 100000) / 100000 ,
        'stop_loss': Math.round((rule.stop_loss - rule.trailing_stop_step*trigger_step_index) * 100000) / 100000 ,
        'max_trade': i+1
      })
      :
      data.push({
        'layer': i,
        'spread': Math.round((rule.spread_base + rule.spread_step*i) * 100000) / 100000 ,
        'volume': Math.min(rule.spot_base + i*rule.spot_step, rule.spot_max),
        'max_trade': i+1
      })
  }

  return (
    <div style={{ marginLeft: 100, marginRight: 100, textAlign: 'center' }} >
      <Table 
        size='small'
        columns={columns}
        dataSource={data}
        rowKey='layer'
      />
    </div>
  )
}

const TradeRuleFormHook = ({ rule, createRule }) => {
  const [form] = Form.useForm()
  const [isDynamic, setDynamic] = useState(rule && rule.trade_mode === 'dynamic')
  const [isMartingale, setMartingale] = useState(rule && rule.trade_mode === 'martingale')
  const [isTimedReset, setIsTimedReset] = useState(false)
  const [isTrailingStop, setTrailingStop] = useState(rule && rule.lock_type === 'trailing-stop')

  const handleLockType = (value) => {
    setTrailingStop(value === 'trailing-stop')
  }

  const handleTradeMode = (value) => {
    setDynamic(value === 'dynamic')
    setMartingale(value === 'martingale')
  }

  const onFinish = values => {
    updateCreateRule(values)
    rule.id ?
      notification.open({
        message: 'Rule Saved',
        icon: <SaveOutlined />
      })
      :
      form.resetFields()
  }

  const updateCreateRule = e => {
    const trade_rule_streams = {
      data: (
        Object
          .entries(e.trade_rule_streams)
          .filter(([stream_id, { order_type, ttl, type }]) => (
            e.strategy_type === 'lock' ? (
              (Number.isInteger(ttl) && order_type === 'limit') || order_type === 'market'
            ) : e.stream_mode === 'master-slave' ? type : true
          ))
          .map(([stream_id, { stream, __typename, ...rest }]) => ({
            stream_id: parseInt(stream_id),
            ...rest,
          }))
      )
    }

    const result = {
      ...e,
      trade_rule_streams,
    }

    createRule({
      variables: {
        name: result.name,
        rule: result,
      }
    })
  }

  useEffect(() => {
    setIsTimedReset(rule.is_timed_reset)
  }, [rule])

  const convertRuleToFormValues = rule => {
    const trade_rule_streams = Object.fromEntries((rule.trade_rule_streams || []).map(
      (rule_stream) => [String(rule_stream.stream.id), rule_stream]
    ))
    return {
      ...rule,
      trade_rule_streams,
    }
  }

  return (
    <Form
      form={form}
      size='small'
      hideRequiredMark={true}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      onFinish={onFinish}
      initialValues={rule ? convertRuleToFormValues(rule) : {}}
    >
      <div style={{ textAlign: 'right' }}>
        <Fragment>
          <Button
            icon={<SaveOutlined />}
            type='primary'
            htmlType='submit'
          />
        </Fragment>
      </div>

      <Form.Item label="Name" name="name"
        rules={[
          {
            required: true,
            message: 'Please input rule name',
          },
        ]} >
        <Input />
      </Form.Item> 
      <StrategyFormItem handleLockType={handleLockType} ruleType='trade-rule' rule={rule}/>

      <Form.Item label="Volume" name='spot' hidden={isMartingale}
        rules={[
          {
            required: true,
            message: 'Please input volume',
          },
        ]} >
        <InputNumber />
      </Form.Item> 

      <Form.Item label="Count" name='execution_count_limit'
        rules={[
          {
            required: true,
            message: 'Please input count',
          },
        ]} >
        <InputNumber />
      </Form.Item> 



      <Form.Item label="Spread" name='spread' hidden={isMartingale}
        rules={[
          {
            required: true,
            message: 'Please input spread',
          },
        ]} >
        <InputNumber />
      </Form.Item> 

      <Form.Item label="Trade Mode" name='trade_mode'
        rules={[
          {
            required: true,
            message: 'Please select a trade mode',
          },
          //{
          //  validator: async (rule, value) => {
          //     return (value == 'martingale'
          //      && !((form.getFieldValue('strategy_type') ? form.getFieldValue('strategy_type') == 'standard' : rule.strategy_type == 'standard')
          //      || (form.getFieldValue('lock_type') ? form.getFieldValue('lock_type') == 'immediate' : rule.lock_type == 'immediate')))
          //      ?
          //       Promise.reject('Only use Martingale with Standard or Lock Immediate Strategy')
          //      :
          //       Promise.resolve()
          //  },
          //},
        ]} >
        <Select onChange={e => handleTradeMode(e)} >
          <Select.Option value="static">Static</Select.Option>
          <Select.Option value="dynamic">
            <Tooltip placement="bottom" title='Use dynamic mode only for instruments with step 1000'>
              Dynamic
            </Tooltip>
          </Select.Option>
          <Select.Option value="martingale">
            <Tooltip placement="bottom" title='Use martingale only with standard or lock immediate startegy or lock immediate startegy'>
              Martingale
            </Tooltip>
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="Step" name='spot_step' hidden={!isDynamic}
        rules={[
          {
            required: isDynamic,
            message: 'Please input step',
          },
        ]} >
        <InputNumber min={1} />
      </Form.Item> 

      <Form.Item label="Volume Max" name='spot_max' hidden={!isDynamic}
        rules={[
          {
            required: isDynamic,
            message: 'Please input volume max',
          },
          {
            validator: async (rule, value) => {
              const spot = await form.getFieldValue('spot')
              if (!isDynamic || value >= spot)
                return Promise.resolve()
              return Promise.reject('Max volume can\'t be less than volume') },
          },
        ]} >
        <InputNumber min={0} />
      </Form.Item> 

      <Form.Item label="Volume Base" name='spot_base' hidden={!isMartingale}
        rules={[
          {
            required: isMartingale,
            message: 'Please input spot base',
          },
        ]} >
        <InputNumber min={1} />
      </Form.Item> 

      <Form.Item label="Volume Step" name='spot_step' hidden={!isMartingale}
        rules={[
          {
            required: isMartingale,
            message: 'Please input spot step',
          },
        ]} >
        <InputNumber min={1} />
      </Form.Item> 

      <Form.Item label="Volume Max" name='spot_max' hidden={!isMartingale}
        rules={[
          {
            required: isMartingale,
            message: 'Please input volume max',
          },
          {
            validator: async (rule, value) => {
              const spot_base = await form.getFieldValue('spot_base')
              if (!isMartingale|| value >= spot_base) return Promise.resolve()
              return Promise.reject('Max volume can\'t be less than volume')
            },
          },
        ]} >
        <InputNumber min={0} />
      </Form.Item> 

      <Form.Item label="Spread Base" name='spread_base' hidden={!isMartingale}
        rules={[
          {
            required: isMartingale,
            message: 'Please input spread base',
          },
        ]} >
        <InputNumber />
      </Form.Item> 

      <Form.Item label="Spread Step" name='spread_step' hidden={!isMartingale}
        rules={[
          {
            required: isMartingale,
            message: 'Please input spread step',
          },
        ]} >
        <InputNumber min={0} />
      </Form.Item> 

      <Form.Item label="Trailing Stop Step" name='trailing_stop_step' hidden={!(isTrailingStop && isMartingale)}
        rules={[
          {
            required: isTrailingStop && isMartingale,
            message: 'Please input trailing stop step',
          },
        ]} >
        <InputNumber min={0} />
      </Form.Item> 

      <Form.Item label="Allow Timed Reset" name='is_timed_reset' hidden={!isMartingale}
        valuePropName="checked"
        rules={[
          {
            required: false,
          },
        ]} >
        <Checkbox
          onChange={(e) => setIsTimedReset(e.target.checked)}
        />
      </Form.Item> 

      <Form.Item label="Timed Reset(s)" name='timed_martingale_reset' hidden={!(isMartingale && isTimedReset)}
        rules={[
          {
            required: false,
            message: 'Please input spread',
          },
        ]} >
        <InputNumber min={5} />
      </Form.Item> 

      <Form.Item label="Auto Price Adjust" name='auto_price_adjust'
        valuePropName="checked"
        rules={[
          {
            required: false,
          },
        ]} >
        <Checkbox
          onChange={(e) => console.log("Adjust price: ", e.target.checked)}
        />
      </Form.Item> 

      {isMartingale && (MartingaleTable(rule))}



      <FormItemWrapper
        fields={['strategy_type']}
        noStyle
      >
        {({ fields: { strategy_type } }) => strategy_type === 'standard' && (
          <Form.Item label="Stream Mode" name='stream_mode'
            rules={[
              {
                required: true,
                message: 'Please select a stream mode',
              },
            ]}>
            <Select>
              <Select.Option value="standard">Standard</Select.Option>
              <Select.Option value="master-slave">Master Slave</Select.Option>
            </Select>
          </Form.Item>
        )}
      </FormItemWrapper>

      <FormItemWrapper
        label="Streams" 
        rules={[
          {
            required: true,
            message: 'Please select streams',
          },
        ]}
        fields={['stream_mode', 'strategy_type']}
      >
        {({ fields: { stream_mode, strategy_type } }) => {
          return strategy_type === 'lock' ? (
            <Form.Item name="trade_rule_streams">
              <StreamsTabular prefix='trade_rule_streams' />
            </Form.Item>
          ) : stream_mode === 'master-slave' ? 
            (
              <Form.Item name="trade_rule_streams" >
                <StreamsSelect />
              </Form.Item>
            ) 
            : stream_mode === 'standard' ?
              (
                <Form.Item name="trade_rule_streams" >
                  <StreamsRadio />
                </Form.Item>
              ) 
              : null
        }}

      </FormItemWrapper>


      <div style={{ textAlign: 'right' }}>
        {rule
          ? <Fragment>
            <Mutation mutation={DELETE_RULE}>
              {(deleteRule) => (
                <Popconfirm
                  title="Are you sure to delete this rule?"
                  onConfirm={
                    () => {
                      rule.id ?
                        deleteRule({
                          variables: {
                            id: rule.id,
                          }
                        })
                        : 
                        console.log('close Modla')
                      form.resetFields()
                    }
                  }
                  onCancel={() => console.log('close Modal')}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              )}
            </Mutation>
            &nbsp;
            <Button
              icon={<SaveOutlined />}
              type='primary'
              htmlType='submit'
            />
          </Fragment>
          : <Fragment>
            <Button
              icon={<CloseOutlined />}
              onClick={form.resetFields()}
            />
            &nbsp;
            <Button
              icon={<PlusOutlined />}
              type='primary'
              htmlType='submit'
            />
          </Fragment>
        }
      </div>
    </Form>
  )
}



const DraggableTabNode = ({ className, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props["data-node-key"],
    })
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleX: 1,
      }
    ),
    transition,
    cursor: "move",
  }

  return React.cloneElement(props.children, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  })
}


const TradeRuleTable = (props) => {
  const [visible, setVisible] = useState(false)
  const [search, setSearch] = useState('')
  const [lockSort, setLockSort] = useState(true)

  const transformedRules = props.rules.map(rule => ({
    ...rule,
    key: rule.id,
    label: rule.name,
  }))

  const [items, setItems] = useState(transformedRules)
  const [updateRuleOrder] = useMutation(UPDATE_RULE_ORDER)
    
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  })

  const onDragEnd = async ({ active, over }) => {
    if(search != '' || lockSort) return
    if (active.id !== over?.id) {
      const previous = items
      const activeIndex = previous.findIndex((i) => i.key === active.id)
      const overIndex = previous.findIndex((i) => i.key === over?.id)

      const newOrder = arrayMove(previous, activeIndex, overIndex)
      setItems([...newOrder])
      const mutationPromises = newOrder.map((currentRule, index) => {
        return updateRuleOrder({
          variables: {
            id: currentRule.id,
            priority: index,
          }
        })
      })

      try {
        await Promise.all(mutationPromises)
        // If successful, subscription will eventually update the state
      } catch (error) {
        // If there's an error, revert to the previous state
        setItems([...previous])
      }

      return items
    }
  }

  const showModal = () => {
    setVisible(true) 
  }
  
  const handleOk = e => {
    setVisible(false) 
  }

  const handleCancel = e => {
    setVisible(false) 
  }

  const onChange = (e) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    const filteredRules = props.rules.filter(rule =>
      rule.name.toLowerCase().includes(search.toLowerCase())
    )
    const transformedRules = filteredRules.map(rule => ({
      ...rule,
      key: rule.id.toString(),
      label: rule.name,
    })) 

    const updatedRules = transformedRules.map(rule => ({
      ...rule,
      children: (
        <Mutation mutation={UPDATE_OR_CREATE_RULE}>
          {createRule => <TradeRuleFormHook rule={rule} createRule={createRule} />}
        </Mutation>
      ),
    }))

    setItems(updatedRules)


  }, [props.rules, search, lockSort])


  return (
    <Card
      title={
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <span>Trade Rules</span>
          <Button
            onClick={() => setLockSort(!lockSort)}
            icon={lockSort ? <LockOutlined /> : <UnlockOutlined />}
          />
        </div>
      }
      type='inner'
      size='small'
      actions={[
        <div style={{ textAlign: 'right', paddingRight: '8px' }} key='add-trade-rule' >
          <Button
            className="ant-btn ant-input-search-button ant-btn-primary ant-btn-sm"
            onClick={showModal}
          >
            Add Rule <PlusOutlined />
          </Button>
        </div>
      ]}
      bordered={false}
    >
      <Input
        value={search}
        placeholder="Search"
        onChange={onChange}
        style={{margin:'8px', width:'30%'}}
      />
      <Tabs
        items={items}
        defaultActiveKey={props.rules[0]?.key}
        size='small'
        tabPosition='left'
        onEdit={(targetKey, action) => console.log('action',action)}
        renderTabBar={(tabBarProps, DefaultTabBar) => (
          <DndContext sensors={[sensor]} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              items={
                items.map((i) => 
                  i.id.toString()
                )
              }
              strategy={verticalListSortingStrategy}
            >
              <DefaultTabBar {...tabBarProps}>
                {
                  (node) => 
                    <DraggableTabNode {...node.props} key={node.key.toString()} >
                      {node}
                    </DraggableTabNode>
                }
              </DefaultTabBar>
            </SortableContext>
          </DndContext>
        )}
      />
      <Modal
        title="Add Rule"
        open={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Mutation mutation={UPDATE_OR_CREATE_RULE} update={handleOk}>
          {(createRule) => (
            <TradeRuleFormHook rule={{}} createRule={createRule} />
          )}
        </Mutation>
      </Modal>
    </Card>
  )
}


class TradeRuleTableContainer extends Component {
  render() {
    return (
      <Subscription
        subscription={TRADE_RULES}
      >
        {({ data, loading, subscribeToMore }) => {
          if (!data) {
            return null
          }
    
          if (loading) {
            return <span>Loading ...</span>
          }
    
          return (
            <TradeRuleTable
              {...this.props}
              rules={data.trade_rule}
              subscribeToMore={subscribeToMore}
            />
          )
        }}
      </Subscription>
    )
  }
}



class RuleSelect extends Component {
  render() {
    const props = this.props
    return (
      <Subscription
        subscription={TRADE_RULES}
      >
        {({ data, loading, subscribeToMore }) => {
          if (!data) {
            return null
          }
    
          if (loading) {
            return <span>Loading ...</span>
          }
    
          return (
            <Select {...props}>
              {data.rule.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          )
        }}
      </Subscription>
    )
  }
}

export { RuleSelect }

export default TradeRuleTableContainer

