// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@keyframes updated-row {
  0% {
    background-color: #bae7ffaa;
  }
}
    
.updated-row {
  animation-name: updated-row;
  animation-duration: 5000ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}    

.summary-row {
  background-color: rgba(0, 0, 0, 0.035);
}

.expire-row {
  background-color: rgba(255, 0, 0, 0.35);
}

.rejected-row {
  background-color: rgba(221, 50, 36, 0.15);
}

.cancel-row {
  background-color: rgba(252, 210, 153, 0.15);
}

.retry-row {
  background-color: rgba(178, 223, 0, 0.15);
}

.partial-row {
  background-color: rgba(0, 100, 100, 0.05);
}

.gray-out {
  background-color: #f0f0f0 !important;
  color: #a0a0a0;
} 
`, "",{"version":3,"sources":["webpack://./src/screens/Trader/index.css"],"names":[],"mappings":";AACA;EACE;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,mCAAmC;AACrC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,oCAAoC;EACpC,cAAc;AAChB","sourcesContent":["\n@keyframes updated-row {\n  0% {\n    background-color: #bae7ffaa;\n  }\n}\n    \n.updated-row {\n  animation-name: updated-row;\n  animation-duration: 5000ms;\n  animation-iteration-count: 1;\n  animation-timing-function: ease-out;\n}    \n\n.summary-row {\n  background-color: rgba(0, 0, 0, 0.035);\n}\n\n.expire-row {\n  background-color: rgba(255, 0, 0, 0.35);\n}\n\n.rejected-row {\n  background-color: rgba(221, 50, 36, 0.15);\n}\n\n.cancel-row {\n  background-color: rgba(252, 210, 153, 0.15);\n}\n\n.retry-row {\n  background-color: rgba(178, 223, 0, 0.15);\n}\n\n.partial-row {\n  background-color: rgba(0, 100, 100, 0.05);\n}\n\n.gray-out {\n  background-color: #f0f0f0 !important;\n  color: #a0a0a0;\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
