// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.page-header {
  padding: 0px;
  margin: 0px;
  height: 42px;
  line-height: 42px;
}

.page-header * {
  padding: 0px;
  margin: 0px;
}

.page-header-collapse-button {
  padding-left: 16px;
  padding-right: 16px;
}

.page-header-title {
  font-size: 1.2em;
  font-weight: 600;
  display: inline;
  margin: 0;
  padding-left: 10px;
}

.page-header-right-menu {
  float: right;
  display: inline;
  height: 42px;
}

.user-button {
  padding-left: 16px;
  padding-right: 16px;
}

`, "",{"version":3,"sources":["webpack://./src/utils/theme/base.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":["\n.page-header {\n  padding: 0px;\n  margin: 0px;\n  height: 42px;\n  line-height: 42px;\n}\n\n.page-header * {\n  padding: 0px;\n  margin: 0px;\n}\n\n.page-header-collapse-button {\n  padding-left: 16px;\n  padding-right: 16px;\n}\n\n.page-header-title {\n  font-size: 1.2em;\n  font-weight: 600;\n  display: inline;\n  margin: 0;\n  padding-left: 10px;\n}\n\n.page-header-right-menu {\n  float: right;\n  display: inline;\n  height: 42px;\n}\n\n.user-button {\n  padding-left: 16px;\n  padding-right: 16px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
