import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery, useSubscription, useMutation } from '@apollo/react-hooks'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import {
  ExclamationCircleOutlined,
  SyncOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import {
  Table,
  Tag,
  Popconfirm,
  Space,
  Button,
  message,
  Statistic,
  Fragment,
  Spin,
  Col,
  Alert,
  Divider
} from 'antd'


const RUNTIME_SUBSCRIPTION = gql`
  subscription {
    runtime(
      order_by: { id: desc },
      limit: 1
    ) {
      id
      current_volatility_id
      created_at
    }
  }
`

const COMMAND_MUTATION = gql`
  mutation($command: [command_insert_input!]!) {
    insert_command(objects: $command) {
      affected_rows
    }
  }
`

const TROUPE_HAS_RESOLVE_RULE = gql`
subscription($troupe_id: Int!) {
  state(where: {troupe_id: {_eq: $troupe_id}}) {
    id: troupe_id
    troupe_id
    type
    status
    troupe {
      id
      troupe_volatility_profile_rules {
        resolve_rule {
          id
        }
      }
    }
  }
}
`

const TROUPE_HAS_PROFILE_RESOLVE_RULE = gql`
subscription($troupe_ids: [Int!]) {
  state(
    where: {
      troupe_id: {_in: $troupe_ids},
      _and: {
        troupe: {
          troupe_volatility_profile_rules: {
            volatility_profile: {
              is_current_volatility_profile: {_eq: true}
            }
          }
        }
      }
    }
  ) {
    troupe_id
    type
    troupe {
      troupe_volatility_profile_rules(
        where: {
          volatility_profile: {
            is_current_volatility_profile: {_eq: true}
          }
        }
      ) {
        volatility_profile {
          id
          is_current_volatility_profile
        }
        id
        resolve_rule {
          id
        }
      }
    }
  }
}
`

const GET_POSITONS = gql`
query {
  getPositions{
    id: ticket
    ticket
    symbol
    comment
    priceOpen
    priceCurrent
    tp
    type
    time
    timeUpdate
    externalId
    identifier
    magic
    profit
    reason
    sl
    swap
    volume
    notionalVolume
  }
}
`

const CLOSE_LOSING_POSITIONS = gql`
mutation {
  close_losing_positions_and_insert_orders {
    id
    stream_instrument_id
    side
    status
  }
} 
`

const CLOSE_PROFITABLE_POSITIONS = gql`
mutation {
  close_profitable_positions_and_insert_orders {
    id
    stream_instrument_id
    side
    status
  }
} 
`

const CLOSE_POSITION_BY_TICKET = gql`
mutation($ticket: bigint!) {
  close_position_and_insert_order(args: {ticket: $ticket}) {
    id
    stream_instrument_id
    side
    status
  }
} 
`

const TROUPE_PROFIT = gql`
mutation {
  match_symbols_and_sum_profits {
    id: troupe_id
    troupe_id
    troupe_name
    symbols
    total_profit
  }
}
`

const RISK_STREAM_PROFIT = gql`
subscription {
  risk_stream_symbol_profit(order_by: {symbol: asc}) {
    id: symbol 
    key: symbol 
    troupe_ids
    troupe_names
    symbol
    total_profit
  }
}
`

const TROUPE_EXPOSURE = gql`
subscription {
  troupe_exposure(where: {_and: [{troupe_id: {_is_null: false}}, {lot: {_neq: 0}}]}){
    id: troupe_id
    troupe_id
    lot
  }
}
`

function Loading() {
  return (
    <>
    </>
  )
}

function RenderTroupeState({troupe_id}) { 
  const {data: hasResolveRule, loading: loadingHasResolveRule, error} = useSubscription(TROUPE_HAS_RESOLVE_RULE, {variables: {troupe_id: troupe_id}})

  if (loadingHasResolveRule) {
    return <Loading /> 
  }

  const type = hasResolveRule?.state?.[0]?.type
  const status = hasResolveRule?.state?.[0]?.status

  return (
    <>
      <Tag color={
        type === 'halt' ? (
          status === 'done' ? 'magenta' :
            status === 'pending' ? 'geekblue' : 'default') :
          type === 'stop' ? (
            status === 'done' ? 'magenta' :
              status === 'pending' ? 'geekblue' : 'default') :
            type === 'trade' ? (
              status === 'done' ? 'processing' :
                status === 'pending' ? 'geekblue' : 'default') :
              type === 'resolve' ? (
                status === 'done' ? 'warning' :
                  status === 'pending' ? 'geekblue' : 'default') : 'error'
      }>
        {(status === 'error' || type === 'error') && <ExclamationCircleOutlined />}
        {type}
      </Tag>
      {status === 'pending' && <SyncOutlined spin />}
      {status === 'error' && <WarningOutlined />}
    </>
  )
}

function ResolveAllButton({ runtime_id, troupe_ids }) {
  const {
    data: hasResolveRuleData,
    loading: loadingHasResolveRule,
    error,
  } = useSubscription(TROUPE_HAS_PROFILE_RESOLVE_RULE, {
    variables: { troupe_ids },
  })

  const [commandMutation] = useMutation(COMMAND_MUTATION)

  const insertCommand = (command) =>
    commandMutation({
      variables: { command },
    })

  if (loadingHasResolveRule) {
    return <Loading />
  }

  if (error) {
    return <Alert message={error.message || 'Error'} type="error" />
  }

  // Determine eligible troupes
  const eligibleTroupes = hasResolveRuleData?.state?.filter(
    (state) =>
      state.type === 'stop' &&
      state.troupe?.troupe_volatility_profile_rules?.[0]?.resolve_rule !== null
  )

  const hasEligibleTroupes = eligibleTroupes && eligibleTroupes.length > 0

  const makeExecute = () => {
    const commands = eligibleTroupes.map((state) => ({
      troupe_id: state.troupe_id,
      runtime_id: runtime_id,
      type: 'resolve',
    }))

    if (commands.length > 0) {
      insertCommand(commands)
    }
  }

  return (
    <Button
      style={{
        color: 'white',
        borderColor:'orange',
        backgroundColor: 'orange'
      }}
      disabled={!hasEligibleTroupes}
      onClick={makeExecute}
    >
      Resolve All
    </Button>
  )
}

function TroupeHasResolveRule({runtime_id, troupe_ids}) {
  const {data: hasResolveRule, loading: loadingHasResolveRule, error} = useSubscription(TROUPE_HAS_PROFILE_RESOLVE_RULE, {variables: {troupe_ids: troupe_ids}})
  //FIXME
  const [commandMutation] = useMutation(COMMAND_MUTATION)
  const insertCommand = command => commandMutation({
    variables: { command },
  })
  //  const makeExecute = (troupe_id) => insertCommand([{ troupe_id: troupe_id, runtime_id: runtime_id, type: 'resolve'}])

  if (loadingHasResolveRule) {
    return <Loading /> 
  }

  const eligibleTroupes = hasResolveRule?.state?.filter(
    (state) =>
      state.type === 'stop' &&
      state.troupe?.troupe_volatility_profile_rules?.[0]?.resolve_rule !== null
  )

  const hasEligibleTroupes = eligibleTroupes && eligibleTroupes.length > 0

  const makeExecute = () => {
    const commands = eligibleTroupes.map((state) => ({
      troupe_id: state.troupe_id,
      runtime_id: runtime_id,
      type: 'resolve',
    }))

    if (commands.length > 0) {
      insertCommand(commands)
    }
  } 


  return (
    <Button disabled={!hasEligibleTroupes} onClick={makeExecute}>
      Resolve
    </Button>
  )
}

function ResolveCategoryButton({ runtime_id, troupe_ids, label, buttonType, commandType }) {
  const {data: exposure, loading: exposureLoading} = useSubscription(TROUPE_EXPOSURE)
  const {
    data: hasResolveRuleData,
    loading: loadingHasResolveRule,
    error,
  } = useSubscription(TROUPE_HAS_PROFILE_RESOLVE_RULE, {
    variables: { troupe_ids },
  })

  const [commandMutation] = useMutation(COMMAND_MUTATION)

  const insertCommand = (command) =>
    commandMutation({
      variables: { command },
    })

  if (loadingHasResolveRule) {
    return <Loading />
  }

  if (error) {
    return <Alert message={error.message || 'Error'} type="error" />
  }

  // Determine eligible troupes
  const eligibleTroupes = hasResolveRuleData?.state?.filter(
    (state) =>
      (state.type === 'stop' &&
      state.troupe?.troupe_volatility_profile_rules?.[0]?.resolve_rule !== null)
        || commandType === 'stop'
  )

  const hasEligibleTroupes = eligibleTroupes && eligibleTroupes.length > 0

  const makeExecute = () => {
    const commands = eligibleTroupes
      .filter((state) => state.type !== commandType)
      .map((state) => ({
        troupe_id: state.troupe_id,
        runtime_id: runtime_id,
        type: commandType,
      }))

    if (commands.length > 0) {
      insertCommand(commands)
    }
  }

  return (

    <Popconfirm
      title={() => 
        <div style={{textAlign: 'center'}}>
          <span>Are you sure you want to stop?</span>
          <br/>
          <h4>Exposure status:</h4> 
          <br/>
          {exposure.troupe_exposure.length === 0 ?
            <Tag color='success'>HEDGED</Tag> 
            :
            <Tag color='error'>UNHEDGED</Tag>
          }
        </div>
      }
      onCancel={() => console.log('close Modal')}
      okText="Yes"
      cancelText="No"
      onConfirm={makeExecute}
    >
      <Button
        disabled={!hasEligibleTroupes}
        type={buttonType}
      >
        {commandType.charAt(0).toUpperCase() + commandType.slice(1) + ' ' + label}
      </Button>
    </Popconfirm>
  )
}

function RiskStreamProfitTable(props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // Use the mutation hook
  const subscription = useSubscription(RISK_STREAM_PROFIT)

  // Poll the mutation every second
  useEffect(() => {
    console.log(props.runtime_id)
  }, [props.runtime_id])

  // Handle error
  if (error) return <Alert message={error} type="error" />

  // Define the table columns
  const columns = [
    {
      title: 'Troupe',
      dataIndex: 'troupe_names',
      key: 'troupe_names',
      render: (troupes) =>
        troupes.map((name, index) => (
          <div key={name+'-'+index}>
            {name}
            <br />
          </div>
        )),
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      ellipsis: true,
      render: (_, record) =>
        record.troupe_ids.map(troupe_id =>
          <div key={'status-div-'+troupe_id+'-'+record.symbol}>
            <RenderTroupeState troupe_id={troupe_id} key={'troupe-'+troupe_id+'-'+record.symbol} />
            <br />
          </div>
        ),
    },
    {
      title: 'Symbols',
      key: 'symbol',
      dataIndex: 'symbol',
    },
    {
      title: 'Total Profit',
      dataIndex: 'total_profit',
      key: 'total_profit',
      render: (profit) => (
        <Statistic
          size='small'
          value={profit.toFixed(2)}
          valueStyle={profit === 0 || profit === null ? {color:'#3f8999', fontSize:'16px'} : profit < 0 ? {color:'#cf1322', fontSize:'16px'} : {color:'#3f8600', fontSize:'16px'} } 
          suffix={profit === 0 || profit === null ?
            0 : profit < 0 ? <><ArrowDownOutlined /></>
              : <><ArrowUpOutlined /></>}
        />
      ),
    },
    {
      title: 'Resolve',
      key: 'resolve',
      render: (_, record) =>
        <TroupeHasResolveRule runtime_id={props.runtime_id} troupe_ids={record.troupe_ids} />
    },
  ]

  // Show loading spinner while mutation is being fetched
  if (subscription.loading) {
    return <Loading />
  }

  const riskCategories = [
    {
      title: 'PnL Winning Risk',
      filter: (item) => item.total_profit > 1000,
      resolveLabel: 'Winning',
      buttonType: 'primary',
    },
    {
      title: 'PnL Losing Risk',
      filter: (item) => item.total_profit < -1000,
      resolveLabel: 'Losing',
      buttonType: 'danger',
    },
    {
      title: 'PnL Neutral Risk',
      filter: (item) =>
        item.total_profit <= 1000 && item.total_profit >= -1000,
      resolveLabel: 'Neutral',
      buttonType: 'default',
    },
  ]


  const topButtons = (commandType) => riskCategories.map((category) => {
    const filteredData = subscription.data.risk_stream_symbol_profit.filter(category.filter)

    const troupeIds = [
      ...new Set(filteredData.flatMap((record) => record.troupe_ids)),
    ]

    return (
      <ResolveCategoryButton
        key={category.title}
        runtime_id={props.runtime_id}
        troupe_ids={troupeIds}
        label={category.resolveLabel}
        buttonType={category.buttonType}
        commandType={commandType}
      />
    )
  })

  return (
    <>
      <div style={{ textAlign: 'left', marginBottom: '16px' }}>
        <Space>{topButtons('resolve')}</Space>
        <Divider type='vertical' />
        <Space>{topButtons('stop')}</Space>
      </div>
      {riskCategories.map((category) => {
        const filteredData = subscription.data.risk_stream_symbol_profit.filter(category.filter)
        const troupeIds = [
          ...new Set(filteredData.flatMap((record) => record.troupe_ids)),
        ]
        const symbol = filteredData.flatMap((record) => record.symbol)
        return (
          <div key={category.title}>
            <Table
              title={() => category.title}
              dataSource={filteredData}
              columns={columns}
              rowKey={filteredData.key}
              pagination={false}
            />
            <Divider />
          </div>
        )
      })}
    </>
  )
}

function TroupeProfitTable() {
  // State to store mutation results
  const [profitsData, setProfitsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // Use the mutation hook
  const [matchSymbolsAndSumProfits] = useMutation(TROUPE_PROFIT)

  // Poll the mutation every second
  useEffect(() => {
    setLoading(true)

    const fetchProfits = async () => {
      try {
        const { data } = await matchSymbolsAndSumProfits()
        setProfitsData(data?.match_symbols_and_sum_profits || [])
        setError(null)
      } catch (err) {
        setError(err.message || 'Error fetching data')
      } finally {
        setLoading(false)
      }
    }

    // Call the mutation every 1 second
    const intervalId = setInterval(() => {
      fetchProfits()
    }, 1000)

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId)
  }, [matchSymbolsAndSumProfits])

  // Handle error
  if (error) return <Alert message={error} type="error" />

  // Define the table columns
  const columns = [
    {
      title: 'Troupe',
      dataIndex: 'troupe_name',
      key: 'troupe_name',
    },
    {
      title: 'Symbols',
      key: 'symbols',
      dataIndex: 'symbols',
      render: (symbols) => (
        <div>
          {symbols
            .slice()
            .sort((a, b) => a.symbol.localeCompare(b.symbol))
            .map((s, index) => (
              <div key={index}>
                <Statistic
                  prefix=<strong>{s.symbol}:</strong>
                  size='small'
                  value={s.profit.toFixed(2)}
                  valueStyle={s.profit === 0 || s.profit === null ? {color:'#3f8999', fontSize:'16px'}
                    : s.profit < 0 ? {color:'#cf1322', fontSize:'16px'}
                      : {color:'#3f8600', fontSize:'16px'}
                  } 
                  suffix={s.profit === 0 || s.profit === null ?
                    0 : s.profit < 0 ? <><ArrowDownOutlined /></>
                      : <><ArrowUpOutlined /></>}
                />

              </div>
            ))}
        </div>
      ),
    },
    {
      title: 'Total Profit',
      dataIndex: 'total_profit',
      key: 'total_profit',
      render: (profit) => (
        <Statistic
          size='small'
          value={profit.toFixed(2)}
          valueStyle={profit === 0 || profit === null ? {color:'#3f8999', fontSize:'16px'} : profit < 0 ? {color:'#cf1322', fontSize:'16px'} : {color:'#3f8600', fontSize:'16px'} } 
          suffix={profit === 0 || profit === null ?
            0 : profit < 0 ? <><ArrowDownOutlined /></>
              : <><ArrowUpOutlined /></>}
        />
      ),
    },
  ]

  // Show loading spinner while mutation is being fetched
  if (loading) {
    return <Loading />
  }

  return (
    <Table
      title={()=>'PnL per Troupe'}
      dataSource={profitsData}
      columns={columns}
      rowKey="id"
      pagination={false} // No pagination as the data updates frequently
    />
  )
}

function MetaTraderPositions(props) {
  const [positions, setPositions] = useState([])
  const {data, loading, error, refetch, networkStatus} = useQuery(GET_POSITONS, {
    pollInterval: 1000,
    notifyOnNetworkStatusChange: true
  })

  const [closeLosingPositions, { loading: closingLosing }] = useMutation(CLOSE_LOSING_POSITIONS)
  const [closeProfitablePositions, { loading: closingProfitable }] = useMutation(CLOSE_PROFITABLE_POSITIONS) 
  const [closePositionByTicket, { loading: closingByTicket }] = useMutation(CLOSE_POSITION_BY_TICKET) 


  const handleCloseLosing = async () => {
    try {
      const { data } = await closeLosingPositions()
      refetch()
    } catch (err) {
      refetch()
      message.error('Failed to close losing positions')
    }
  }

  const handleCloseProfitable = async () => {
    try {
      const { data } = await closeProfitablePositions()
      refetch()
    } catch (err) {
      refetch()
      message.error('Failed to close profitable positions')
    }
  }

  const handleClosePosition = async (ticket) => {
    try {
      const { data } = await closePositionByTicket({ variables: { ticket } })
      refetch()
    } catch (err) {
      refetch()
      message.error('Error occurred while closing the position')
    }
  } 

  useEffect(() => {
    if (data && !loading) {
      setPositions(data.getPositions)
    }
  }, [data, loading])

  const isInitialLoading = loading && networkStatus !== 6

  if (isInitialLoading) {
    return <Loading />
  }

  const columns = [
    {
      title: 'Ticket',
      dataIndex: 'ticket',
      key: 'ticket',
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
    },
    {
      title: 'Side',
      dataIndex: 'type',
      key: 'symbol',
      render: (type) => type === 0 ? <Tag color='#2db7f5'>Buy</Tag> : <Tag color='#f50'>Sell</Tag>,
    },
    {
      title: 'Price Open',
      dataIndex: 'priceOpen',
      key: 'priceOpen',
      render: (priceOpen) => priceOpen.toFixed(5), // Format price open with 5 decimal places
    },
    {
      title: 'Price Current',
      dataIndex: 'priceCurrent',
      key: 'priceCurrent',
      render: (priceCurrent) => priceCurrent.toFixed(5), // Format price current with 5 decimal places
    },
    {
      title: 'Swap',
      dataIndex: 'swap',
      key: 'swap',
      render: (swap) => swap.toFixed(2), // Optional: Format swap to 2 decimal places
    },
    {
      title: 'Volume',
      dataIndex: 'notionalVolume',
      key: 'notionalVolume',
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      sorter: (a, b) => a.profit - b.profit,
      defaultSortOrder: 'ascend', 
      render: (profit) => (
        <Statistic
          size='small'
          value={profit.toFixed(2)}
          valueStyle={profit === 0 || profit === null ? {color:'#3f8999', fontSize:'16px'} : profit < 0 ? {color:'#cf1322', fontSize:'16px'} : {color:'#3f8600', fontSize:'16px'} } 
          suffix={profit === 0 || profit === null ?
            0 : profit < 0 ? <><ArrowDownOutlined /></>
              : <><ArrowUpOutlined /></>}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      width: 15,
      render: (_, record) => (
        <Button
          type="text"
          style={{float: 'right'}}
          loading={closingByTicket}
          onClick={() => handleClosePosition(parseInt(record.ticket))}
        >
          X
        </Button>
      ),
    }
  ]

  return (
    <Table
      dataSource={data?.getPositions}
      size='small'
      pagination={false}
      loading={networkStatus === 4}
      rowKey="ticket"
      columns={columns}
      footer={() => (
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px 24px' }}>
          <Button
            type="primary"
            onClick={handleCloseLosing}
            loading={closingLosing}
            style={{float: 'left'}}
            shape='round'
            size='large'
            danger // Red button for losing positions
          >
            Close Losing Positions
          </Button>

          <Button
            type="primary"
            onClick={handleCloseProfitable}
            loading={closingProfitable}
            style={{float: 'right'}}
            size='large'
            shape='round'
          >
            Close Profitable Positions
          </Button>
        </div>
      )}
    />
  )
}

function MetaTraderControl(props) {
  const { loading, error, data } = useSubscription(RUNTIME_SUBSCRIPTION)

  useEffect(() => {
    console.log(data)
    console.log(data ? data.runtime[0].id : data)
  }, [data])

  if (loading || !(data.runtime && data.runtime.length)) {
    return <Loading />
  }

  if (error) {
    return "error"
  }


  return (
    <>
      <RiskStreamProfitTable runtime_id={data.runtime[0].id} />
    </>
  )
}
/*
      <Divider />
      <TroupeProfitTable runtime={data.runtime[0]} />
      <Divider />
      <MetaTraderPositions />
*/
export default MetaTraderControl 
