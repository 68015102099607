
import {
  SET_AUTHENTICATION_TOKEN, 
  AUTHENTICATION_LOGOUT,
} from '../actions/Authentication'

function jwtDecode(token) {
  return JSON.parse(atob(token.split('.')[1]))
}

export const initialState = {
  token: '',
  id: '',
  name: '',
  email: '',
  roles: ['guest'],
  isAuthenticated: false,
}

const AuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_AUTHENTICATION_TOKEN: {
    const token = action.payload
    const {
      'https://hasura.io/jwt/claims': {
        'x-hasura-name': name,
        'x-hasura-name': email,
        'x-hasura-user-id': id,
        'x-hasura-default-role': role,
      }
    } = jwtDecode(token)
    return { id, roles: [role], name, email, isAuthenticated: true, token }
  }
  case AUTHENTICATION_LOGOUT: return initialState
  default: return state
  }
}

export default AuthenticationReducer

