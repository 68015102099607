import React from 'react'
import {
  FallOutlined,
  BookOutlined,
  StockOutlined,
  FormOutlined,
  LogoutOutlined,
  TeamOutlined,
  ScheduleOutlined,
  SettingOutlined,
  SlidersOutlined,
  ToolOutlined,
  PieChartOutlined,
  UngroupOutlined,
  ProfileOutlined,
  BarChartOutlined
} from '@ant-design/icons'

import { Navigate, Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import _ from 'lodash'

import { isAuthenticated as getIsAuthenticated, getUserRoles } from 'selectors/Authentication'

import PermissionDenied  from 'components/PermissionDenied'
import FileNotFound      from 'components/FileNotFound'

import App from 'screens/App'
//import Debug from 'screens/Debug'
import Login from 'screens/Login'
import Logout from 'screens/Logout'
import Users from 'screens/Users'
import Trader from 'screens/Trader'
import Settings from 'screens/Settings'
import TradeGroup from 'screens/TradeGroup'
import Preferences from 'screens/Preferences'
import ErrorReport from 'screens/ErrorReport'
import ExecutionReport from 'screens/ExecutionReport'
import RejectedOrders from 'screens/RejectedOrders'
import Trades from 'screens/Trades'
import NotificationScreen from 'screens/NotificationScreen'
import { CountToExpire } from 'screens/NotificationScreen'
import TroupeExposure from 'screens/TroupeExposure'
import StreamProfile from 'screens/StreamProfile'
import Statistics from 'screens/Statistics'
import Statements from 'screens/Statements'
import MetaTraderControl from 'screens/MetaTraderControl'
import Slip from 'screens/Slip'


export const TRADER = {
  menu: ['sidebar'],
  path: '/trader',
  icon: <StockOutlined />,
  name: 'Trader',
  roles: ['admin', 'manager', 'viewer'],
  element: <Trader />,
}

export const SETTINGS = {
  menu: ['sidebar'],
  path: '/settings',
  icon: <SettingOutlined />,
  name: 'Settings',
  roles: ['admin', 'manager', 'viewer'],
  element: <Settings />,
}


export const TRADE_GROUP = {
  menu: ['sidebar'],
  path: '/trade-group',
  icon: <UngroupOutlined />,
  name: 'TradeGroup',
  roles: ['admin', 'manager', 'viewer'],
  element: <TradeGroup />,
}

export const TROUPE_EXPOSURE = {
  menu: ['sidebar'],
  path: '/troupe-exposure',
  icon: <SlidersOutlined />,
  name: 'Exposure',
  roles: ['admin', 'manager', 'viewer'],
  element: <TroupeExposure />,
}

export const STREAM_PROFILE = {
  menu: ['sidebar'],
  path: '/stream-profile',
  icon: <ProfileOutlined />,
  name: 'StreamProfile',
  roles: ['admin', 'manager', 'viewer'],
  element: <StreamProfile />,
}

export const ERROR_REPORT = {
  menu: ['sidebar'],
  path: '/error-report',
  icon: <ScheduleOutlined />,
  name: 'Error Report',
  roles: ['admin', 'manager', 'viewer'],
  element: <ErrorReport />,
}

export const REJECTED_ORDERS = {
  menu: ['sidebar'],
  path: '/rejected-orders',
  icon: <ScheduleOutlined />,
  name: 'Rejected Orders',
  roles: ['admin', 'manager', 'viewer'],
  element: <RejectedOrders />,
}

export const EXECUTION_REPORT = {
  menu: ['sidebar'],
  path: '/execution-report',
  icon: <ScheduleOutlined />,
  name: 'Execution Report',
  roles: ['admin', 'manager', 'viewer'],
  element: <ExecutionReport />,
}

export const METATRADER_CONTROL = {
  menu: ['sidebar'],
  path: '/mt-control',
  icon: <BarChartOutlined/>,
  name: 'MT5 Control',
  roles: ['admin'],
  element: <MetaTraderControl />,
}

export const NOTIFICATIONSCREEN = {
  menu: ['sidebar'],
  path: '/notification-screen',
  icon: <ScheduleOutlined />,
  name: 'Notification',
  roles: ['admin', 'manager', 'viewer'],
  element: <NotificationScreen />,
  badge: CountToExpire,
}

export const TRADES = {
  menu: ['sidebar'],
  path: '/trades',
  icon: <ScheduleOutlined />,
  name: 'Trades',
  roles: ['admin'],
  element: <Trades />,
}

export const PREFERENCES = {
  menu: ['topbar', 'sidebar'],
  path: '/preferences',
  icon: <FormOutlined />,
  name: 'Preferences',
  roles: ['admin', 'manager', 'viewer'],
  element: <Preferences />,
}

export const LOGOUT = {
  menu: ['topbar'],
  path: '/logout',
  icon: <LogoutOutlined />,
  name: 'Logout',
  roles: ['admin', 'manager', 'viewer'],
  element: <Logout />,
}

export const USERS = {
  menu: ['sidebar'],
  path: '/users',
  icon: <TeamOutlined />,
  name: 'Users',
  roles: ['admin', 'manager'],
  element: <Users />,
}

//export const DEBUG = {
//  menu: ['sidebar'],
//  path: '/debug',
//  icon: <ToolOutlined />,
//  name: 'Debug',
//  roles: ['admin'],
//  element: <Debug />,
//}

export const STATISTICS = {
  menu: ['sidebar'],
  path: '/statistics',
  icon: <PieChartOutlined />,
  name: 'Statistics',
  roles: ['admin'],
  element: <Statistics />,
}

export const SLIP = {
  menu: ['sidebar'],
  path: '/slip',
  icon: <FallOutlined />,
  name: 'Slip',
  roles: ['admin'],
  element: <Slip />,
}

export const STATEMENTS = {
  menu: ['sidebar'],
  path: '/statements',
  icon: <BookOutlined />,
  name: 'Statements',
  roles: ['admin'],
  element: <Statements />,
}

// export const sidebar = [
//   TRADER,
//   SETTINGS,
//   EXPOSURE,
//   {
//     icon: <BookOutlined />,
//     name: 'Statements',
//     children: jj
//     
// 
// ]

export const routes = [
  TRADER,
  METATRADER_CONTROL,
  SETTINGS,
  TRADE_GROUP,
  TROUPE_EXPOSURE,
  STREAM_PROFILE,
  TRADES,
  ERROR_REPORT,
  REJECTED_ORDERS,
  EXECUTION_REPORT,
  NOTIFICATIONSCREEN,
  STATEMENTS,
  PREFERENCES,
  LOGOUT,
  USERS,
  //STATISTICS,
  //DEBUG,
  //SLIP,
]


//const SettingsRedirect = () => <Redirect to={'/settings'} />
const TraderRedirect       = () => <Navigate to={'/trader'} />
const LoginRedirect        = () => <Navigate to={'/login'} />
const FileNotFoundRedirect = () => <Navigate to={'/not-found'} />

const AuthenticatedRoutes = (props) => {
  const userRoles = useSelector(getUserRoles)
  return (
    <App>
      <Routes>
        <Route path='/' element={<TraderRedirect />} />
        <Route path='/login' element={<TraderRedirect />} />
        {routes.map(
          ({ path, element , roles }) =>
            <Route
              key={path}
              path={path}
              element={_.intersection(userRoles, roles).length ? element : <PermissionDenied />}
            />
        )}
        <Route path='/not-found' element={<FileNotFound />} />
      </Routes>
    </App>
  )
}


const GuestRoutes = (props) => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='*' element={<LoginRedirect />} />
    </Routes>
  )
}

export const AppRoutes = () => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  return (
    isAuthenticated ? <AuthenticatedRoutes /> : <GuestRoutes />
  )
}



