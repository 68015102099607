import React, { useEffect, useState, Fragment } from 'react'
import { Switch, Select, Popconfirm, Row, Col, Input, InputNumber, Form, Modal, Tag, Button, Divider, Statistic, Card, Spin, Alert } from 'antd'
import { FilterOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { useQuery, useSubscription, useMutation } from '@apollo/react-hooks'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment'

// TODO change exposure(instrument_id, stream_id) to stream_instrument_id

const EXPOSURE_SUBSCRIPTION = gql`
subscription {
  exposure(
    distinct_on: [ stream_instrument_id ],
    order_by: { stream_instrument_id: desc, id: desc }
  ) {
    stream_instrument_id
    created_at
    id
    stream_instrument {
      id
      name
      troupe_id
      instrument {
        id
        name
        decimal_pip
      }
      stream {
        id
        account_name
        connector {
          id
          name
        }
      }
    }
    change
    lot
  }
}
`

const GET_EXPOSURE = gql`
query($symbol: String!){
  getMTInstrument(symbol: $symbol) {
    id
    BID
    ASK
    swapLong
    swapShort
  }
} 
`

const UPDATE_EXPOSURE = gql`
  mutation($input: order_insert_input!) {
    insert_order(objects:[$input]) {
      returning {
        id
        stream_instrument_id
        lot
      }
    }
  }
`

const CLEAR_EXPOSURE = gql`
  mutation($input: order_insert_input!) {
    insert_order(objects:[$input]) {
      returning {
        id
        stream_instrument_id
        lot
      }
    }
  }
`

const TROUPES = gql`
subscription {
  troupe(where: {is_visible: {_eq: true}}, order_by: {name: asc}) {
    id
    name
    stream_instruments {
      id
      name
      troupe_id
      instrument {
        id
        name
        decimal_pip
      }
      stream {
        id
        account_name
        connector {
          id
          name
        }
      }
    }
  }
}

`

// ( where:{ 
//      _and: [
//        {is_visible: {_eq: true}}, 
//        {connector: {is_visible: {_eq: true}}}
//    ]} ) 

const STREAM_INSTRUMENTS = gql`
  subscription {
    stream_instrument(where: {troupe_id: {_is_null: false}}) {
      id
      name
      troupe_id
      instrument {
        id
        decimal_pip
        name
      }
      stream {
        id
        account_name
        connector {
          id
          name
        }
      }
    }
  }
`

const STREAMS = gql`
  subscription {
    stream ( where:{ 
      _and: [
        {is_visible: {_eq: true}}, 
        {connector: {is_visible: {_eq: true}}}
    ]} ) {
      id
      account_name
      connector {
        name
      }
    }
  }
`

const STREAMS_SUBSCRIPTION = gql`
subscription streams {
  stream(
    where: {
      is_visible: { _eq: true }
    }
    order_by: { created_at: asc }
  ) {
    id
    account_name
    connector {
      id
      name
    }
  }
}
`

function Loading() {
  return (
    <Col span={24} style={{ padding: 10 }}>
      <Spin tip="Loading...">
        <Alert
          message="Please wait for the server to be available."
          description="You will be able to issue commands once fully loaded."
          type="info"
        />
      </Spin>
    </Col>
  )
}

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

const AdjustExposure: React.FC<CollectionCreateFormProps> = ({
  visible,
  onCreate,
  onCancel,
  instrument,
  streams,
  exposure,
  form
}) => {

  useEffect(() => {
    if (visible) {
      form.resetFields()
      form.setFieldsValue(exposure)
    }
  }, [exposure, visible, form])

  return (
    <Modal
      visible={visible}
      title={instrument.name}
      okText="Add Exposure"
      cancelText="Cancel"
      onCancel={() => {
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values).then(() =>
              form.resetFields())
          })
          .catch(info => {
            console.log('Validate Failed:', info)
          })
      }}
    >
      <Form
        form={form}
        size='small'
        initialValues={exposure}
      >
        {streams.map( (stream_instrument, index) => {
          const stream = stream_instrument.stream
          const name = (stream.account_name === '' ? stream.connector.name : stream.account_name) + '-' + stream_instrument.name
          const displayName = (stream.account_name === '' ? stream.connector.name : stream.account_name)
          return (
            <Form.Item key={name+'-'+index} label={displayName} name={name}>
              <InputNumber/>
            </Form.Item>
          )
        })}
      </Form>
    </Modal>
  )
}

function getSwap(symbol, lot, decimal_pip) {
  const { loading, error, data } = useQuery(GET_EXPOSURE, {variables: {symbol: symbol}})

  if (loading) {
    return <></>
  }   
  if (error || !data.getMTInstrument) {
    return <></>
  }   
  const pipVal = ((10**(-(decimal_pip))))*Math.abs(lot)

  return (
    <Statistic
      size='small'
      key={data.getMTInstrument.id}
      value={'\t' + (lot > 0 ?
        ((pipVal*data.getMTInstrument.swapLong)).toFixed(decimal_pip)
        : lot < 0 ?
          ((pipVal*data.getMTInstrument.swapShort)).toFixed(decimal_pip)
          : 0
      )}
      precision={2}
      valueStyle={{color:'#3f8999', fontSize:'12px', float: 'right' }} 
    />
  )

}

function StreamStat({instrument, streams, exposure, updateExposure, form, hideZeroStreams}) {
  const [visible, setVisible] = useState(false)
  const [streamExposure, setStreamExposure] = useState({})


  useEffect(() => {
    const initialExposure = {}
    streams.forEach(stream => {
      const streamId = stream.stream.id
      const lot = exposure && exposure[streamId] ? exposure[streamId].lot : 0
      const name = (stream.stream.account_name === '' ? stream.stream.connector.name : stream.stream.account_name) + '-' + stream.name
      initialExposure[name] = lot
    })

    setStreamExposure(initialExposure)
  }, [streams, exposure, hideZeroStreams])

  const onCreate = async (values) => {
    const changes = []
    streams.forEach( stream => {
      const name = stream.stream.account_name === '' ? stream.stream.connector.name : stream.stream.account_name + '-' + stream.name
      const oldLot = exposure ? exposure[stream.stream.id] ? exposure[stream.stream.id].lot : 0 : 0
      const lot = values[name] - oldLot 
      const side = lot < 0 ? 'sell' : 'buy'
      changes.push({stream_instrument_id: stream.id, lot: lot, side: side  })
    })

    const promises = changes.filter(change => change.lot !== 0).map(change =>
      updateExposure({
        variables: {
          input: {
            serial: -1,
            status: "filled",
            stream_instrument_id: change.stream_instrument_id,
            lot: Math.abs(change.lot),
            side: change.side,
          }
        }
      })
    )

    try {
      await Promise.all(promises)
    } catch (error) {
      console.error('Update exposure failed:', error)
    }

    setVisible(false)
  }


  return (
    <Fragment>
      {streams.map( stream_instrument => { 
        const stream = stream_instrument.stream
        const name = (stream.account_name === '' ? stream.connector.name : stream.account_name) + '-' + stream_instrument.name
        const displayName = (stream.account_name === '' ? stream.connector.name : stream.account_name)
        const key = stream.connector.name + '-' + stream.account_name + '-' + stream.connector.id + '-' + stream.id + '-' + stream_instrument.name
        const lot = streamExposure[name]
        const swap = getSwap(stream_instrument.instrument.name.replace('/',''), lot, stream_instrument.instrument.decimal_pip)

        if (hideZeroStreams && (lot === 0 || lot === null))  return null

        return (
          <Fragment key={stream.id}>
            <Statistic
              size='small'
              key={key}
              prefix={<span style={{ paddingRight:'40px'}}>{
                displayName + ': '}
              </span>}
              value={lot != null ? lot : 0}
              precision={0}
              valueStyle={lot === 0 || lot === null ? {color:'#3f8999', fontSize:'16px'} : lot < 0 ? {color:'#cf1322', fontSize:'16px'} : {color:'#3f8600', fontSize:'16px'} } 
              suffix={lot === 0 || lot === null ?
                <Fragment/> : lot < 0 ? <><ArrowDownOutlined /><Divider type='vertical'/>{swap}</>
                  : <><ArrowUpOutlined /><Divider type='vertical'/>{swap}</>}
            />
            <Divider style={{margin:'10px'}}/>
          </Fragment>
        )
      })}
      <div style={{textAlign: 'center'}}>
        <Button
          size="small"
          onClick={() => {
            setVisible(true)
          }}
        >
          Add Exposure 
        </Button>
      </div>
      <AdjustExposure
        instrument={instrument}
        streams={streams}
        exposure={streamExposure}
        visible={visible}
        form={form}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false)
        }}
      />
    </Fragment>
  )
}


function ExposureReport(props) {
  const { loading, error, data } = useSubscription(EXPOSURE_SUBSCRIPTION)
  const { loadingTroupes, errorTroupes, data: dataTroupes } = useSubscription(TROUPES)
  const { loadingStreamInstruments, errorStreamInstruments, data: dataStreamInstruments } = useSubscription(STREAM_INSTRUMENTS)
  const {stream, streamLoading} = useSubscription(STREAMS_SUBSCRIPTION)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [clearExposure] = useMutation(CLEAR_EXPOSURE)
  const [form] = Form.useForm()
  const [instrumentExposure, setInstrumentExposure] = useState({})
  const [selectedTroupes, setSelectedTroupes] = useState(window.localStorage ? window.localStorage.getItem('exposure-troupe-selected') ? window.localStorage.getItem('exposure-troupe-selected').split(',') : []  : [])
  const [statusFilter, setStatusFilter] = useState(window.localStorage ? window.localStorage.getItem('exposure-status-filter') ? window.localStorage.getItem('exposure-status-filter').split(',') : []  : [])
  const [hideZeroStreams, setHideZeroStreams] = useState(window.localStorage ? window.localStorage.getItem('exposure-streams-hide') : false)

  const statusTagColor = {
    flat: 'processing',
    hedged: 'success',
    unhedged: 'error',
  }

  const options = [
    {
      value: 'flat',
      label: 'FLAT',
    },
    {
      value: 'hedged',
      label: 'HEDGED',
    },
    {
      value: 'unhedged',
      label: 'UNHEDGED',
    },
  ]

  const troupeOptions = (dataTroupes && dataTroupes.troupe) ? dataTroupes.troupe.map(troupe => ({
    value: troupe.name,
    label: troupe.name,
  })) : []

  const tagRender = (props) => {
    const { color, label, value, closable, onClose } = props
    const onPreventMouseDown = (event) => {     event.preventDefault()
      event.stopPropagation()
    }

    return (
      <Tag
        color={statusTagColor[value]}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    )
  }

  const updateStatusFilter = filters => {
    setStatusFilter(filters)
    window.localStorage.setItem('exposure-status-filter', filters)
  }

  const updateTroupeSelected = selected => {
    setSelectedTroupes(selected)
    window.localStorage.setItem('exposure-troupe-selected', selected)
  }

  const updateHideZeroStreams = hide => {
    setHideZeroStreams(hide)
    window.localStorage.setItem('exposure-streams-hide', hide)
  }

  const handleChange = event => {
    setSearchTerm(event.target.value.toLowerCase())
  }

  useEffect(() => {
    if (data && data.exposure) {
      const newInstrumentExposure = {}
      data.exposure.forEach(exposureData => {
        const troupeId = exposureData.stream_instrument.troupe_id
        if (!newInstrumentExposure[troupeId]) {
          newInstrumentExposure[troupeId] = { sum: 0, allZero: 0 }
        }
        const streamId = exposureData.stream_instrument.stream.id
        newInstrumentExposure[troupeId][streamId] = {
          ...exposureData.stream_instrument,
          lot: exposureData.lot
        }
        newInstrumentExposure[troupeId].sum += exposureData.lot
        newInstrumentExposure[troupeId].allZero += Math.abs(exposureData.lot)
      })
      setInstrumentExposure(newInstrumentExposure)
    }
    let results = ((dataTroupes && dataTroupes.troupe) || []).filter(troupe =>
      troupe.name.toLowerCase().includes(searchTerm)
    )

    if (statusFilter.length > 0) {
      results = results.filter(troupe => {
        const exposure = instrumentExposure[troupe.id]
        if (!exposure) return statusFilter.includes('flat')
        if (exposure.allZero === 0) {
          return statusFilter.includes('flat')
        } else if (exposure.sum === 0) {
          return statusFilter.includes('hedged')
        } else {
          return statusFilter.includes('unhedged')
        }
      })
    } 

    if (selectedTroupes.length > 0) {
      results = results.filter(troupe =>
        selectedTroupes.includes(troupe.name)
      )
    }

    setSearchResults(results)
  }, [searchTerm, dataTroupes, data, statusFilter, selectedTroupes])


  if (!data || error || !dataTroupes || errorTroupes || !dataStreamInstruments || errorStreamInstruments) {
    return <Loading />
  }   
  
  if (loading || loadingStreamInstruments || loadingTroupes) {
    return <span>Loading ...</span>
  }   


  const columns = [
    {
      title: 'trade group',
      dataindex: 'name',
      key: 'name',//_instruments[0].troupe.name',
      render: name => <span style={{color:'#1890ff'}}>{name.name}</span>,
    },
  ]

  return (
    <>
      <Row justify='center'>
        <Col span={3} style={{padding: '10px'}}>
          <strong>Hide Zero Streams: </strong>
          <Switch onChange={updateHideZeroStreams} checked={hideZeroStreams}/>
        </Col>
        <Col span={7} style={{padding: '10px'}}>
          <Select
            mode="multiple"
            showSearch
            style={{
              width: '100%',
            }}
            placeholder="Filter by Troupe Names"
            options={troupeOptions}
            defaultValue={selectedTroupes}
            onChange={updateTroupeSelected}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            allowClear={true}
          />
        </Col>
        <Col span={7} style={{padding: '10px'}}>
          <Select
            mode="multiple"
            placeholder="Filter by Exposure Status"
            tagRender={tagRender}
            defaultValue={statusFilter}
            style={{
              width: '100%',
            }}
            allowClear={true}
            options={options}
            onChange={updateStatusFilter}
          />
        </Col>
        <Col span={7} style={{padding: '10px'}}>
          <Input.Search placeholder="Search by instrument" onChange={handleChange} />
        </Col>
        {searchResults.map(troupe => 
          <Col key={troupe.id + '-' + troupe.name}>
            <Card 
              key={troupe.id + '-' + troupe.name}
              size='small'
              title={
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>{troupe.name}</span>
                  <Popconfirm
                    title="Are you sure you want to clear the exposure?"
                    onConfirm={() => {
                      const changes = []
                      troupe.stream_instruments.forEach(stream => {
                        const name = stream.stream.account_name === '' ? stream.stream.connector.name : stream.stream.account_name
                        const exposure = instrumentExposure[troupe.id]
                        const oldLot = exposure ? exposure[stream.stream.id] ? exposure[stream.stream.id].lot : 0 : 0 
                        const lot = -oldLot 
                        const side = lot < 0 ? 'sell' : 'buy'
                        changes.push({stream_instrument_id: stream.id, lot: lot, side: side})  
                      })  
                      changes.forEach(change => {
                        change.lot === 0 ? 
                          console.log('no change needed')
                          :   
                          clearExposure({
                            variables: {
                              input: {
                                serial: -1, 
                                status: "filled",
                                stream_instrument_id: change.stream_instrument_id,
                                lot: Math.abs(change.lot),
                                side: change.side, 
                              }   
                            }   
                          })  
                      })  
                      form.resetFields()
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </div>
              }
              bordered={true} 
              style={{ margin: '5px', float:'left', minWidth:'100px' }}
              actions={
                [!instrumentExposure[troupe.id] || instrumentExposure[troupe.id].allZero === 0
                  ? 
                  <Tag color='processing'>FLAT</Tag> 
                  :
                  instrumentExposure[troupe.id].sum === 0 
                    ? 
                    <Tag color='success'>HEDGED</Tag> 
                    :
                    <Tag color='error'>UNHEDGED {instrumentExposure[troupe.id].sum.toLocaleString()}</Tag>]
              }
            >
              <Mutation mutation={UPDATE_EXPOSURE} >
                {(updateExposure) => (
                  <StreamStat 
                    instrument={troupe} 
                    streams={troupe.stream_instruments} 
                    exposure={instrumentExposure[troupe.id]} 
                    updateExposure={updateExposure}
                    form={form}
                    hideZeroStreams={hideZeroStreams}
                  />
                )}
              </Mutation>
            </Card>
          </Col>
        )}
      </Row>
    </>
  )
}


export default ExposureReport
