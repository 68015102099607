import React, { useEffect, useMemo, useState } from "react"
import gql from 'graphql-tag'
import moment from 'moment'
import { useMutation, useSubscription } from '@apollo/react-hooks'
import { Table, Badge, Col, Spin, Alert, Button, Form, Modal, Input, message } from 'antd'

import SubscriptionTable from 'components/SubscriptionTable'

const STREAM_INSTRUMENTS = gql`
subscription{
  stream_instrument(where: {maturity_date: {_neq: "No Maturity"},
                    _and: {is_visible: {_eq: true}}},
                    order_by: {maturity_date: asc})
  {
    id
    name
    maturity_date
    stream{
      id
      account_name
      connector{
        name
      }
    }
    instrument{
      id
      name
    }
  }
}
`

const COUNT_STREAM_INSTRUMENTS = gql`
subscription{
  stream_instrument(
  where: {maturity_date: {_neq: "No Maturity"},
  _and: {is_visible: {_eq: true}}},
  order_by: {maturity_date: asc}) {
    id
    maturity_date
  }
}
`

const UPDATE_STREAM_INSTRUMENT = gql`
mutation($ids: [Int!]!, $name: String!, $maturity: String!) {
  update_stream_instrument(
    where: {id: {_in: $ids}},
    _set: {name: $name, maturity_date: $maturity}
  ){
    returning{
      id
      name
      maturity_date
    }
  }
} 
`

const columns = [
  {
    title: 'Stream Instrument Name',
    dataIndex: 'name',
  },
  {
    title: 'Stream',
    dataIndex: 'stream',
    render: (x) => x.connector.name + (x.account_name === '' ? '' : '-') + x.account_name,
  },
  {
    title: 'Instrument',
    dataIndex: 'instrument',
    render: (x) => x.name,
  },
  {
    title: 'Maturity',
    dataIndex: 'maturity_date',
  },
  {
    title: 'Days Till Expiry',
    dataIndex: 'maturity_date',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => {
      const daysTillExpiryA = Math.floor((new Date(a.maturity_date) - new Date()) / 86400000)
      const daysTillExpiryB = Math.floor((new Date(b.maturity_date) - new Date()) / 86400000)
      return daysTillExpiryA - daysTillExpiryB
    },
    render: (x) => Math.floor((new Date(x) - new Date()) / 86400000),
  },
]

const CountToExpire =  () => {
  const subscription = useSubscription(COUNT_STREAM_INSTRUMENTS)

  if(subscription.loading)
    return
  
  let count = 0
  subscription.data.stream_instrument.map(
    (data) => { 
      if(Math.floor((new Date(data.maturity_date) - new Date()) / 86400000) < 5) {
        count++
      }
    }
  )

  return (
    <Badge count={count} offset={[5,-5]}>
    </Badge>
  )
}


function Loading() {
  return (
    <Col span={24} style={{ padding: 10 }}>
      <Spin tip="Loading...">
        <Alert
          message="Please wait for the server to be available."
          description="You will be able to issue commands once fully loaded."
          type="info"
        />
      </Spin>
    </Col>
  )
}

const RolloverModalForm = ({
  isModalVisible,
  setIsModalVisible,
  selectedRowKeys,
  setLoading,
  setSelectedRowKeys
}) => {
  const [updateStreamInstrument] = useMutation(UPDATE_STREAM_INSTRUMENT)
  const [form] = Form.useForm()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setSelectedRowKeys([])
    form.resetFields()
  }

  const onFinish = (values) => {
    updateStreamInstrument({
      variables: {
        ids: selectedRowKeys,
        name: values.rolloverName,
        maturity: values.new_maturity
      }
    }).then((response) => {
      if (response) {
        message.success('Submission successful!')
        handleOk()
      } else {
        message.error('Submission failed, please try again.')
      }
    })
    handleOk()
    setLoading(false)
    form.resetFields()
    setSelectedRowKeys([])
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    setSelectedRowKeys([])
  }

  return (
    <>
      <Modal title="Enter Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Form
          form={form}
          name="rolloverForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item 
            label="Rollover Name" 
            name="rolloverName" 
            rules={[{ required: true, message: 'Please input the rollover name!' }]} 
          > 
            <Input /> 
          </Form.Item>
          <Form.Item
            label="Maturity"
            name="new_maturity"
            rules={[{ required: true, message: 'Please input the maturity date!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}



function NotificationScreen(props) {
  const subscription = useSubscription(STREAM_INSTRUMENTS)
  const [rolloverStreamInst, setRolloverStreamInst] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filteredInfo, setFilteredInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  
  const memoizedRolloverStreamInst = useMemo(() => {
    return subscription.data?.stream_instrument.filter(x => x.maturity_date.includes('-'))
  }, [subscription.data])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }


  const update = () => {
    setLoading(true)
    setIsModalVisible(true)
  }

  useEffect(() => {
    if (!subscription.loading) {
      setRolloverStreamInst(memoizedRolloverStreamInst)
      setSelectedRowKeys([]) 
    }
  }, [subscription.loading, memoizedRolloverStreamInst])

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const hasSelected = selectedRowKeys.length > 0

  let uniqueStreamInstrument = []
  let streamInstrumentFilters = []
  let uniqueInstrument = []
  let instrumentFilters = []

  if(!subscription.loading && rolloverStreamInst) {
    uniqueStreamInstrument = Array.from(new Set(rolloverStreamInst.map(item => item.name)))
    streamInstrumentFilters = uniqueStreamInstrument.map(name => ({ text: name,   value: name })) 

    uniqueInstrument = Array.from(new Set(rolloverStreamInst.map(item => item.instrument.name)))
    instrumentFilters = uniqueInstrument.map(name => ({ text: name,   value: name })) 
  }

  const rollover_columns = [
    {
      title: 'Instrument',
      dataIndex: 'instrument',
      filterSearch: true,
      filters: instrumentFilters,
      onFilter: (value: string, record) => 
        record.instrument.name.toLowerCase().includes(value.toLowerCase()),
      render: (x) => x.name,
    },
    {
      title: 'Stream Instrument Name',
      filterSearch: true,
      filters: streamInstrumentFilters,
      onFilter: (value: string, record) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
      dataIndex: 'name',
    },
    {
      title: 'Stream',
      dataIndex: 'stream',
      render: (x) => x.connector.name + (x.account_name === '' ? '' : '-') + x.account_name,
    },
    {
      title: 'Maturity Date',
      dataIndex: 'maturity_date',
    },
    {
      title: 'Days Till Expiry',
      dataIndex: 'maturity_date',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        const daysTillExpiryA = Math.floor((new Date(a.maturity_date) - new Date()) / 86400000)
        const daysTillExpiryB = Math.floor((new Date(b.maturity_date) - new Date()) / 86400000)
        return daysTillExpiryA - daysTillExpiryB
      },
      render: (x) => Math.floor((new Date(x) - new Date()) / 86400000),
    },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    if (Object.keys(filters).length > 0) {
      setSelectedRowKeys([])
    }
    setFilteredInfo(filters)
  }


  return (
    <div> 
      <div
        style={{
          marginBottom: 8,
          float: 'right',
        }}
      >
        <span
          style={{
            marginRight: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
        <Button type="primary" onClick={update} disabled={!hasSelected} loading={loading}>
          Update
        </Button>
      </div>
      <RolloverModalForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedRowKeys={selectedRowKeys}
        setLoading={setLoading}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <Table
        dataSource={rolloverStreamInst}
        onChange={handleTableChange}
        rowKey='id'
        rowSelection={rowSelection}
        size='small'
        title={() => <h3>Rollover Stream Instrument</h3>}
        columns={rollover_columns}
        defaultExpandAllRows={false}
        rowClassName={
          ({ maturity_date }) => Math.floor((new Date(maturity_date) - new Date()) / 86400000) < 5 ? 'expire-row' : null
        }
        pagination={{
          defaultPageSize: 500,
        }}
      />
    </div>
  )
}

export { 
  CountToExpire,
}
export default NotificationScreen 

