
const defaultTheme = 'light'

const loadThemeCss = (name) => {
  switch (name) {
  case 'dark':
    require('./dark.css')
    break
  default:
    require('./light.css')
    break
  }
  require('./base.css')
}

export const getTheme = (themeName) => {
  if (window.localStorage) {
    const currentThemeName = window.localStorage.getItem('themeName')
    return currentThemeName || defaultTheme
  }
}

export const setTheme = (themeName) => {
  if (window.localStorage) {
    window.localStorage.setItem('themeName', themeName)
    window.location.reload(false)
  }
}

window.setTheme = setTheme

loadThemeCss(getTheme())
