import React from 'react'
import {
  Row,
} from 'antd'

import SlipGraph from './SlipGraph'

function Slip() {
  return (
    <Row>
      <SlipGraph />
    </Row>
  )
}

export default Slip
