import bcrypt from 'bcryptjs'

export const hash = async (str) => {
  const salt = await bcrypt.genSalt(12)
  return await bcrypt.hash(str, salt)
}

export default {
  hash,
}
