import React from 'react'
import {  Form, Input, Button } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'

import { setAuthenticationToken } from 'actions/Authentication'
import { getTheme } from 'utils/theme'

const AUTHENTICATE = gql`
  mutation($username: String!, $password: String!) {
    authenticate(username: $username, password: $password) {
      token
      success
    }
  }
`

function Login() {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [authenticate] = useMutation(AUTHENTICATE)
  const theme = getTheme()

  const handleSubmit = async (values) => {
    const {
      data: {
        authenticate: {
          token,
          success,
        }
      }
    } = await authenticate({
      variables: {
        username: values.email,
        password: values.password,
      }
    })

    if (success) {
      dispatch(setAuthenticationToken(token))
    } else {
      form.setFields([
        {
          name: 'email',
          value: values.email,
          errors: [''],
        },
        {
          name: 'password',
          value: values.password,
          errors: [
            'Invalid account credentials!',
          ],
        },
      ])
    }
  }

  return (
    <div style={{
      backgroundColor: theme === 'dark' ? 'black' : 'white',
      height: '100%',
      width: '100%',
      textAlign: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      display: 'table',
    }}>
      <div style={{ verticalAlign: 'middle', display: 'table-cell', textAlign: 'center' }}>
        <div style={{textAlign: 'center'}}>
          <img src={`logo-${theme}.png`} alt='logo' />
        </div>

        <div style={{width: '300px', display: 'inline-block' }}>
          <Form form={form} onFinish={handleSubmit} className='login-form'>
            <Form.Item name='email' rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input prefix={<UserOutlined />} placeholder='Email' />
            </Form.Item>
            <Form.Item name='password' rules={[{ required: true, message: 'Please input your Password!' }]}>
              <Input prefix={<LockOutlined />} type='password' placeholder='Password' />
            </Form.Item>
            <Form.Item>
              <Button htmlType='submit' className='login-form-button'>
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login
