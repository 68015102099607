import React, { useEffect, useState, useRef } from "react"
import { Popconfirm, Collapse, Space, Checkbox, Col, Tag, Button, Spin, Alert, Table as AntTable } from 'antd'
import {
  ExclamationCircleOutlined,
  SyncOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { useQuery, useSubscription, useMutation } from '@apollo/react-hooks'
import { Mutation } from 'react-apollo'
import moment from 'moment'
import cronParser from 'cron-parser'
import gql from 'graphql-tag'
import _ from 'lodash'
import { convert } from 'crontzconvert'

import Table from 'components/Table'
import { VolatilityProfileSelect, RuleSelect, ResolveRuleSelect } from '../Settings/RuleSelect'

const UPDATE_TROUPE = gql`
mutation($volatility_profile_id: Int, $troupe_id: Int, $trade_rule_id: Int) {
  update_troupe_volatility_profile_rule(where: {_and: [{volatility_profile_id: {_eq: $volatility_profile_id}}, {troupe_id: {_eq: $troupe_id}}]}, _set: {trade_rule_id: $trade_rule_id}){
      affected_rows
    }
  }
`

const UPDATE_TROUPE_RESOLVE = gql`
  mutation($volatility_profile_id: Int, $troupe_id: Int, $resolve_rule_id: Int) {
    update_troupe_volatility_profile_rule(where: {_and: [{volatility_profile_id: {_eq: $volatility_profile_id}}, {troupe_id: {_eq: $troupe_id}}]}, _set: {resolve_rule_id: $resolve_rule_id}){
        affected_rows
      }
    }
`

const UPDATE_VOLATILITY_PROFILE = gql`
  mutation($current_volatility_id: Int) {
    update_runtime(where: {}, _set: {current_volatility_id: $current_volatility_id}) {
      affected_rows
    }
  }
`

const UPDATE_TROUPE_AUTO_RESOLVE = gql`
  mutation($volatility_profile_id: Int, $troupe_id: Int, $auto_resolve: Boolean) {
    update_troupe_volatility_profile_rule(where: {_and: [{volatility_profile_id: {_eq: $volatility_profile_id}}, {troupe_id: {_eq: $troupe_id}}]}, _set: {auto_resolve: $auto_resolve}){
        affected_rows
      }
    }
`

const UPDATE_TROUPE_AUTO_TRADE = gql`
  mutation($volatility_profile_id: Int, $troupe_id: Int, $auto_trade: Boolean) {
    update_troupe_volatility_profile_rule(where: {_and: [{volatility_profile_id: {_eq: $volatility_profile_id}}, {troupe_id: {_eq: $troupe_id}}]}, _set: {auto_trade: $auto_trade}){
        affected_rows
      }
    }
`


const RUNTIME_SUBSCRIPTION = gql`
  subscription {
    runtime(
      order_by: { id: desc },
      limit: 1
    ) {
      id
      current_volatility_id
      created_at
    }
  }
`

const STATE_SNAPSHOT_SUBSCRIPTION = gql`
  subscription ($current_volatility_id : Int!) {
    state(
     where: {_or: [
       {troupe: {is_visible: {_eq: true}}},
       {troupe_id: {_is_null: true}}]},
     order_by: {troupe: {priority: asc, name: asc}}
    ) {
      id: troupe_id
      troupe_id
      troupe {
        id
        name
        priority
        is_visible
        troupe_volatility_profile_rules(
          where: { volatility_profile_id: { _eq: $current_volatility_id} }
        ) {
          id
          trade_rule_id
          resolve_rule_id
          auto_resolve
          auto_trade
        }
      }
      type
      status
    }
  }
`
//        exposure {
//          lot
//          position_count
//        }

const COMMAND_MUTATION = gql`
  mutation($command: [command_insert_input!]!) {
    insert_command(objects: $command) {
      affected_rows
    }
  }
`

const TROUPE_EXPOSURE = gql`
subscription {
  troupe_exposure(where: {_and: [{troupe_id: {_is_null: false}}, {lot: {_neq: 0}}]}){
    id: troupe_id
    troupe_id
    lot
  }
}
`

const TROUPE_HAS_TRADE = gql`
subscription {
  troupe_has_trades(where: {troupe_id: {_is_null: false}, _and: {lot: {_gt: 0}}}){
    id: troupe_id
    troupe_id
  }
}
`
const VOLATILITY_PROFILE_SUBSCRIPTION = gql`
subscription {
  volatility_profile(where: {is_visible: {_eq: true}}) {
    id
    name
  }
} 
`
const GET_VIX = gql`
query {
  getMTInstrument(symbol: "VIX") {
    id
    name
    BID
  }
} 
`

const TROUPE_HAS_REJECTED = gql`
subscription{
  troupe_has_rejected {
    id: troupe_id
    troupe_id
    troupe_name
    current_runtime_id
  }
}
`

const UPCOMMING_CRON_COMMANDS = gql`
subscription {
  cron_job(where: {jobname: {_neq: "update_current_positions_job"}}) {
    id: jobname
    jobname
    schedule
    command_type
  }
}
`

function Loading() {
  return (
    <Col span={24} style={{ padding: 10 }}>
      <Spin tip="Loading...">
        <Alert
          message="Please wait for the server to be available."
          description="You will be able to issue commands once fully loaded."
          type="info"
        />
      </Spin>
    </Col>
  )
}

function Runtime() {
  const { loading, error, data } = useSubscription(RUNTIME_SUBSCRIPTION)

  if (loading || !(data.runtime && data.runtime.length)) {
    return <Loading />
  }

  if (error) {
    return "error"
  }

  return <Control runtime={data.runtime[0]} />
}


const renderTroupeState = ({ status, type }) => (
  <>
    <Tag color={
      type === 'halt' ? (
        status === 'done' ? 'magenta' :
          status === 'pending' ? 'geekblue' : 'default') :
        type === 'stop' ? (
          status === 'done' ? 'magenta' :
            status === 'pending' ? 'geekblue' : 'default') :
          type === 'trade' ? (
            status === 'done' ? 'processing' :
              status === 'pending' ? 'geekblue' : 'default') :
            type === 'resolve' ? (
              status === 'done' ? 'warning' :
                status === 'pending' ? 'geekblue' : 'default') : 'error'
    }>
      {(status === 'error' || type === 'error') && <ExclamationCircleOutlined />}
      {type}
    </Tag>
    {status === 'pending' && <SyncOutlined spin />}
    {status === 'error' && <WarningOutlined />}
  </>
)


const renderServerState = ({ status, type }) => (
  <>
    <Tag color={
      type === 'halt' ? (
        status === 'done' ? 'magenta' :
          status === 'pending' ? 'warning' : 'default') :
        type === 'stop' ? (
          status === 'done' ? 'processing' :
            status === 'pending' ? 'warning' : 'default') : 'default'
    }>
      {(status === 'error' || type === 'error') && <ExclamationCircleOutlined />}
      {status === 'done' && type === 'stop' ? 'active' : type}
    </Tag>
    {status === 'pending' && <SyncOutlined spin />}
    {status === 'error' && <WarningOutlined />}
  </>
)

function VolatilityProfileControl({global, runtime, volatilityProfile}) {
  const [vixData, setVixData] = useState(null)
  const {data: vix, loading: vixLoading, error, refetch} = useQuery(GET_VIX)

  useEffect(() => {
    const fetchData = async () => {
      refetch()
    }

    fetchData()
    const intervalId = setInterval(fetchData, 60000)

    return () => clearInterval(intervalId)
  }, [refetch]) 


  let dataSource = [{
    key: 'VIX-1',
    vix: 0 
  }]

  if (vix && vix.getMTInstrument) {
    dataSource = [
      {
        key: 'VIX-1',
        vix: vix.getMTInstrument.BID
      }
    ]
  }


  const columns = [
    {
      title: <span>VIX</span>,
      align: 'center',
      key: 'vix',
      dataIndex: 'vix',
    }, {
      title: <span>Suggested Profile</span>,
      align: 'center',
      dataIndex: 'label',
      key: 'label',
      render: (text, row) => {
        return (
          row.vix > 25
            ? <Tag color="geekblue">High</Tag>
            : row.vix >= 15
              ? <Tag color="lime">Medium</Tag>
              : <Tag color="orange">Low</Tag>
        )
      }
    },{
      title: 'Profile', 
      ellipsis: true,
      align: 'center',
      render: (row) => (
        <Mutation mutation={UPDATE_VOLATILITY_PROFILE}>
          {(updateVolatility) => (
            <VolatilityProfileSelect
              size='small'
              style={{ width: '100%' }}
              currentruleid={runtime.current_volatility_id || ''}
              onSelect={(value) => {
                const confirmationMessage = `Please make sure all rules are stopped
before updating the Volatility Profile`
                const isConfirmed = window.confirm(confirmationMessage)
                if (isConfirmed)
                  updateVolatility({
                    variables: {
                      current_volatility_id: value,
                    }
                  })
              }}
            /> 
          )}
        </Mutation>
      )
    }
  ]
  return (
    <Table
      bordered={false}
      pagination={false}
      showHeader={true}
      rowKey='vix'
      size='small'
      columns={columns}
      dataSource={dataSource}
      defaultExpandAllRows={false}
    />
  )
}

function TroupeControl({ disabled, runtime, troupe, hasTradeMap, exposure, hasRejected }) {
  const [commandMutation] = useMutation(COMMAND_MUTATION)
  const insertCommand = command => commandMutation({
    variables: { command },
  })

  const makeTroupeCommandAll = type => () => {
    insertCommand(
      troupe.filter(
        row =>
          row.troupe.troupe_volatility_profile_rules[0][`${type}_rule_id`]
          && row.type === 'stop'
          && (type === 'resolve' ? hasTradeMap.includes(row.id) : true)
      ).map(
        row => ({
          troupe_id: row.troupe.id,
          runtime_id: runtime.id,
          type,
        })
      )
    )
  }
  const troupeFilter = troupe.map( data => { 
    const name = data.troupe.name
    return { text: name , value: name } 
  })

  const columns = [
    {
      title: <span>&ensp;&emsp;Troupe</span>,
      align: 'center',
      width: '10%',
      dataIndex: ['troupe', 'name'],
      defaultFilteredValue: window.localStorage ? window.localStorage.getItem('control-troupe-filter') ? window.localStorage.getItem('control-troupe-filter').split(',') : []:[],
      filterSearch: true,
      filters: troupeFilter, 
      onFilter: (value, record) => record.troupe.name === value,
      render: (troupe, record) => (
        <>
          {hasRejected['troupe_has_rejected'].some(item => item['id'] === record.id)
            ? <Tag icon={<ExclamationCircleOutlined />} color="warning">{troupe}</Tag>
            : troupe}
        </>
      ),
    }, /*{
      title: <span>&ensp;&emsp;</span>,
      align: 'center',
      dataIndex: ['troupe', 'exposure'],
      render: (exposure) => {
        const { lot = 0, position_count = 0 } = exposure || { }
        return (
          lot !== 0
          ? <Tag color='error'>UHEDGED</Tag>
          : (position_count !== 0
              ? <Tag color='success'>HEDGED</Tag>
              : <Tag color='processing'>FLAT</Tag>))
      },
    }, */{
      title: 'Trade Rule', 
      ellipsis: true,
      align: 'center',
      width: '22%',
      render: (row) => (
        <Mutation mutation={UPDATE_TROUPE}>
          {(updateTroupe) => (
            <RuleSelect
              size='small'
              style={{ width: '100%' }}
              currentruleid={row.troupe.troupe_volatility_profile_rules[0].trade_rule_id || ''}
              onSelect={(value) => {
                updateTroupe({
                  variables: {
                    volatility_profile_id: runtime.current_volatility_id,
                    troupe_id: row.troupe.id,
                    trade_rule_id : value || null,
                  }
                })
              }}
            /> 
          )}
        </Mutation>
      )
    }, {
      title: 'Resolve Rule', 
      ellipsis: true,
      align: 'center',
      width: '22%',
      render: (row) => (
        <Mutation mutation={UPDATE_TROUPE_RESOLVE}>
          {(updateTroupe) => (
            <ResolveRuleSelect
              size='small'
              style={{ width: '100%' }}
              currentruleid={row.troupe.troupe_volatility_profile_rules[0].resolve_rule_id || ''}
              onSelect={(value) => {
                updateTroupe({
                  variables: {
                    volatility_profile_id: runtime.current_volatility_id,
                    troupe_id: row.troupe.id,
                    resolve_rule_id : value || null,
                  }
                })
              }}
            /> 
          )}
        </Mutation>
      )
    }, {
      title: 'Auto Resolve', 
      ellipsis: true,
      align: 'center',
      width: '8%',
      render: (row) => (
        <Mutation mutation={UPDATE_TROUPE_AUTO_RESOLVE}>
          {(toggleAutoResolve) => (
            <Checkbox
              size='small'
              checked={row.troupe.troupe_volatility_profile_rules[0].auto_resolve}
              onClick={() => {
                toggleAutoResolve({
                  variables: {
                    volatility_profile_id: runtime.current_volatility_id,
                    troupe_id: row.troupe.id,
                    auto_resolve: !row.troupe.troupe_volatility_profile_rules[0].auto_resolve
                  }
                })
              }}
            />
          )}
        </Mutation>
      )
    }
    //, {
    //      title: 'Auto Trade', 
    //      ellipsis: true,
    //      align: 'center',
    //      width: '8%',
    //      render: (row) => (
    //        <Mutation mutation={UPDATE_TROUPE_AUTO_TRADE}>
    //          {(toggleAutoTrade) => (
    //            <Checkbox
    //              size='small'
    //              disabled
    //              checked={row.troupe.auto_trade}
    //              onClick={() => {
    //                toggleAutoTrade({
    //                  variables: {
    //                    volatility_profile_id: runtime.current_volatility_id,
    //                    troupe_id: row.troupe.id,
    //                    auto_trade: !row.troupe.auto_trade
    //                  }
    //                })
    //              }}
    //            />
    //          )}
    //        </Mutation>
    //      )
    //    }
    ,  {
      title: 'Status',
      key: 'status',
      align: 'center',
      width: '8%',
      ellipsis: true,
      render: renderTroupeState,
    }, {
      title: 'Operating Mode',
      key: 'operation',
      align: 'center',
      ellipsis: true,
      render: ({ id, type, troupe_id, troupe: {troupe_volatility_profile_rules}}) => {
        const args = { troupe_id, runtime_id: runtime.id }
        const makeExecute = (type) => () => insertCommand([{ ...args, type }])
        return (
          <Button.Group size='small' type='ghost'>
            <Button disabled={type !== 'stop' || !troupe_volatility_profile_rules[0].resolve_rule_id} onClick={makeExecute('resolve')}>Resolve</Button>
            <Button disabled={type !== 'stop' || !troupe_volatility_profile_rules[0].trade_rule_id || (troupe_volatility_profile_rules[0].auto_resolve && !troupe_volatility_profile_rules[0].resolve_rule_id)} onClick={makeExecute('trade')}>Trade</Button>
            <Button disabled={type === 'stop'} onClick={makeExecute('stop')}>Stop</Button>
          </Button.Group>
        )
      },
    }
  ]
  return (
    disabled
      ? 
      <Alert
        message="Server offline"
        description="Control of troupes is disabled until the server is ready."
        type="warning"
        showIcon
      />
      : 
      <Table
        bordered={false}
        pagination={false}
        showHeader={true}
        rowKey='troupe_id'
        size='small'
        columns={columns}
        dataSource={troupe}
        defaultExpandAllRows={false}
        onChange={(pagination, filters, sorter) => {
          const newFilter = filters['troupe.name'] ? filters['troupe.name'] : []
          window.localStorage.setItem('control-troupe-filter', newFilter)
        }}
        summary={() => (
          <AntTable.Summary>
            <AntTable.Summary.Row className='summary-row'>
              <AntTable.Summary.Cell index={0}><center>All Trade Groups</center></AntTable.Summary.Cell>
              <AntTable.Summary.Cell index={1}></AntTable.Summary.Cell>
              <AntTable.Summary.Cell index={2}></AntTable.Summary.Cell>
              <AntTable.Summary.Cell index={3}></AntTable.Summary.Cell>
              <AntTable.Summary.Cell index={4}></AntTable.Summary.Cell>
              <AntTable.Summary.Cell index={5}>
                <center>
                  <Button.Group size='small' >
                    <Button style={{color: 'white', borderColor:'orange',  backgroundColor: 'orange'}} onClick={makeTroupeCommandAll('resolve')}>Resolve</Button>
                    <Button type='primary' onClick={makeTroupeCommandAll('trade')}>Trade</Button>
                    <Popconfirm
                      title={() => 
                        <div style={{textAlign: 'center'}}>
                          <span>Are you sure you want to stop?</span>
                          <br/>
                          <h4>Exposure status:</h4> 
                          <br/>
                          {exposure.troupe_exposure.length === 0 ?
                            <Tag color='success'>HEDGED</Tag> 
                            :
                            <Tag color='error'>UNHEDGED</Tag>
                          }
                        </div>
                      }
                      onConfirm={
                        () => insertCommand([{ runtime_id: runtime.id, type: 'stop' }])
                      }
                      onCancel={() => console.log('close Modal')}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type='danger' >Stop</Button>
                    </Popconfirm>
                  </Button.Group>
                </center>
              </AntTable.Summary.Cell>
            </AntTable.Summary.Row>
          </AntTable.Summary>
        )}
      />
  )
}


function ServerControl({ runtime, global, exposure }) {
  const [commandMutation] = useMutation(COMMAND_MUTATION)
  const [time, setTime] = useState(moment())
  const insertCommand = command => commandMutation({
    variables: { command },
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const columns = [
    {
      title: 'Emergency',
      key: 'emergency',
      align: 'center',
      render: () => {
        const args = { runtime_id: runtime.id }
        return (
          <Space>
            <Popconfirm
              title={() => 
                <div style={{textAlign: 'center'}}>
                  <span>Are you sure you want to foce stop the trader?</span>
                  <br/>
                  <h4>Exposure status:</h4> 
                  <br/>
                  {exposure.troupe_exposure.length === 0 ?
                    <Tag color='success'>HEDGED</Tag> 
                    :
                    <Tag color='error'>UNHEDGED</Tag>
                  }
                </div>
              }
              onConfirm={
                () => insertCommand([{ ...args, type: 'kill' }])
              }
              onCancel={() => console.log('close Modal')}
              okText="Yes"
              cancelText="No"
            >
              <Button size='small' danger type='primary'>Force Stop</Button>
            </Popconfirm>
          </Space>
        )},
    }, {
      title: 'Runtime Id',
      key: 'runtime_id',
      align: 'center',
      render: () => (
        <span>
          <h3>{runtime.id}</h3>
        </span>
      ),
    }, {
      title: 'Started',
      key: 'runtime',
      align: 'center',
      render: () => ( 
        <span> {moment.utc(runtime.created_at).local().format('YYYY-MM-DD HH:mm:ss')} </span>
      ),
    },{
      title: 'Current Time',
      key: 'currenttime',
      align: 'center',
      render: () => ( 
        <span> {moment().local().format('YYYY-MM-DD HH:mm:ss')} </span>
      ),
    }, {
      title: 'Uptime',
      key: 'uptime',
      align: 'center',
      render: () => { 
        const uptime = moment.duration(time.diff(moment.utc(runtime.created_at)))
        const days = uptime.get('days')
        const hours = String(uptime.get('hours')).padStart(2, '0')
        const minutes = String(uptime.get('minutes')).padStart(2, '0')
        const seconds = String(uptime.get('seconds')).padStart(2, '0')
        return (
          <span> {days + ' days ' + hours + ':' + minutes + ':' + seconds }</span>
        )
      },
    }, {
      title: 'Status',
      key: 'status',
      align: 'center',
      render: renderServerState,
    }, {
      title: 'Action',
      key: 'operation',
      align: 'center',
      render: () => {
        const args = { runtime_id: runtime.id }
        return (
          <Space>
            <Popconfirm
              title={() => 
                <div style={{textAlign: 'center'}}>
                  <span>Are you sure you want to stop?</span>
                  <br/>
                  <h4>Exposure status:</h4> 
                  <br/>
                  {exposure.troupe_exposure.length === 0 ?
                    <Tag color='success'>HEDGED</Tag> 
                    :
                    <Tag color='error'>UNHEDGED</Tag>
                  }
                </div>
              }
              onConfirm={
                () => insertCommand([{ ...args, type: 'stop' }])
              }
              onCancel={() => console.log('close Modal')}
              okText="Yes"
              cancelText="No"
            >
              <Button size='small' danger type='primary' >Stop</Button>
            </Popconfirm>
            <Popconfirm
              title={() => 
                <div style={{textAlign: 'center'}}>
                  <span>Are you sure you want to reset?</span>
                  <br/>
                  <h4>Exposure status:</h4> 
                  <br/>
                  {exposure.troupe_exposure.length === 0 ?
                    <Tag color='success'>HEDGED</Tag> 
                    :
                    <Tag color='error'>UNHEDGED</Tag>
                  }
                </div>
              }
              onConfirm={
                () => insertCommand([{ ...args, type: 'halt' }])
              }
              onCancel={() => console.log('close Modal')}
              okText="Yes"
              cancelText="No"
            >
              <Button size='small'type='primary'>Reset</Button>
            </Popconfirm>
          </Space>
        )
      }
    },
  ]

  return (
    <Table
      bordered={false}
      pagination={false}
      showHeader={true}
      rowKey='troupe_id'
      size='small'
      columns={columns}
      dataSource={global ? [global] : []}
      defaultExpandAllRows={false}
      footer={() => {
        const uptime = moment.duration(time.diff(moment.utc(runtime.created_at)))
        const diff = (time.diff(moment.utc(runtime.created_at)))
        return diff < 120000
          ?
          <Alert
            style={{textAlign:'center'}}
            message='Trader Recently Reset (> 2 min ago)'
            type='info'
          />
          :
          <span></span>
      }} 
    />
  )
}

function CronAlerts() {
  const { loading, error, data } = useSubscription(UPCOMMING_CRON_COMMANDS)
  const [time, setTime] = useState(moment())

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const willExecuteWithinNextFiveMinutes = (cronExpression) => {

    try {
      const interval = cronParser.parseExpression(cronExpression)
      const nextExecution = interval.next().toDate()
      const now = new Date()
      return (nextExecution - now)
    } catch (err) {
      console.error('Error parsing cron expression:', err)
      return new Date() 
    }
  } 

  if (loading) return <Loading />

  const upcomingCronJobs = data.cron_job.filter(job => willExecuteWithinNextFiveMinutes(convert(job.schedule, 'UTC', moment.tz.guess())) <= 5 * 60 * 1000)

  const commandTagColor = (command, id) => (
    <Tag key={id+'-tag'} color={
      command === 'stop' ? 'magenta' :
        command === 'trade' ? 'processing' :
          command === 'resolve' ? 'warning' :
            'geekblue'
    }>
      {command}
    </Tag>
  )

  const generateDescription = () => (
    <>
      {upcomingCronJobs.map(job => {
        const timeDiff = willExecuteWithinNextFiveMinutes(convert(job.schedule, 'UTC', moment.tz.guess()))
        const minutes = Math.floor(timeDiff / 60000)
        const seconds = Math.floor((timeDiff % 60000) / 1000)
        return (
          <div key={job.id+'-div'}>
            <br />
            {commandTagColor(job.command_type, job.id)}
            <span key={job.id + '-2'}>{`Scheduled to run in ${minutes} minute${minutes !== 1 ? 's' : ''} and ${seconds} second${seconds !== 1 ? 's' : ''}`}</span>
            <br />
          </div>
        )
      })}
    </>
  )

  return (
    <>
      {upcomingCronJobs.length === 0 ?
        <></>
        :
        <Alert
          key='cron-alert'
          message='Commands Scheduled to Execute'
          description={generateDescription()}
          type="info"
          showIcon
        />
      }
    </> 
  )
}

function Control({ runtime }) {
  const { loading, error, data } = useSubscription(
    STATE_SNAPSHOT_SUBSCRIPTION,
    { variables: { current_volatility_id: runtime.current_volatility_id}}
  )
  const {data: hasTrade, loading: hasTradeLoading} = useSubscription(TROUPE_HAS_TRADE)
  const {data: hasRejected, loading: hasRejectedLoading} = useSubscription(TROUPE_HAS_REJECTED)
  const [hasTradeMap, setHasTradeMap] = useState({})
  const {data: volatilityProfile, loading: volatilityProfileLoading} = useSubscription(VOLATILITY_PROFILE_SUBSCRIPTION)
  const {data: exposure, loading: exposureLoading} = useSubscription(TROUPE_EXPOSURE)

  useEffect(() => {
    if(!hasTradeLoading)
      setHasTradeMap(hasTrade.troupe_has_trades.map((t) => t.troupe_id))
  }, [hasTrade])


  if (loading || hasTradeLoading || volatilityProfileLoading || exposureLoading || hasRejectedLoading) {
    return <Loading />
  }

  if (error) {
    return <span>Error ...</span>
  }

  const [troupe, [global]] = _.partition(
    _.sortBy(data.state, ['troupe.priority']), o => Boolean(o.troupe)
  )

  //troupe.sort((a, b) => (a.troupe.name.split(' ')[1] ? parseInt(a.troupe.name[a.troupe.name.length - 1])  : 0) - (b.troupe.name.split(' ')[1] ? parseInt(b.troupe.name[b.troupe.name.length - 1])  : 0))

  //troupe.sort((a, b) => {
  //  const lastCharA = a.troupe.name.charAt(a.troupe.name.length - 1)
  //  const lastCharB = b.troupe.name.charAt(b.troupe.name.length - 1)
  //  const intA = isNaN(parseInt(lastCharA)) ? 0 : parseInt(lastCharA)
  //  const intB = isNaN(parseInt(lastCharB)) ? 0 : parseInt(lastCharB)
  //  return intA - intB
  //}) 

  const isDisabled = !global || (global.type === 'halt' || global.type === 'error')
  return (
    <>
      <Col span={24} style={{ padding: 10}}>
        <CronAlerts />
      </Col>
      <Col span={24} style={{ padding: 10}}>
        <Collapse defaultActiveKey={['1']} bordered={false} >
          <Collapse.Panel header={<h4>Server</h4>} key="1">
            <ServerControl global={global} runtime={runtime} exposure={exposure}/>
          </Collapse.Panel>
        </Collapse>
      </Col>
      <Col span={24} style={{ padding: 10}}>
        <Collapse defaultActiveKey={['1']} bordered={false} >
          <Collapse.Panel header={<h4>Volatility Profile</h4>} key="1">
            <VolatilityProfileControl global={global} runtime={runtime} volatilityProfile={volatilityProfile} />
          </Collapse.Panel>
        </Collapse>
      </Col>
      <Col span={24} style={{ padding: 10 }}>
        <Collapse defaultActiveKey={['1']} bordered={false} >
          <Collapse.Panel header={<h4>Troupes</h4>} key="1">
            <TroupeControl disabled={isDisabled} troupe={troupe} runtime={runtime} hasTradeMap={hasTradeMap} exposure={exposure} hasRejected={hasRejected}/>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </>
  )
}



export default Runtime 
