
export const SET_AUTHENTICATION_TOKEN = 'SET_AUTHENTICATION_TOKEN'
export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT'


export const setAuthenticationToken = (value) => {
  return ({
    type: SET_AUTHENTICATION_TOKEN,
    payload: value,
  })
}


export const authenticationLogout = () => {
  return ({
    type: AUTHENTICATION_LOGOUT,
  })
}




