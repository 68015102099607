import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

export function mergeSubscription() {
  const result = { loading: false, error: false, data: { } }
  for (const index in arguments) {
    const argument = arguments[index]
    result.loading |= argument.loading 
    result.error |= argument.error 
    result.data = { ...result.data, ...argument.data }
  }
  return result
}

export function branchSelector(selector, ThenComponent, ElseComponent = null) {
  const BranchedComponent = props => {
    const result = useSelector(selector)
    const ResultComponent = result ? ThenComponent : ElseComponent
    return ResultComponent && <ResultComponent {...props} />
  }
  // Assign a display name for debugging purposes
  BranchedComponent.displayName = `BranchedComponent(${getDisplayName(ThenComponent)}, ${getDisplayName(ElseComponent)})`
  return BranchedComponent
}

// Helper function to get a component's display name
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

//export function branchSelector(selector, ThenComponent, ElseComponent=null) {
//  return props => {
//    const result = useSelector(selector)
//    const ResultComponent = result ? ThenComponent : ElseComponent
//    return ResultComponent && <ResultComponent {...props} />
//  }
//}

export function useChange(monitorValue, then, condition) {
  const [value, setValue] = useState(undefined)
  const [hasValue, setHasValue] = useState(false)
  useEffect(() => {
    if (condition(monitorValue)) {
      if (hasValue) {
        // then we compare the new error report against our last one
        if (!_.isEqual(value, monitorValue)) {
          setValue(monitorValue)
          then(monitorValue, value)
        }
      } else {
        // then we have encountered our first error report message
        // we do not raise notifications on our first subscription
        setValue(monitorValue)
        setHasValue(true)
      }
    }
  }, [monitorValue])
}


export const TRADING_SESSION = [
  { flag: '🇦🇺', start: 22, end:  6, color: '#f8615a', name: 'Sydney' },
  { flag: '🇯🇵', start: 23, end:  7, color: '#ffd868', name: 'Tokyo' },
  { flag: '🇩🇪', start:  6, end: 14, color: '#a8d3da', name: 'Frankfurt' },
  { flag: '🇬🇧', start:  7, end: 15, color: '#b80d57', name: 'London' },
  { flag: '🇺🇸', start: 12, end: 20, color: '#b80d57', name: 'New York' },
]

export function timeIntersectAt(array, time) {
  return array.filter(({ start, end }) => (
    start >= end
      ? (start <= moment.utc(time).hour() || moment.utc(time).hour() < end)
      : (start <= moment.utc(time).hour() && moment.utc(time).hour() < end)
  ))
}

export function hourIntersectAt(array, hour) {
  return array.filter(({ start, end }) => (
    start >= end
      ? (start <= hour || hour < end)
      : (start <= hour && hour < end)
  ))
}

export function tradingSessionAt(time) {
  return timeIntersectAt(TRADING_SESSION, time)
}


export function tradingSessionAtHour(hour) {
  return hourIntersectAt(TRADING_SESSION, hour)
}

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below). 
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler)
      }
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay]
  )

  return debouncedValue
}

export function useStateWithLocalStorage(localStorageKey, defaultValue=undefined) {
  const [value, setValue] = useState(
    window.localStorage.getItem(localStorageKey) || defaultValue
  )
  
  useEffect(() => {
    window.localStorage.setItem(localStorageKey, value)
  }, [localStorageKey, value])

  return [value, setValue]
}
