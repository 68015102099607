import React from 'react'
import { useMutation, useSubscription } from '@apollo/react-hooks'
import {
  Menu
} from 'antd'
import {
  UserDeleteOutlined,
  CoffeeOutlined
} from '@ant-design/icons'
import gql from 'graphql-tag'

const USER_STATUS = gql`
subscription{
  user_status_enum{
    id:value
    value
  }
}
`

const LOGGED_USER = gql`
subscription($userId: uuid){
  user(where: {id: {_eq: $userId}}) {
    id
    email
    name
    status
  }
}
`

const CHANGE_STATUS = gql`
mutation($userId: uuid!, $status: user_status!){
  update_user(_set: {status: $status}, where: {id: {_eq: $userId}}){
    returning{
      id
      name
      status
    }
  }
}
`

function UserStatusMenu(props) {
  const { loading, error, data } = useSubscription(USER_STATUS)
  const userInfo = useSubscription(LOGGED_USER, {variables: {userId: window.localStorage.getItem('user-id')}})
  const [statusMutation] = useMutation(CHANGE_STATUS)
  const changeStatus = stat => statusMutation({
    variables: { 
      userId: window.localStorage.getItem('user-id'),
      status: stat
    },
  })

  if(loading || userInfo.loading)
    return <></>

  return (
    <Menu selectedKeys={[userInfo.data.user[0].status]}>
      {data.user_status_enum.map((stat) =>
        <Menu.Item key={stat.id} onClick={() => changeStatus(stat.value)}>
          { stat.value === 'lunch' ?
            <CoffeeOutlined
              style={{color:'orange'}}
              key={stat.value+'-icon'}
            />
            :
            <UserDeleteOutlined
              style={stat.value === 'busy' ? {color:'red'} : stat.value === 'away' ? {color: 'blue'} : stat.value === 'active' ? {color: 'green'} : {color:'gray'}}
              key={stat.value +'-icon'}
            />
          }
          <span className="nav-text">{' ' + stat.value.charAt(0).toUpperCase() + stat.value.slice(1)}</span>
        </Menu.Item>
      )}
    </Menu>
  )
}

export default UserStatusMenu
