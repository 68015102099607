import React from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import { Table } from 'antd'
import { useSubscription } from '@apollo/react-hooks'
import { ExportOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons'

import SubscriptionTable from 'components/SubscriptionTable'

const EXECUTION_REPORT_SUBSCRIPTION = gql`
subscription {
  execution_report_grouped(order_by: {received_at: desc}, limit: 100) {
    id: client_order_id
    received_at
    new_at 
    client_order_id
    execution_type
    order_status
    side
    requested_price
    fill_price
    requested_size
    operation_id
    order_serial
    stream_instrument_id
    slip
    execution_time
    stream_instrument {
      id
      name
      stream {
        id
        account_name
        connector {
          id
          name
        }
      }
    }
    report_group
  }
}
`
const fix_status_map = {
  0 : 'New',
  1 : 'Partial fill',
  2 : 'Fill',
  3 : 'Done for day',
  4 : 'Canceled',
  5 : 'Replaced',
  6 : 'Pending Cancel',
  7 : 'Stopped',
  8 : 'Rejected',
  9 : 'Suspended',
  A : 'Pending New',
  B : 'Calculated',
  C : 'Expired',
  F : 'Trade (partial fill or fill)',
}

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: 'Symbol',
    key: 'stream_instrument',
    render: ({ stream_instrument, stream_instrument_id }) => stream_instrument.name ? stream_instrument.name : stream_instrument_id
  }, {
    title: 'Stream',
    key: 'stream_instrument',
    render: ({ stream_instrument, stream_instrument_id }) => stream_instrument.stream ? stream_instrument.stream.account_name || stream_instrument.stream.connector.name : stream_instrument_id
  }, {
    title: 'Order Status',
    key: 'order_status',
    dataIndex: 'order_status',
    render: (stat) => fix_status_map[stat],
  }, {
    title: 'Side',
    key: 'side',
    dataIndex: 'side',
    render: (side) => side === '1' ? 'Buy' : 'Sell',
  }, {
    title: 'Requested Price',
    key: 'requested_price',
    dataIndex: 'requested_price',
    render: (price) => price ? price.toFixed(5) : null,
  }, {
    title: 'Fill Price',
    key: 'fill_price',
    dataIndex: 'fill_price',
    render: (price) => price ? price.toFixed(5) : null,
  }, {
    title: 'Requested Size',
    key: 'requested_size',
    dataIndex: 'requested_size',
  }, {
    title: 'Slip',
    key: 'slip',
    dataIndex: 'slip',
    render: (price) => price ? price.toFixed(5) : null,
  }, {
    title: 'Execution Time',
    key: 'execution_time',
    dataIndex: 'execution_time',
  }
]

function ExecutionReport(props) {
  const subscription = useSubscription(EXECUTION_REPORT_SUBSCRIPTION)
  const expandedRowRender = (record) => {
    console.log(record.report_group.sort((a, b) => b.received_at - a.received_at))
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      }, {
        title: 'Order Status',
        key: 'order_status',
        dataIndex: 'order_status',
        render: (stat) => fix_status_map[stat],
      }, {
        title: 'Side',
        key: 'side',
        dataIndex: 'side',
        render: (side) => side === '1' ? 'Buy' : 'Sell',
      }, {
        title: 'Requested Price',
        key: 'requested_price',
        dataIndex: 'requested_price',
        render: (price) => price ? price.toFixed(5) : null,
      }, {
        title: 'Fill Price',
        key: 'fill_price',
        dataIndex: 'fill_price',
        render: (price) => price ? price.toFixed(5) : null,
      }, {
        title: 'Last Price',
        key: 'last_price',
        dataIndex: 'last_price',
      }, {
        title: 'Requested Size',
        key: 'requested_size',
        dataIndex: 'requested_size',
      }, {
        title: 'Last Size',
        key: 'last_size',
        dataIndex: 'last_size',
      }, {
        title: 'Operation Id',
        key: 'operation_id',
        dataIndex: 'operation_id',
      }, {
        title: 'Execution Time',
        key: 'execution_time',
        dataIndex: 'received_at',
        render: (received_at) => Math.round((received_at - record.new_at) / 1000000, 0)
      }
    ]
    return <Table rowKey='id' size='small' columns={columns} dataSource={record.report_group} pagination={false} />
  }
  return (
    <SubscriptionTable
      subscription={subscription}
      rowKey='id'
      size='small'
      columns={columns}
      defaultExpandAllRows={false}
      expandable={{ 
        expandedRowRender, 
        expandRowByClick: true,
        indentSize: 5,
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <MinusOutlined onClick={e => onExpand(e, record)} />
          ) : (
            <PlusOutlined onClick={e => onExpand(e, record)} />
          )
      }}
    />
  )
}

export default ExecutionReport

