import React from 'react'
import {
  Row,
  Col,
} from 'antd'

import UsersTable from './UsersTable'

function Users() {
  return (
    <Row>
      <Col span={24} style={{ padding: 10 }}>
        <UsersTable />
      </Col>
    </Row>
  )
}

export default Users 
