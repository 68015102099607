import React from 'react'

import {
  Collapse,
} from 'antd'

export function Panel({ title, children, ...restProps }) {
  return (
    <Collapse defaultActiveKey={['1']} bordered={false}>
      <Collapse.Panel header={<h4>{title}</h4>} key="1">
        {children}
      </Collapse.Panel>
    </Collapse>
  )
}

export default Panel
