import React, { useState } from 'react'
import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, Select, Modal, Input, Button } from 'antd'
import { useSelector } from 'react-redux'

import { isAdmin as getIsAdmin } from 'selectors/Authentication'
import bcrypt from 'utils/bcrypt'


const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i

const formItemLayout = {
  labelCol: {
    xs: { span: 16 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 32 },
    sm: { span: 16 },
  },
}


// NOTE The following shorthand is buggy, do not use: `const [form] = Form.useForm()'
function UserModal(props) {
  const [form, setForm] = useState(undefined)
  const [visible, setVisible] = useState(false)
  const isAdmin = useSelector(getIsAdmin)

  const showModal = () => {
    setVisible(true)
  }

  const handleOk = (e) => {
    handleReset()
    setVisible(false)
  }

  const handleCancel = (e) => {
    handleReset()
    setVisible(false)
  }

  const handleReset = () => {
    form && form.resetFields()
  }

  const handleSubmit = async (values) => {
    if (props.user) {
      const password = (
        values.newPassword
          ? await bcrypt.hash(values.newPassword)
          : props.user.password
      )

      await props.updateUser({
        variables: {
          id: props.user.id,
          user: {
            id: props.user.id,
            name: values.newName,
            email: values.newEmail,
            password,
            role: values.newRole,
          },
        },
      })
      handleOk()
    } else if (values.newName) {
      const password = await bcrypt.hash(values.newPassword)
      await props.insertUser({
        variables: {
          name: values.newName,
          email: values.newEmail,
          password,
          role: values.newRole,
        },
      })
      handleOk()
    }
  }

  const {
    title,
    children,
    user,
  } = props

  const submit = () => form && form.submit()

  return (
    <>
      {children(showModal)}
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={
          <div style={{ textAlign: 'right' }}>
            {user
              ? <>
                <Button
                  style={{float: 'left'}}
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    (async () => {
                      await props.deleteUser({
                        variables: {
                          id: user.id,
                        }
                      })
                      handleOk()
                    })()
                  }}
                />
                &nbsp;
                <Button
                  icon={<SaveOutlined />}
                  type='primary'
                  onClick={submit}
                />
              </>
              : <>
                <Button
                  icon={<PlusOutlined />}
                  type='primary'
                  onClick={submit}
                />
              </>
            }
          </div>
        }
      >
        {visible &&
      <Form
        ref={setForm}
        onFinish={handleSubmit}
        initialValues={{
          newName: user ? user.name : '',
          newEmail: user ? user.email : '',
          newRole: user ? user.role : 'viewer',
          newPassword: '',
        }}
      >
        <Form.Item
          label='Name'
          name='newName'
          rules={[{
            required: true,
            message: 'Name is required',
          }]}
          {...formItemLayout}
        >
          <Input
            autoComplete="false"
          />
        </Form.Item>
        <Form.Item
          label='Email'
          name='newEmail'
          rules={[{
            required: true,
            message: 'Email is required',
          }, {
            required: true,
            pattern: EMAIL_REGEXP,
            message: 'Email is required',
          }]}
          {...formItemLayout}
        >
          <Input
            autoComplete="false"
          />
        </Form.Item>
        <Form.Item
          label='Role'
          name='newRole'
          rules={[{
            required: true,
            message: 'Mode is required',
          }]}
          {...formItemLayout}
        >
          <Select>
            {isAdmin && <Select.Option value='admin'>
              Admin
            </Select.Option>}
            <Select.Option value='manager'>
              Manager
            </Select.Option>
            <Select.Option value='viewer'>
              Viewer
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Password'
          name='newPassword'
          rules={[{
            required: !props.user,
            message: 'Password is required',
          }]}
          {...formItemLayout}
        >
          <Input
            autoComplete="false"
            placeholder={props.user ? '(password unchanged)' : ''}
          />
        </Form.Item>
      </Form>
        }
      </Modal>
    </>
  )
}

export default UserModal
