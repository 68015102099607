import React from 'react'
import { Table } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { useSubscription } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import _ from 'lodash'

import Panel from 'components/Panel'
import SubscriptionTable from 'components/SubscriptionTable'

const PNL_STREAM = gql`
  subscription {
    pnl_stream(
      where: { lot: { _eq: 0 } },
      order_by: { created_at: desc  }
    ) {
      instrument {
        id
        name
      }
      stream {
        id
        account_name
        connector {
          id
          name
        }
      }
      balance
      commission
      created_at
    }
  }
`



const PNL_DAILY = gql`
  subscription {
    pnl_daily(
      order_by: { completed_at: desc  }
    ) {
      instrument {
        id
        name
      }
      positive_sum
      negative_sum
      total_trade_count
      profit_trade_count
      pnl
      commission
      completed_at
    }
  }
`


const PNL_CURRENCY_DAILY = gql`
  subscription {
    currency_pnl_daily(
      where: { completed_at: { _gte: "2020-08-01" } }
      order_by: { completed_at: desc  }
    ) {
      currency {
        id
        name
      }
      positive_sum
      negative_sum
      total_trade_count
      profit_trade_count
      pnl
      commission
      completed_at
      exchange_rate {
        id
        rate
      }
    }
  }
`



const PNL_MONTHLY = gql`
  subscription {
    pnl_monthly(
      order_by: { completed_at: desc  }
    ) {
      instrument {
        id
        name
      }
      positive_sum
      negative_sum
      total_trade_count
      profit_trade_count
      pnl
      pnl_daily_average
      commission
      completed_at
      day_count
    }
  }
`




const numericColumnStyle = {
  align: 'right',
  render: x => x && x.toFixed(2),
}

const integerColumnStyle = {
  align: 'right',
  render: x => x && x.toLocaleString(),
}

const columns = [
  { title: 'Instrument', dataIndex: ['instrument', 'name'], key: 'instrument' },
  { title: '+Ve Opp', dataIndex: 'positive_sum', ...numericColumnStyle  },
  { title: '-Ve Opp', dataIndex: 'negative_sum', ...numericColumnStyle  },
  { title: 'Commission', dataIndex: 'commission', ...numericColumnStyle  },
  { title: 'PNL', dataIndex: 'pnl', ...numericColumnStyle },
  { title: 'Profit Opp Count', dataIndex: 'profit_trade_count', ...integerColumnStyle },
  { title: 'Total Opp Count', dataIndex: 'total_trade_count', ...integerColumnStyle },
  { title: 'Date', dataIndex: 'completed_at' },
]

export function PnlStreamTable() {
  const columns = [
    { title: 'Instrument', dataIndex: ['instrument', 'name'], key: 'instrument' },
    { title: 'Stream', render: ({ stream: { connector: { name }, account_name }}) => (account_name ? `${name} (${account_name})` : name) },
    { title: 'Balance (USD)', render: ({ balance, commission }) => (balance - commission) },
    //    { title: 'Balance', dataIndex: 'balance'  },
    //    { title: 'Commission', dataIndex: 'commission' },
    { title: 'Date', dataIndex: 'created_at' },
  ]

  const subscription = useSubscription(PNL_STREAM)
  return (
    <SubscriptionTable
      columns={columns}
      subscription={subscription}
    />
  )
}

export function PnlInstrumentTable({ title, subscription }) {
  return (
    <Panel title={title}>
      <SubscriptionTable
        columns={columns}
        subscription={subscription}
      />
    </Panel>
  )
}


export function PnlInstrumentDailyTable() {
  const subscription = useSubscription(PNL_DAILY)
  return (
    <PnlInstrumentTable
      title='PNL Daily'
      subscription={subscription}
    />
  )
}


export function PnlInstrumentMonthlyTable() {
  const columns = [
    { title: 'Instrument', dataIndex: ['instrument', 'name'], key: 'instrument' },
    { title: '+Ve Opp', dataIndex: 'positive_sum', ...numericColumnStyle  },
    { title: '-Ve Opp', dataIndex: 'negative_sum', ...numericColumnStyle  },
    { title: 'Commission', dataIndex: 'commission', ...numericColumnStyle  },
    { title: 'PNL', dataIndex: 'pnl', ...numericColumnStyle },
    { title: 'PNL Daily Average', dataIndex: 'pnl_daily_average', ...numericColumnStyle },
    { title: 'Day Count', dataIndex: 'day_count', ...integerColumnStyle },
    { title: 'Profit Opp Count', dataIndex: 'profit_trade_count', ...integerColumnStyle },
    { title: 'Total Opp Count', dataIndex: 'total_trade_count', ...integerColumnStyle },
    { title: 'Date', dataIndex: 'completed_at' },
  ]

  const subscription = useSubscription(PNL_MONTHLY)
  return (
    <Panel title='PNL Monthly'>
      <SubscriptionTable
        columns={columns}
        subscription={subscription}
      />
    </Panel>
  )
}

const expandedRowRender = (record) => {
  const columns = [
    { title: 'Currency', dataIndex: ['currency', 'name'], key: 'instrument' },
    { title: '+Ve Opp', dataIndex: 'positive_sum', ...numericColumnStyle  },
    { title: '-Ve Opp', dataIndex: 'negative_sum', ...numericColumnStyle  },
    { title: 'Commission', dataIndex: 'commission', ...numericColumnStyle  },
    { title: 'PNL', dataIndex: 'pnl', ...numericColumnStyle },
    { title: 'Profit Opp Count', dataIndex: 'profit_trade_count', ...integerColumnStyle },
    { title: 'Total Opp Count', dataIndex: 'total_trade_count', ...integerColumnStyle },
  ]

  return (
    <>
      <Table
        rowKey='broker'
        size='small'
        columns={columns}
        dataSource={record.records}
        pagination={false}
        style={{ padding: '20px' }}
      />
    </>
  )
}



export function PnlCurrencyDailyTable() {
  const subscription = useSubscription(PNL_CURRENCY_DAILY)
  const columns = [
    //    { title: 'Currency', dataIndex: ['currency', 'name'], key: 'instrument' },
    //    { title: '+Ve Opp', dataIndex: 'positive_sum', ...numericColumnStyle  },
    //    { title: '-Ve Opp', dataIndex: 'negative_sum', ...numericColumnStyle  },
    //    { title: 'Commission', dataIndex: 'commission', ...numericColumnStyle  },
    //    { title: 'Profit Opp Count', dataIndex: 'profit_trade_count', ...integerColumnStyle },
    //    { title: 'Total Opp Count', dataIndex: 'total_trade_count', ...integerColumnStyle },
    { title: 'Date', dataIndex: 'completed_at' },
    { title: 'PNL (inaccurate commissions)', dataIndex: 'pnl', ...numericColumnStyle },
  ]

  return (
    <Panel title='PNL Currency Daily'>
      <SubscriptionTable
        map={(rows) => 
          Object
            .entries(_.groupBy(rows, 'completed_at'))
            .map(([key, records]) => {
              const hasExchangeRate = _.filter(records, x => x.exchange_rate).length === records.length
              const pnl = (
                hasExchangeRate ? _.sum(records.map(x => x.pnl / x.exchange_rate.rate)) : ''
              )
              return ({ completed_at: key, pnl, records })
            })
        }
        columns={columns}
        subscription={subscription}
        rowKey='completed_at'
        size='small'
        expandable={{
          expandedRowRender,
          expandRowByClick: true,
          indentSize: 5,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <MinusOutlined onClick={e => onExpand(e, record)} />
            ) : (
              <PlusOutlined onClick={e => onExpand(e, record)} />
            )
        }}
      />
    </Panel>
  )
}


