import React, {useState} from 'react'
import { Col } from 'antd'
import { useSubscription } from '@apollo/react-hooks'
import moment from 'moment'
import gql from 'graphql-tag'

import SubscriptionTable from 'components/SubscriptionTable'
import {
  PnlCurrencyDailyTable,
  PnlInstrumentDailyTable,
  PnlInstrumentMonthlyTable,
  PnlStreamTable,
} from './PnlTable.js'


const SLIP_DAILY = gql`
  subscription {
    slip_daily(
      order_by: { received_at: desc  }
    ) {
      instrument {
        id
        name
      }
      stream {
        id
        account_name
      }
      low
      high
      total
      median
      received_at
    }
  }
`
const TRADER_QUERY = gql`
  subscription ($start: timestamp!, $end: timestamp!) {
    opportunity(
      order_by: {completed_at: desc}, 
      where: {
        instrument_id: { _eq: 4 }
        operation: {
          type: { _in: ["trade", "resolve"] }
        },
        _and: [
          {net:          {_is_null: false}},
          {operation_id: {_is_null: false}},
          {completed_at: {_gte: $start}}, 
          {completed_at: {_lte: $end}}
        ]
      }
    ) {
      id
      lot
      net
    }
  }
`

function transformData(data) {
  let revenue = 0
  let commission = 0 

  data.forEach(x => {
    revenue += x.net * x.lot
    commission += (x.lot / 100) * (0.8 + 1.5)
  })

  return ({
    commission,
    revenue,
    total: revenue - commission,
    count: data.length,
  })
}

const slipColumns = [
  { title: 'Instrument', dataIndex: ['instrument', 'name'], key: 'instrument' },
  { title: 'Stream', dataIndex: ['stream', 'account_name'], key: 'stream' },
  { title: 'Low', dataIndex: 'low' },
  { title: 'Median', dataIndex: 'median' },
  { title: 'High', dataIndex: 'high' },
  { title: 'Total', dataIndex: 'total' },
  { title: 'Date', dataIndex: 'received_at' },
]

function Trader() {
  const [date, setDate] = useState({
    start: moment.utc(moment().startOf('day')).format("YYYY-MM-DDTHH:mm:ss"),
    end: moment.utc(moment().endOf('day')).format("YYYY-MM-DDTHH:mm:ss")
  })

  const { loading, error, data = { opportunity: [] } } = useSubscription(
    TRADER_QUERY,  { variables: date }
  )
  const slipSubscription = useSubscription(SLIP_DAILY)

  if (!data) {
    return null
  }


  const aggregate = transformData(data.opportunity)
  
  return (
    <>
      <Col span={24} style={{ padding: 10 }}>
        <PnlCurrencyDailyTable />
      </Col>
      <Col span={24} style={{ padding: 10 }}>
        <PnlInstrumentDailyTable />
      </Col>
      <Col span={24} style={{ padding: 10 }}>
        <PnlInstrumentMonthlyTable />
      </Col>
      <Col span={24} style={{ padding: 10 }}>
        <PnlStreamTable />
      </Col>
      <Col span={24} style={{ padding: 10 }}>
        <SubscriptionTable
          columns={slipColumns}
          subscription={slipSubscription}
        />
      </Col>
    </>
  )
}


export default Trader 

