import React from 'react'

import { Empty, Table as AntTable } from 'antd'

export function Table({
  map = ((x) => x),
  error,
  dataSource,
  dataSourceIndex,
  ...restProps
}) {
  return (
    <AntTable
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={error ? 'Error Fetching Data' : 'No Data' }
          />
        )
      }}
      dataSource={
        map(!error && dataSource && (dataSourceIndex ? dataSource[dataSourceIndex] : dataSource))
      }
      {...restProps}
    />
  )
}

export default Table

