import React, { useState } from 'react'
import { Statistic, Checkbox, Popover, Button, DatePicker, Tag, Tooltip, Divider } from 'antd'
import { ArrowUpOutlined, ArrowDownOutlined, ExportOutlined, PlusOutlined, MinusOutlined, ExclamationCircleOutlined, ArrowRightOutlined} from '@ant-design/icons'
import { useSubscription, useApolloClient } from '@apollo/react-hooks'
import moment from 'moment'
import gql from 'graphql-tag'

import Table from 'components/Table'
import { tradingSessionAt } from 'utils/misc'
import { exportJsonToCsv } from 'utils/csv'
import './index.css'

const OPPORTUNITY_TOTAL_SUBSCRIPTION = gql`
subscription (
  $start: numeric!,
  $end: numeric!,
  $type: [operation_type!],
  $status: [String!],
  $stream: [String!],
  $troupe: [String!],
  $instrument: [String!]
) {
  opportunity_report_aggregate(
    where: {
      _and: [
        {operation_id: {_is_null: false}},
        {completed_at: {_gte: $start}}, 
        {completed_at: {_lte: $end}},
        {operation : {troupe: {name: {_in: $troupe}}}},
        {operation: {type : {_in: $type }}},
        {_or: [
          {ask: {stream_instrument: {stream: {account_name: {_in: $stream}}}}}, 
          {bid: {stream_instrument: {stream: {account_name: {_in: $stream}}}}},
          {ask: {stream_instrument: {stream: {connector:{name: {_in: $stream}}}}}}, 
          {bid: {stream_instrument: {stream: {connector:{name: {_in: $stream}}}}}}, 
        ]},
        {_or: [
          {ask: {order_status: {_in: $status}}},
          {bid: {order_status: {_in: $status}}}
        ]}
      ]     
    }
  ){
    aggregate {
      count
    }
  } 
} 
`

const OPPORTUNITY_QUERY = gql`
query (
  $start: numeric!,
  $end: numeric!,
  $offset: Int!,
  $limit: Int!,
  $type: [operation_type!],
  $status: [String!],
  $stream: [String!],
  $troupe: [String!],
  $instrument: [String!],
  $sort: [opportunity_report_order_by!]
) {
  opportunity_report(
    order_by: $sort,
    offset: $offset,
    limit: $limit,
    where: {_and: [
      {operation_id: {_is_null: false}},
      {completed_at: {_gte: $start}},
      {completed_at: {_lte: $end}},
      {operation: {troupe: {name: {_in: $troupe}}}},
      {operation: {type: {_in: $type}}},
      {_or: [
        {ask: {stream_instrument: {stream: {account_name: {_in: $stream}}}}},
        {bid: {stream_instrument: {stream: {account_name: {_in: $stream}}}}},
        {ask: {stream_instrument: {stream: {connector: {name: {_in: $stream}}}}}},
        {bid: {stream_instrument: {stream: {connector: {name: {_in: $stream}}}}}}
      ]},
      {_or: [
        {ask: {order_status: {_in: $status}}},
        {bid: {order_status: {_in: $status}}}
      ]}
    ]}
  ) {
    id
    serial
    operation {
      id
      type
      config {
        id
        data
      }
      troupe {
        id
        name
      }
    }
    ask {
      id: client_order_id
      client_order_id
      key: order_serial
      order_serial
      stream_instrument {
        id
        name
        stream {
          account_name
          connector {
            id
            name
          }
        }
        instrument {
          decimal_pip
        }
      }
      side
      requested_price
      requested_size
      fill_price
      fill_size
      received_at
      order_status
      received_at
      new_at
      slip
      execution_time
      report_group
    }
    bid {
      id: client_order_id
      client_order_id
      key: order_serial
      order_serial
      stream_instrument {
        id
        name
        stream {
          account_name
          connector {
            id
            name
          }
        }
        instrument {
          decimal_pip
        }
      }
      side
      requested_price
      requested_size
      fill_price
      fill_size
      received_at
      order_status
      received_at
      new_at
      slip
      execution_time
      report_group
    }
    fill_size
    spread
    net
    completed_at
  }
}
`

const OPPORTUNITY_SUBSCRIPTION = gql`
subscription (
  $start: numeric!,
  $end: numeric!,
  $offset: Int!,
  $limit: Int!,
  $type: [operation_type!],
  $status: [String!],
  $stream: [String!],
  $troupe: [String!],
  $sort: [opportunity_report_order_by!]
) {
  opportunity_report(
    order_by: $sort,
    offset: $offset,
    limit: $limit,
    where: {_and: [
      {operation_id: {_is_null: false}},
      {completed_at: {_gte: $start}},
      {completed_at: {_lte: $end}},
      {operation: {troupe: {name: {_in: $troupe}}}},
      {operation: {type: {_in: $type}}},
      {_or: [
        {ask: {stream_instrument: {stream: {account_name: {_in: $stream}}}}},
        {bid: {stream_instrument: {stream: {account_name: {_in: $stream}}}}},
        {ask: {stream_instrument: {stream: {connector: {name: {_in: $stream}}}}}},
        {bid: {stream_instrument: {stream: {connector: {name: {_in: $stream}}}}}}
      ]},
      {_or: [
        {ask: {order_status: {_in: $status}}},
        {bid: {order_status: {_in: $status}}}
      ]}
    ]}
  ) {
    id
    serial
    operation {
      id
      type
      config {
        id
        data
      }
      troupe {
        id
        name
      }
    }
    ask {
      id: client_order_id
      client_order_id
      key: order_serial
      order_serial
      stream_instrument {
        id
        name
        stream {
          account_name
          connector {
            id
            name
          }
        }
        instrument {
          decimal_pip
        }
      }
      side
      requested_price
      requested_size
      fill_price
      fill_size
      received_at
      order_status
      received_at
      new_at
      slip
      execution_time
      report_group
      vix
    }
    bid {
      id: client_order_id
      client_order_id
      key: order_serial
      order_serial
      stream_instrument {
        id
        name
        stream {
          account_name
          connector {
            id
            name
          }
        }
        instrument {
          decimal_pip
        }
      }
      side
      requested_price
      requested_size
      fill_price
      fill_size
      received_at
      order_status
      received_at
      new_at
      slip
      execution_time
      report_group
      vix
    }
    fill_size
    spread
    net
    completed_at
  }
}
`

const TRADE_GROUPS = gql`
subscription {
  troupe (where: {is_visible: {_eq: true}}, order_by: {name: asc}) {
    id
    name
  }
}
`

const STREAMS = gql`
  subscription {
    stream(where:{is_visible: {_eq: true}}) {
      account_name
      connector {
        name
      }
    }
  }
`

const fix_status_map = {
  0 : <Tag color='processing' >New</Tag>,
  1 : <Tag color='lime' >Partial fill</Tag>,
  2 : <Tag color='success' >Filled</Tag>,
  3 : <Tag color='success' >Done for day</Tag>,
  4 : <Tag color='warning' >Canceled</Tag>,
  5 : <Tag color='warning' >Replaced</Tag>,
  6 : <Tag color='warning' >Pending Cancel</Tag>,
  7 : <Tag color='error' >Stopped</Tag>,
  8 : <Tag color='error' >Rejected</Tag>,
  9 : <Tag color='warning' >Suspended</Tag>,
  A : <Tag color='warning' >Pending New</Tag>,
  B : <Tag color='warning' >Calculated</Tag>,
  C : <Tag color='error' >Expired</Tag>,
  F : <Tag color='lime' >Trade (partial fill or fill)</Tag>,
}

const fix_status_map_bold = {
  0 : <Tag color='processing' >N</Tag>,
  1 : <Tag color='#2db7f5' >P</Tag>,
  2 : <Tag color='#26a69a' >F</Tag>,
  3 : <><Tag color='#FF0000' >R</Tag><ArrowRightOutlined/><Tag color='#26a69a' >F</Tag></>,
  4 : <Tag color='#f6bd16' >C</Tag>,
  5 : <Tag color='warning' >Replaced</Tag>,
  6 : <Tag color='warning' >Pending Cancel</Tag>,
  7 : <Tag color='error' >Stopped</Tag>,
  8 : <Tag color='#FF0000' >R</Tag>,
  9 : <><Tag color='#26a69a' >F</Tag><ArrowRightOutlined/><Tag color='#CC5500' >C</Tag></>,
  A : <Tag color='warning' >Pending New</Tag>,
  B : <Tag color='warning' >Calculated</Tag>,
  C : <Tag color='error' >E</Tag>,
  F : <Tag color='lime' >Trade (partial fill or fill)</Tag>,
}


class DateFilter extends React.PureComponent {
  handleChange = value => {
    value ? 
      this.props.setSelectedKeys([value])
      :   
      this.props.setSelectedKeys([
      ])


    this.props.confirm()
    

  }

  render() {
    return (
      <div style={{padding: 8}}> 
        <DatePicker.RangePicker
          format="YYYY-MM-DD"
          placeholder={["Start Date", "End Date"]}
          onChange={this.handleChange}
          onOk={this.search}
          allowClear={true}
        />
      </div>
    )
  }
}

function RuleInfo({ type, data  }) {
  const rule = data[type + '_rule']
  const strategyInfo = (
    { 'standard': (<>
      <div><b>Use Limit Order &nbsp;</b> <Checkbox checked={rule.use_limit_order} /></div>
    </>),
    'lock': (<>
      <div><b>Lock Type       &nbsp;</b> {rule.lock_type}</div>
      {{ 'time-delay': <>
        <div><b>Delay       &nbsp;</b> {rule.delay}</div>
      </>,
      'price-trigger': <>
        <div><b>Take Profit &nbsp;</b> {rule.take_profit}</div>
        <div><b>Stop Loss   &nbsp;</b> {rule.stop_loss}</div>
      </>,
      'trailing-stop': <>
        <div><b>Trailing Trigger &nbsp;</b> {rule.trailing_trigger}</div>
        <div><b>Trailing Step &nbsp;</b> {rule.trailing_step}</div>
        <div><b>Stop Loss   &nbsp;</b> {rule.stop_loss}</div>
      </>,
      }[rule.lock_type] || null}
    </>),
    }[rule.strategy_type] || null
  )
  return { 'trade': (
    <>
      <div><b>Strategy              &nbsp;</b> {rule.strategy_type}</div>
      {rule.trade_mode !== 'martingale' && (<div><b>Spread &nbsp;</b> {rule.spread}</div>)}
      <div><b>Execution Count Limit &nbsp;</b> {rule.execution_count_limit}</div>
      <div><b>Trade Mode            &nbsp;</b> {rule.trade_mode}</div>
      {rule.trade_mode !== 'martingale' && (<div><b>Volume &nbsp;</b> {rule.spot.toLocaleString()}</div>)}
      {rule.trade_mode === 'dynamic' && (<>
        <div><b>Volume Step         &nbsp;</b> {rule.spot_step.toLocaleString()}</div>
        <div><b>Volume Max          &nbsp;</b> {rule.spot_max.toLocaleString()}</div>
      </>)}
      {rule.trade_mode === 'martingale' && (<>
        <div><b>Volume Base         &nbsp;</b> {rule.spot_step.toLocaleString()}</div>
        <div><b>Volume Step         &nbsp;</b> {rule.spot_step.toLocaleString()}</div>
        <div><b>Volume Max          &nbsp;</b> {rule.spot_max.toLocaleString()}</div>
        <div><b>Spread Base         &nbsp;</b> {rule.spread_base}</div>
        <div><b>Spread Step         &nbsp;</b> {rule.spread_step}</div>
      </>)}
      {strategyInfo}
    </>
  ),
  'resolve': (
    <>
      <div><b>Strategy       &nbsp;</b> {rule.strategy_type}</div>
      <div><b>Allow Unhedged &nbsp;</b> <Checkbox checked={rule.allow_unhedged} /></div>
      {rule.trade_mode !== 'martingale' && (<div><b>Spread &nbsp;</b> {rule.spread}</div>)}
      <div><b>Execution Count Limit &nbsp;</b> {rule.execution_count_limit}</div>
      <div><b>Trade Mode            &nbsp;</b> {rule.trade_mode}</div>
      {rule.trade_mode !== 'martingale' && (<div><b>Volume &nbsp;</b> {rule.spot.toLocaleString()}</div>)}
      {rule.trade_mode === 'dynamic' && (<>
        <div><b>Volume Step         &nbsp;</b> {rule.spot_step.toLocaleString()}</div>
        <div><b>Volume Max          &nbsp;</b> {rule.spot_max.toLocaleString()}</div>
      </>)}
      {rule.trade_mode === 'martingale' && (<>
        <div><b>Volume Base         &nbsp;</b> {rule.spot_step.toLocaleString()}</div>
        <div><b>Volume Step         &nbsp;</b> {rule.spot_step.toLocaleString()}</div>
        <div><b>Volume Max          &nbsp;</b> {rule.spot_max.toLocaleString()}</div>
        <div><b>Spread Base         &nbsp;</b> {rule.spread_base}</div>
        <div><b>Spread Step         &nbsp;</b> {rule.spread_step}</div>
      </>)}
      {strategyInfo}
    </>
  ),
  }[type] || null
}

// TODO FIXME
function ConfigPopover({ children, record: { operation: { type, config }} }) {

  return (
    <Popover
      title={type}
      placement='leftTop'
      content={
        <div style={{ width: 250 }}>
          <RuleInfo type={type} data={config.data} />
        </div>
      }
    >
      {children}
    </Popover>
  )
}

function NestedTable(error, loading, data, date, setDate, page, setPage, count, setFilters, filters, setSorter, sorter, apolloClient) {
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Side',
        key: 'side',
        dataIndex: 'side',
        render: (side) => side === '1' ? <Tag color='#2db7f5'>Buy</Tag> : <Tag color='#f50'>Sell</Tag>,
      },{
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      }, {
        title: 'Stream',
        key: 'stream_instrument',
        dataIndex: 'stream_instrument',
        render: (stream_instrument) => stream_instrument ? stream_instrument.stream ? stream_instrument.stream.account_name || stream_instrument.stream.connector.name : null : null
      }, {
        title: 'Symbol',
        key: 'symbol',
        dataIndex: 'stream_instrument',
        render: (stream_instrument) => stream_instrument.name,
      }, {
        title: 'Order Status',
        key: 'order_status',
        dataIndex: 'order_status',
        render: (stat, record) => fix_status_map[record.fill_price && stat === '4' ? '1' : stat],
      }, {
        title: 'Requested Price',
        key: 'requested_price',
        dataIndex: 'requested_price',
        render: (price, record) => price ? price.toFixed(record.stream_instrument.instrument.decimal_pip) : price,
      }, {
        title: 'Fill Price',
        key: 'fill_price',
        dataIndex: 'fill_price',
        render: (price, record) => price ? price.toFixed(record.stream_instrument.instrument.decimal_pip) : price,
      }, {
        title: 'Requested Size',
        key: 'requested_size',
        dataIndex: 'requested_size',
        render: x => x ? x.toLocaleString() : '' 
      }, {
        title: 'Fill Size',
        key: 'fill_size',
        dataIndex: 'fill_size',
        render: x => x ? x.toLocaleString() : '',
      }, {
        title: 'Slip',
        key: 'slip',
        dataIndex: 'slip',
        render: (price, record) => price ? price.toFixed(record.stream_instrument.instrument.decimal_pip) : price,
      }, {
        title: 'Execution Time',
        key: 'execution_time',
        dataIndex: 'execution_time',
      }, {
        title: 'VIX',
        key: 'vix',
        dataIndex: 'vix',
      }, {
        title: 'Time Stamp',
        key: 'received_at',
        dataIndex: 'received_at',
        render: (received_at) => new Date(received_at/1000000).toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          fractionalSecondDigits: 3,
          hour12: true,
        }),
      },
    ]
    const expandedRowRender = (record) => {
      record.report_group.sort((a, b) => b.id - a.id)
      const columns = [
        {
          title: 'Order Status',
          key: 'order_status',
          dataIndex: 'order_status',
          render: (stat) => fix_status_map[stat],
        }, {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
        }, {
          title: 'Side',
          key: 'side',
          dataIndex: 'side',
          render: (side) => side === '1' ? 'Buy' : 'Sell',
        }, {
          title: 'Requested Price',
          key: 'requested_price',
          dataIndex: 'requested_price',
          render: (price) => price ? price.toFixed(record.stream_instrument.instrument.decimal_pip) : price,
        }, {
          title: 'Fill Price',
          key: 'fill_price',
          dataIndex: 'fill_price',
          render: (price) => price ? price.toFixed(record.stream_instrument.instrument.decimal_pip) : price,
        }, {
          title: 'Last Price',
          key: 'last_price',
          dataIndex: 'last_price',
          render: (price) => price ? price.toFixed(record.stream_instrument.instrument.decimal_pip) : price,
        }, {
          title: 'Requested Size',
          key: 'requested_size',
          dataIndex: 'requested_size',
          render: x => x ? x.toLocaleString() : '',
        }, {
          title: 'Last Size',
          key: 'last_size',
          dataIndex: 'last_size',
          render: x => x ? x.toLocaleString() : '',
        }, {
          title: 'Slip',
          key: 'slip',
          dataIndex: 'slip',
          render: (price) => price ? price.toFixed(record.stream_instrument.instrument.decimal_pip) : price,
        }, {
          title: 'Message',
          key: 'message',
          dataIndex: 'message',
        }, {
          title: 'Execution Time',
          key: 'execution_time',
          dataIndex: 'received_at',
          render: (received_at) => Math.round((received_at - record.new_at) / 1000000, 0)
        }, {
          title: 'Time Stamp',
          key: 'received_at',
          dataIndex: 'received_at',
          render: (received_at) => new Date(received_at/1000000).toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            fractionalSecondDigits: 3,
            hour12: true,
          }),
        },

      ]
      return <Table rowKey='id' size='small' columns={columns} dataSource={record.report_group} pagination={false} />
    }
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          {record.order_status === 'unhedged' && (
            <Tag color='red' style={{ fontSize: '20px' }} >Unhedged Order</Tag>
          )}
        </div>
        <Table
          size='small'
          columns={columns}
          dataSource={record.ask && record.bid ? [record.ask, record.bid] : record.ask ? [record.ask] : [record.bid]}
          pagination={false}
          style={{ padding: '20px' }}
          expandable={{ 
            expandedRowRender, 
            indentSize: 25,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <MinusOutlined onClick={e => onExpand(record, e)} />
              ) : (
                <PlusOutlined onClick={e => onExpand(record, e)} />
              )
          }}
        />
      </>
    )
  }


  const { data: troupes =  { troupe: [] }} = useSubscription(TRADE_GROUPS)
  const troupeFilter = troupes.troupe.map( troupe => (
    { text: troupe.name, value: troupe.name}
  )) 

  const getOppType = (type, record) => {
    const data = record.operation.config.data
    return type === 'trade'
      ?
      <>{data.trade_rule.strategy_type === 'lock'
        ?
        <Tag
          color={
            data.trade_rule.lock_type === 'price-trigger' ? 'geekblue'
              : data.trade_rule.lock_type === 'time-delay' ? 'magenta'
                : data.trade_rule.lock_type === 'immediate' ? 'green'
                  : data.trade_rule.lock_type === 'trailing-stop' ? 'purple'
                    : data.trade_rule.lock_type === 'reverse-martingale' ? 'volcano' : 'blue'
          }>
          {data.trade_rule.lock_type}
        </Tag>
        :
        <Tag
          color={
            data.trade_rule.stream_mode === 'standard' ? ''
              : data.trade_rule.stream_mode === 'master-slave' ? 'red' : 'blue'
          }>
          {data.trade_rule.stream_mode}
        </Tag>
      } <Tag 
        color={
          data.trade_rule.trade_mode === 'static' ? 'lime'
            : data.trade_rule.trade_mode === 'dynamic' ? 'cyan'
              : data.trade_rule.trade_mode === 'martingale' ? 'magenta' : ''
        }>{data.trade_rule.trade_mode}</Tag></>
      :
      <>{data.resolve_rule.strategy_type === 'lock'
        ?
        <Tag
          color={
            data.resolve_rule.lock_type === 'price-trigger' ? 'geekblue'
              : data.resolve_rule.lock_type === 'time-delay' ? 'magenta'
                : data.resolve_rule.lock_type === 'immediate' ? 'green'
                  : data.resolve_rule.lock_type === 'trailing-stop' ? 'purple' : 'blue'
          }>
          {data.resolve_rule.lock_type}
        </Tag>
        :
        <Tag
          color={
            data.resolve_rule.strategy_type   === 'standard' ? ''
              : data.resolve_rule.strategy_type === 'master-slave' ? 'red' : 'blue'
          }>
          {data.resolve_rule.strategy_type}
        </Tag>
      } <Tag 
        color={
          data.resolve_rule.trade_mode === 'static' ? 'lime'
            : data.resolve_rule.trade_mode === 'dynamic' ? 'cyan'
              : data.resolve_rule.trade_mode === 'martingale' ? 'magenta' : ''
        }>
        {data.resolve_rule.trade_mode}
      </Tag>
      </>
  }

  const getStreams = (record) => {
    const bidStream = record.bid
      ?
      record.bid.stream_instrument.stream.account_name === ""
        ?
        record.bid.stream_instrument.stream.connector.name
        :
        record.bid.stream_instrument.stream.account_name
      : ''
    const askStream = record.ask
      ? 
      record.ask.stream_instrument.stream.account_name === ""
        ?
        record.ask.stream_instrument.stream.connector.name
        :
        record.ask.stream_instrument.stream.account_name
      : ''
    return askStream + "/" + bidStream
  }

  const isSameStream = (ask, bid) => {
    const bidStream = bid
      ?
      bid.stream_instrument.stream.account_name === ""
        ?
        bid.stream_instrument.stream.connector.name
        :
        bid.stream_instrument.stream.account_name
      : ''
    const askStream = ask
      ? 
      ask.stream_instrument.stream.account_name === ""
        ?
        ask.stream_instrument.stream.connector.name
        :
        ask.stream_instrument.stream.account_name
      : ''
    return askStream === bidStream
  }

  const { data: stream =  { stream: [] }} = useSubscription(STREAMS)
  const streamFilters = stream.stream.map( stream => {
    const name = stream.account_name === '' ? stream.connector.name : stream.account_name
    return {text: name, value: name}
  })

  function checkCleanUpAttempt(data) {
    const toCheck = data.reduce((accumulator, current) => {
      const groupKey = current.client_order_id
      if (!accumulator[groupKey]) {accumulator[groupKey] = []}
      accumulator[groupKey].push(current)
      return accumulator
    }, {})

    for (let clientOrderId of Object.keys(toCheck)) {
      const filteredData = toCheck[clientOrderId].filter(item => item.order_status === '2')
      const count = filteredData.length
      const firstSide = filteredData[0]?.side
      const side = filteredData.every(item => item.side === firstSide)
      if (count === 2 && !side) {
        return true
      }
    }
    return false
  }

  function checkOrderStatus(data) {
    let hasStatus8 = false
    let hasStatus1or2 = false
    for (let i = 0; i < data.length; i++) {
      const orderStatus = data[i].order_status
      if (orderStatus === '8') {
        hasStatus8 = true
      } else if (orderStatus === '1' || orderStatus === '2') {
        hasStatus1or2 = true
      }
      if (hasStatus8 && hasStatus1or2) {
        return true
      }
    }
    return false
  }

  const columns = [
    {
      title: 'Status',
      key: 'status',
      filters: [
        {
          text:  'Fill',
          value: '2',
        },
        {
          text:  'Canceled',
          value: '4',
        },
        {
          text:  'Rejected',
          value: '8',
        },
      ],
      align:'center',
      defaultFilteredValue: filters.status,
      render: (_, record) => (
        <>
          { 
            record.ask ? 
              checkCleanUpAttempt(record.ask.report_group) ? fix_status_map_bold[9]
                :
                checkOrderStatus(record.ask.report_group) ? fix_status_map_bold[3]
                  :
                  fix_status_map_bold[record.net && record.ask.order_status === '4' ? '1' : record.ask.order_status]
              : <Tag></Tag>
          }

          <Divider type='vertical' />
          {
            record.bid ?
              checkCleanUpAttempt(record.bid.report_group) ? fix_status_map_bold[9]
                :
                checkOrderStatus(record.bid.report_group) ? fix_status_map_bold[3]
                  :
                  fix_status_map_bold[record.net && record.bid.order_status === '4' ? '1' : record.bid.order_status]
              : <Tag></Tag>
          }
        </>
      ),
    }, { title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <ConfigPopover record={record}>
          {id}-
          {(id %
          Math.pow(10,record.operation.config.data[record.operation.type + '_rule'].execution_count_limit.toString().length))
          + 1
          }
          /{record.operation.config.data[record.operation.type + '_rule'].execution_count_limit}
        </ConfigPopover>
      ),

    },
    { title: 'Trade Group', 
      key: 'troupe',
      dataIndex: 'operation', 
      filterSearch: true,
      filters: troupeFilter,
      defaultFilteredValue: filters.troupe,
      render: (operation) => operation.troupe.name,
    },
    { title: 'Type', 
      dataIndex: 'operation',
      key: 'type',
      filters: [
        {
          text: 'Resolve',
          value: 'resolve',
        },
        {
          text: 'Trade',
          value: 'trade',
        },
      ],
      defaultFilteredValue: filters.type,
      render: (operation, record) => 
        <>
          <Tag color={operation.type === 'trade' ? 'blue' : 'gold'}>
            {operation.type}
          </Tag>
          {getOppType(operation.type, record)}
        </>
    },
    { title: 'Streams', 
      dataIndex: 'stream', 
      filterSearch: true,
      filters: streamFilters,
      defaultFilteredValue: filters.stream,
      render: (_, record) => getStreams(record), 
    },
    { title: 'Volume', dataIndex: 'fill_size', render: x => x ? x.toLocaleString() : '' },
    { title: 'Ask',
      dataIndex: 'ask',
      render: (ask, record) =>
        ask && ask.fill_price ?
          ask.fill_price.toFixed(record.ask.stream_instrument.instrument.decimal_pip)
          : null
    },
    { title: 'Bid',
      dataIndex: 'bid',
      render: (bid, record) =>
        bid && bid.fill_price ?
          bid.fill_price.toFixed(record.bid.stream_instrument.instrument.decimal_pip)
          : null 
    },
    { title: 'Spread', 
      dataIndex: 'spread', 
      render: (spread, record) => spread ? spread.toFixed(record.ask.stream_instrument.instrument.decimal_pip) : spread 
    },
    { title: 'Net', 
      dataIndex: 'net',
      key: 'net',
      sorter: true,
      render: (net, record) =>
        <Statistic
          value={net ? net.toFixed(record.ask.stream_instrument.instrument.decimal_pip) : net === null ? '-' : net} 
          valueStyle={ net > 0 ? {color: '#3f8600', fontSize:14} : net < 0 ? {color: '#cf1322', fontSize:14} : {color:'grey', fontSize:14}}
          prefix={ net === 0 || net === null ? <></> : net > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        />

    },
    { title: 'VIX', 
      dataIndex: ['ask', 'vix'], 
      key: 'opp-vix',
      render: (vix, record) => record.bid ? record.bid.vix : record.ask ? record.ask.vix : Math.max(record.bid.vix, record.ask.vix)
    },
    { title: 'Time', 
      dataIndex: 'completed_at', 
      filterDropdown: props => <DateFilter {...props} />,
      render: x => moment.utc(x/1000000).local().format('YYYY-MM-DD HH:mm:ss'),
    },
    { title: () => (
      <Button
        size='small'
        type='text'
        style={{ float: 'right' }}
        icon={<ExportOutlined />}
        onClick={
          async () => {
            const response = await apolloClient.query({
              query: OPPORTUNITY_QUERY,  
              variables: {...date, ...filters, ...sorter},
              fetchPolicy: 'network',
            })
            exportJsonToCsv(response.data && response.data.opportunity)
          }
        }
      />
    ), 
    dataIndex: '', 
    render: ({ completed_at })  => 
      <>
        {tradingSessionAt(moment.utc(completed_at/1000000).format('YYYY-MM-DD HH:mm:ss')).map(
          ({ color, name, flag }, i) =>
            <Tooltip
              key={i}
              placement="right"
              title={name + ' Session'}
              style={{ userSelect: 'none' }}
            >
              &nbsp;{flag}
            </Tooltip>
        )}
      </>,
    width: 10,
    },
  ]
  return (
    <Table
      error={error}
      loading={loading}
      dataSource={data.opportunity_report}
      rowKey='id'
      rowSelection={{}}
      pagination={{
        showSizeChanger: true,
        total: count ? count.opportunity_report_aggregate.aggregate.count :  page.limit,
        defaultCurrent: (page.offset + page.limit) / page.limit,
        defaultPageSize: page.limit,
        position: ['topRight', 'bottomRight'],
      }}
      rowClassName={
        ({ completed_at, bid, ask, net }) => {
          return (ask && checkCleanUpAttempt(ask.report_group)) || (bid && checkCleanUpAttempt(bid.report_group))
            ? 'retry-row'
            : (((bid ? bid.order_status === '4' : false) && ask) || ((ask ? ask.order_status === '4' : false) && bid)) && net === null
              ? 'rejected-row'
              : ((bid ? bid.order_status === '4' : false) || (ask ? ask.order_status === '4' : false)) && net !== null
                ? 'partial-row'
                : (bid ? bid.order_status === '4' : false) || (ask ? ask.order_status === '4' : false)
                  ? 'cancel-row'
                  : (bid ? bid.order_status === '8' : false) || (ask ? ask.order_status === '8' : false)
                    ? 'rejected-row'
                    : moment.utc(completed_at/1000000).diff(moment(), 's') > - 60
                      ? 'updated-row'
                      : null
        }
      }
      onChange={(pagination, filter, sorter) => {
        window.localStorage.setItem('opp-troupe-filter', filter.troupe ? filter.troupe.length !== 0 ? filter.troupe : window.localStorage.getItem('opp-troupe-filter') : null)
        window.localStorage.setItem('opp-type-filter', filter.type ? filter.type.length !== 0 ? filter.type: window.localStorage.getItem('opp-type-filter') : null)
        window.localStorage.setItem('opp-stream-filter', filter.stream ? filter.stream.length !== 0 ? filter.stream : window.localStorage.getItem('opp-stream-filter') : null)
        window.localStorage.setItem('opp-status-filter', filter.status ? filter.status.length !== 0 ? filter.status : window.localStorage.getItem('opp-status-filter') : null)
        const [from, to] = filter.completed_at && filter.completed_at[0] ? filter.completed_at[0] : [] 
        if (filter.completed_at && filter.completed_at[0]) {
          setDate(
            {
              start: moment.utc(from.startOf('day')).unix()*1000000000,
              end: moment.utc(to.endOf('day')).unix()*1000000000,
            }
          )
        }
        setPage(
          {
            offset: pagination.current * pagination.pageSize - pagination.pageSize,
            limit: pagination.pageSize   
          }
        )
        setFilters(
          {
            troupe: window.localStorage
              ? window.localStorage.getItem('opp-troupe-filter') && window.localStorage.getItem('opp-troupe-filter') != 'null'
                ? window.localStorage.getItem('opp-troupe-filter').split(',')
                : null 
              : null,
            type: window.localStorage
              ? window.localStorage.getItem('opp-type-filter') && window.localStorage.getItem('opp-type-filter') != 'null'
                ? window.localStorage.getItem('opp-type-filter').split(',')
                : null 
              : null,
            stream: window.localStorage
              ? window.localStorage.getItem('opp-stream-filter') && window.localStorage.getItem('opp-stream-filter') != 'null'
                ? window.localStorage.getItem('opp-stream-filter').split(',')
                : null 
              : null,
            status: window.localStorage
              ? window.localStorage.getItem('opp-status-filter') && window.localStorage.getItem('opp-status-filter') != 'null'
                ? window.localStorage.getItem('opp-status-filter').split(',')
                : null 
              : null,
          }
        )
        sorter.order
          ?
          setSorter(
            {
              sort: {
                [sorter.field]: sorter.order === "ascend" ? "asc_nulls_last" : "desc_nulls_last",
              }
            }
          )
          :
          setSorter(
            {
              sort: {
                completed_at: 'desc'
              }
            }
          )
      }}
      size='small'
      columns={columns}
      expandable={{ 
        expandedRowRender, 
        indentSize: 5,
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <MinusOutlined onClick={e => onExpand(record, e)} />
          ) : (
            <PlusOutlined onClick={e => onExpand(record, e)} />
          )
      }}
    />
  )
}

function Trader() {
  const [page, setPage] = useState({
    offset: 0,
    limit: 100000
  })
  const [date, setDate] = useState(
    {
      start: moment.utc(moment().startOf('day')).unix()*1000000000,
      end: moment.utc(moment().endOf('day')).unix()*1000000000
    }
  )
  const [filters, setFilters] = useState({
    troupe: window.localStorage
      ? window.localStorage.getItem('opp-troupe-filter') && window.localStorage.getItem('opp-troupe-filter') != 'null'
        ? window.localStorage.getItem('opp-troupe-filter').split(',')
        : null 
      : null,
    type: window.localStorage
      ? window.localStorage.getItem('opp-type-filter') && window.localStorage.getItem('opp-type-filter') != 'null'
        ? window.localStorage.getItem('opp-type-filter').split(',')
        : null 
      : null,
    stream: window.localStorage
      ? window.localStorage.getItem('opp-stream-filter') && window.localStorage.getItem('opp-stream-filter') != 'null'
        ? window.localStorage.getItem('opp-stream-filter').split(',')
        : null 
      : null,
    status: window.localStorage
      ? window.localStorage.getItem('opp-status-filter') && window.localStorage.getItem('opp-status-filter') != 'null'
        ? window.localStorage.getItem('opp-status-filter').split(',')
        : null 
      : null,
  })
  const [sorter, setSorter] = useState({
    sort: {completed_at: 'desc'}
  })
  const { loading: loadingCount, data: count  } = useSubscription(
    OPPORTUNITY_TOTAL_SUBSCRIPTION, 
    { 
      variables: {...date, ...filters}
    }
  )
  const { loading, error, data = { opportunity: [] } } = useSubscription(
    OPPORTUNITY_SUBSCRIPTION, 
    { 
      variables: {...date, ...page, ...filters, ...sorter}
    }
  )
  const apolloClient = useApolloClient()
  return NestedTable(error, loading, data, date, setDate, page, setPage, count, setFilters, filters, setSorter, sorter, apolloClient)
}


export default Trader 

