import React, { useMemo, useState, Component } from 'react'
import { Mutation } from 'react-apollo'
import { useSubscription } from '@apollo/react-hooks'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { Table, InputNumber, Form, Select, Checkbox, Input, Button, Col, Row } from 'antd'
import gql from 'graphql-tag'
import _ from 'lodash'

import SubscriptionTable from 'components/SubscriptionTable'

const SUBSCRIPTION_DIRECT_STREAMS = gql`
  subscription {
    stream(
      where: {
        is_visible: { _eq: true }
      }
      order_by: { created_at: asc }
    ) {
      id
      account_name
      connector {
        id
        name
      }
    }
  }
`
const SUBSCRIPTION_PRIMEXM_STREAMS = gql`
  subscription {
    stream(
      where:{
        connector_id:{_eq: 1},
        is_visible: {_eq: true} 
      }, 
      order_by: {created_at: asc}) {
      id
      name: account_name
    }
  }
`

const SUBSCRIPTION_PRIMEXM_AU_STREAMS = gql`
  subscription {
    stream(
      where:{
        connector_id:{_eq: 1},
        is_visible: {_eq: true} 
      }, 
      order_by: {created_at: asc}) {
      id
      name: account_name
    }
  }
`

const CREATE_STREAM = gql`
  mutation ($input: stream_insert_input!) {
    insert_stream(
      objects: [$input],
      on_conflict: {
        constraint: stream_account_name_connector_id_key,
        update_columns: [
          is_visible
        ]
      }
    ) {
      returning {
        id
      }
    }
  }
`

const DELETE_STREAM = gql`
  mutation($id: Int) {
    update_stream(where: {id: {_eq: $id}}, _set: {is_visible: false}) {
      returning {
        id
      }
    }
  }
`


function AddInput({ onSubmit, ...restProps }) {
  const [value, setValue] = useState('')
  return (
    <Input.Search
      {...restProps}
      value={value}
      enterButton={<PlusOutlined />}
      onChange={({ target: { value } }) => { setValue( value ) }}
      onSearch={value => {
        onSubmit && onSubmit(value)
        setValue('')
      }}
    />
  )
}

const directColumns = [{
  title: 'Streams',
  render: ({ account_name, connector: { name } }) => <span style={{color:'#1890ff'}}>{account_name ? `${name} (${account_name})` : name}</span>,
}]

function DirectStreamsTable({ props }) {
  const subscription = useSubscription(SUBSCRIPTION_DIRECT_STREAMS)
  return (
    <SubscriptionTable
      subscription={subscription}
      rowKey='account_name'
      pagination={false}
      size='small'
      showHeader={true}
      columns={directColumns}
      {...props}
    />
  )
}

const columns = [{
  title: 'PrimeXM Streams',
  dataIndex: 'name',
  render: text => <span style={{color:'#1890ff'}}>{text}</span>,
}, {
  align: 'right',
  render: ({ id }) => (
    <Mutation mutation={DELETE_STREAM}>
      {(deleteStream) => (
        <Button
          icon={<DeleteOutlined />}
          size='small'
          onClick={() => {
            deleteStream({
              variables: {
                id,
              }
            })
          }}
        />
      )}
    </Mutation>
  )
}]

const columnsAu = [{
  title: 'Metatrader Streams',
  dataIndex: 'name',
  render: text => <span style={{color:'#1890ff'}}>{text}</span>,
}, {
  align: 'right',
  render: ({ id }) => (
    <Mutation mutation={DELETE_STREAM}>
      {(deleteStream) => (
        <Button
          icon={<DeleteOutlined />}
          size='small'
          onClick={() => {
            deleteStream({
              variables: {
                id,
              }
            })
          }}
        />
      )}
    </Mutation>
  )
}]

function StreamsTable(props) {
  const subscription = useSubscription(SUBSCRIPTION_PRIMEXM_STREAMS)
  const subscriptionAu = useSubscription(SUBSCRIPTION_PRIMEXM_AU_STREAMS)
  return (
    <Row>
      <Col span={12} style={{ padding: 10 }}>
        <SubscriptionTable
          subscription={subscription}
          rowKey='name'
          pagination={false}
          size='small'
          showHeader={true}
          columns={columns}
          footer={() =>
            <Mutation mutation={CREATE_STREAM}>
              {(createStream) => (
                <AddInput
                  size='small'
                  onSubmit={name => {
                    createStream({
                      variables: {
                        input: {
                          account_name: name,
                          connector_id: 1,
                          is_visible: true,
                        }
                      }
                    })
                  }}
                />
              )}
            </Mutation>
          }
          {...props}
        />
      </Col>
      <Col span={12} style={{ padding: 10 }}>
        <SubscriptionTable
          subscription={subscriptionAu}
          rowKey='name'
          pagination={false}
          size='small'
          showHeader={true}
          columns={columnsAu}
          footer={() =>
            <Mutation mutation={CREATE_STREAM}>
              {(createStream) => (
                <AddInput
                  size='small'
                  onSubmit={name => {
                    createStream({
                      variables: {
                        input: {
                          account_name: name,
                          connector_id: 2,
                          is_visible: true,
                        }
                      }
                    })
                  }}
                />
              )}
            </Mutation>
          }
          {...props}
        />
      </Col>
    </Row>
  )
}




const StreamsRadio = ({ value={}, onChange, ...rest }) => {
  const subscription = useSubscription(SUBSCRIPTION_DIRECT_STREAMS)
  const keys = useMemo(() => Object.keys(value), [value])
  const handleChange = (value) => {
    const newValue = Object.fromEntries(
      value.map(stream_id => [stream_id, { stream_id: parseInt(stream_id) }])
    )
    if (onChange) {
      onChange(newValue)
    }
  }
  if (subscription.loading || subscription.error) {
    return null
  }
    
  return (
    <Checkbox.Group value={keys} onChange={handleChange}>
      {subscription.data.stream.map(({ id, account_name, connector: { name } }) => (
        <div key={id}>
          <Checkbox value={String(id)}>{account_name ? `${name} (${account_name})` : name}</Checkbox>
          <br />
        </div>
      ))}
    </Checkbox.Group>
  )
}

//class StreamsContainer extends Component {
//  render() {
//    return (
//        <Col>
//          <DirectStreamsTable/>
//          <br />
//          <StreamsTable/>
//        </Col>
//    )
//  }
//}


class StreamsContainer extends Component {
  render() {
    return (
      <Col>
        <DirectStreamsTable/>
        <br />
        <StreamsTable/>
      </Col>
    )
  }
}

const StreamsSelect = ({ value={}, onChange, ...rest }) => {
  const subscription = useSubscription(SUBSCRIPTION_DIRECT_STREAMS)
  if (subscription.loading || subscription.error) {
    return null
  }

  return (
    <div>
      {subscription.data.stream.map(({ id, account_name, connector: { name } }) => (
        <Form.Item
          key={id}
          label={account_name ? `${name} (${account_name})` : name}
          name={['trade_rule_streams', String(id), 'type']}
        >
          <Select size='small'>
            <Select.Option value=''></Select.Option>
            <Select.Option value='master'>Master</Select.Option>
            <Select.Option value='slave'>Slave</Select.Option>
          </Select>
        </Form.Item>
      ))}
    </div>
  )
}

const StreamsTabular = ({ prefix, value={}, onChange, ...rest }) => {
  const subscription = useSubscription(SUBSCRIPTION_DIRECT_STREAMS)
  const [currentValue, setCurrentValue] = useState(value)
  const [selectedRowKeys, result] = useMemo(() => {
    if (!subscription.data) {
      return [[], {}]
    }
    return [
      Object.keys(currentValue),
      Object.fromEntries(subscription.data.stream.map(
        stream => [String(stream.id), ({
          stream,
          ...(currentValue[String(stream.id)]),
        })]
      ))
    ]
  }, [subscription.data, currentValue])

  const setSelectedRowKeys = (keys) => {
    const newResult = Object.fromEntries(
      Object.values(result).filter(({ stream: { id } }) => keys.includes(String(id))).map(
        x => [String(x.stream.id), x]
      )
    )
    setCurrentValue(newResult)
    triggerChange(newResult)
  }

  const triggerChange = (result) => {
    if (onChange) {
      onChange(result)
    }
  }
 
  const setOrderType = (id, order_type) => {
    const newResult = Object.fromEntries(Object.entries(currentValue).map(
      ([stream_id, value]) => [
        stream_id,
        stream_id === id
          ? ({ ...value, order_type, ttl: (order_type === 'market' ? 0 : value.ttl ) })
          : value,
      ]
    ))
    setCurrentValue(newResult)
    triggerChange(newResult)
  }

  const setTTL = (id, ttl) => {
    const newResult = Object.fromEntries(Object.entries(currentValue).map(
      ([stream_id, value]) => [stream_id, (stream_id === id ? ({ ...value, ttl}) : value)]
    ))
    setCurrentValue(newResult)
    triggerChange(newResult)
  }

  if (subscription.loading || subscription.error) {
    return null
  }

  const setDepth = (id, depth) => {
    const newResult = Object.fromEntries(Object.entries(currentValue).map(
      ([stream_id, value]) => [stream_id, (stream_id === id ? ({ ...value, depth}) : value)]
    ))
    setCurrentValue(newResult)
    triggerChange(newResult)
  }

  if (subscription.loading || subscription.error) {
    return null
  }

  return (
    <div>
      <Table
        rowSelection={{ type: 'checkbox', selectedRowKeys, onChange: setSelectedRowKeys }}
        rowKey={({ stream: { id } }) => String(id)}
        size='small'
        columns={[
          {
            key: 'name',
            title: 'Name',
            render: ({ stream: { id, account_name, connector: { name } } }) => (
              account_name ? `${name} (${account_name})` : name
            ),
          },
          {
            key: 'order',
            title: 'Order',
            render: ({ order_type, stream: { id } }) => (
              <Form.Item
                name={
                  [prefix, String(id), 'order_type']
                }
                rules={[{
                  required: selectedRowKeys.includes(String(id)),
                  message: 'order required',
                }]}
              >
                <Select
                  disabled={!selectedRowKeys.includes(String(id))}
                  size='small'
                  onChange={
                    value => setOrderType(String(id), value)
                  }
                >
                  <Select.Option value='market'>market</Select.Option>
                  <Select.Option value='limit'>limit</Select.Option>
                </Select>
              </Form.Item>
            ),
          },
          {
            key: 'ttl',
            title: 'TTL',
            render: ({ ttl, stream: { id } }) => (
              <Form.Item
                fields={['order_type']}
                shouldUpdate={
                  (prevValues, currentValues) => (
                    _.get(prevValues, `${prefix}.id.order_type`) !== _.get(currentValues, `${prefix}.id.order_type`)
                  )}
                noStyle>
                {({ getFieldValue }) => {
                  const isLimitType = getFieldValue([prefix, String(id), 'order_type']) === 'limit'
                  return (
                    <Form.Item
                      name={
                        [prefix, String(id), 'ttl']
                      }
                      rules={[{
                        required: selectedRowKeys.includes(String(id)) && isLimitType,
                        message: 'ttl required',
                      }]}
                    >
                      <InputNumber
                        disabled={!selectedRowKeys.includes(String(id)) || !isLimitType}
                        size='small'
                        style={{ width: '80px'}}
                        min={0}
                        max={10000}
                        onChange={
                          value => setTTL(String(id), value)
                        }
                      />
                    </Form.Item>
                  )
                }}
              </Form.Item>
            ),
          },
          {
            key: 'depth',
            title: 'Depth',
            render: ({ order_type, stream: { id } }) => (
              <Form.Item
                name={
                  [prefix, String(id), 'depth']
                }
                rules={[{
                  required: selectedRowKeys.includes(String(id)),
                  message: 'Depth is required',
                }]}
              >
                <InputNumber
                  size='small'
                  style={{ width: '80px'}}
                  min={1}
                  max={1}
                  onChange={
                    value => setDepth(String(id), value)
                  }
                />
              </Form.Item>
            ),
          }
        ]}
        dataSource={Object.values(result)}
        pagination={false}
      />
    </div>
  )
}



export { 
  StreamsTabular,
  StreamsRadio, 
  StreamsSelect,
}

export default StreamsContainer
