import { json2csvAsync } from 'json-2-csv'

export async function exportJsonToCsv(rows) {
  //const header = "data:text/csv;charset=utf-8," 
  const body   = await json2csvAsync(rows)
  console.log(rows)
  console.log(body)
  //const encodedUri = encodeURI(header + body)
  //window.open(encodedUri)
  var link = document.createElement('a')
  link.href = 'data:text/csv,' + encodeURIComponent(body)
  link.download = 'export.csv'
  link.click()
}
