import React from 'react'
import {
  Select,
} from 'antd'

export function SubscriptionSelect({
  subscription: { loading, error, data },
  valueIndex,
  labelIndex,
  labelRender = function(x, y) { return y },
  ...restProps
}) {
  const dataSourceIndex = Object.keys(data || {})[0]
  const options = (data && dataSourceIndex && data[dataSourceIndex]) || []
  return (
    <Select loading={loading} {...restProps}>
      {options.map(element =>
        <Select.Option key={element[valueIndex]} value={element[valueIndex]}>
          {labelRender(element, labelIndex && element[labelIndex])}
        </Select.Option>)}
    </Select>
  )
}

export default SubscriptionSelect
