'use strict'
import React, { StrictMode, useCallback, useMemo, useRef, useState } from 'react'
import { useSubscription } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import moment from 'moment'
import { Statistic, Checkbox, Popover, Button, DatePicker, Tag, Tooltip, Divider, Col, Spin, Alert } from 'antd'
import { ArrowUpOutlined, ArrowDownOutlined, ExportOutlined, PlusOutlined, MinusOutlined, ExclamationCircleOutlined, ArrowRightOutlined} from '@ant-design/icons'

import 'ag-grid-enterprise'
import {AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { getTheme } from 'utils/theme'

const OPPORTUNITY_SUBSCRIPTION = gql`
subscription (
  $start: numeric!,
  $end: numeric!,
  $offset: Int!,
  $limit: Int!,
  $type: [operation_type!],
  $status: [String!],
  $stream: [String!],
  $troupe: [String!],
  $sort: [opportunity_report_order_by!]
) {
  opportunity_report(
    offset: $offset,
    limit: $limit,
    where: {_and: [
      {operation_id: {_is_null: false}},
      {completed_at: {_gte: $start}},
      {completed_at: {_lte: $end}},
      {operation: {troupe: {name: {_in: $troupe}}}},
      {operation: {type: {_in: $type}}},
      {bid: {vix: {_neq: null}}},
      {ask: {vix: {_neq: null}}},
      {spread: {_neq: null}},
      {net: {_neq: null}},
      {_or: [
        {ask: {stream_instrument: {stream: {account_name: {_in: $stream}}}}},
        {bid: {stream_instrument: {stream: {account_name: {_in: $stream}}}}},
        {ask: {stream_instrument: {stream: {connector: {name: {_in: $stream}}}}}},
        {bid: {stream_instrument: {stream: {connector: {name: {_in: $stream}}}}}}
      ]},
      {_or: [
        {ask: {order_status: {_in: $status}}},
        {bid: {order_status: {_in: $status}}}
      ]}
    ]}
  ) {
    id
    serial
    operation {
      id
      type
      config {
        id
        data
      }
      troupe {
        id
        name
      }
    }
    ask {
      id: client_order_id
      client_order_id
      key: order_serial
      order_serial
      stream_instrument {
        id
        name
        stream {
          account_name
          connector {
            id
            name
          }
        }
        instrument {
          decimal_pip
        }
      }
      side
      requested_price
      requested_size
      fill_price
      fill_size
      received_at
      order_status
      received_at
      new_at
      slip
      execution_time
      report_group
      vix
    }
    bid {
      id: client_order_id
      client_order_id
      key: order_serial
      order_serial
      stream_instrument {
        id
        name
        stream {
          account_name
          connector {
            id
            name
          }
        }
        instrument {
          decimal_pip
        }
      }
      side
      requested_price
      requested_size
      fill_price
      fill_size
      received_at
      order_status
      received_at
      new_at
      slip
      execution_time
      report_group
      vix
    }
    fill_size
    spread
    net
    completed_at
  }
}
`
const fix_status_map = {
  0 : <Tag color='processing' >New</Tag>,
  1 : <Tag color='lime' >Partial fill</Tag>,
  2 : <Tag color='success' >Filled</Tag>,
  3 : <Tag color='success' >Done for day</Tag>,
  4 : <Tag color='warning' >Canceled</Tag>,
  5 : <Tag color='warning' >Replaced</Tag>,
  6 : <Tag color='warning' >Pending Cancel</Tag>,
  7 : <Tag color='error' >Stopped</Tag>,
  8 : <Tag color='error' >Rejected</Tag>,
  9 : <Tag color='warning' >Suspended</Tag>,
  A : <Tag color='warning' >Pending New</Tag>,
  B : <Tag color='warning' >Calculated</Tag>,
  C : <Tag color='error' >Expired</Tag>,
  F : <Tag color='lime' >Trade (partial fill or fill)</Tag>,
}

const fix_status_map_bold = {
  0 : <Tag color='processing' >N</Tag>,
  1 : <Tag color='#2db7f5' >P</Tag>,
  2 : <Tag color='#26a69a' >F</Tag>,
  3 : <><Tag color='#FF0000' >R</Tag><ArrowRightOutlined/><Tag color='#26a69a' >F</Tag></>,
  4 : <Tag color='#f6bd16' >C</Tag>,
  5 : <Tag color='warning' >Replaced</Tag>,
  6 : <Tag color='warning' >Pending Cancel</Tag>,
  7 : <Tag color='error' >Stopped</Tag>,
  8 : <Tag color='#FF0000' >R</Tag>,
  9 : <Tag color='warning' >Suspended</Tag>,
  A : <Tag color='warning' >Pending New</Tag>,
  B : <Tag color='warning' >Calculated</Tag>,
  C : <Tag color='error' >E</Tag>,
  F : <Tag color='lime' >Trade (partial fill or fill)</Tag>,
}

const Statements = () => {
  const [page, setPage] = useState({
    offset: 0,
    limit: 10000
  })
  const [date, setDate] = useState(
    {
      start: moment.utc(moment().startOf('day')).unix()*1000000000,
      end: moment.utc(moment().endOf('day')).unix()*1000000000
    }
  )
  const { loading, error, data = { opportunity: [] } } = useSubscription(
    OPPORTUNITY_SUBSCRIPTION, 
    { 
      variables: {...date, ...page}
    }
  )
  const gridRef = useRef()
  const containerStyle = useMemo(() => ({ width: '100w', height: '85vh' }), [])
  const gridStyle = useMemo(() => ({ width: '100w%', height: '85vh' }), [])

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
    }
  }, [])
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 250,
    }
  }, []) 

  const onBtDefault = useCallback(() => {
    gridRef.current.api.closeToolPanel()
    gridRef.current.api.setGridOption('pivotMode', false)
    gridRef.current.api.applyColumnState({
      state: [
        { colId: 'troupe', rowGroup: false},
        { colId: 'streams', rowGroup: false},
      ],
      defaultState: {
        pivot: false,
        rowGroup: false,
      },
    })
  }, [])

  const onBtNormal = useCallback(() => {
    gridRef.current.api.setGridOption('pivotMode', false)
    gridRef.current.api.applyColumnState({
      state: [
        { colId: 'troupe', rowGroup: true },
        { colId: 'streams', rowGroup: true },
      ],
      defaultState: {
        pivot: false,
        rowGroup: false,
      },
    })
  }, [])

  const onBtPivotMode = useCallback(() => {
    gridRef.current.api.setGridOption('pivotMode', true)
    gridRef.current.api.applyColumnState({
      state: [
        { colId: 'troupe', rowGroup: true },
        { colId: 'stream', rowGroup: true },
      ],
      defaultState: {
        pivot: false,
        rowGroup: false,
      },
    })
  }, [])

  const onBtFullPivot = useCallback(() => {
    gridRef.current.api.setGridOption('pivotMode', true)
    gridRef.current.api.applyColumnState({
      state: [
        { colId: 'troupe', rowGroup: true },
        { colId: 'stream', pivot: true },
      ],
      defaultState: {
        pivot: false,
        rowGroup: false,
      },
    })
  }, [])

  function checkOrderStatus(data) {
    let hasStatus8 = false
    let hasStatus1or2 = false
    for (let i = 0; i < data.length; i++) {
      const orderStatus = data[i].order_status
      if (orderStatus === '8') {
        hasStatus8 = true
      } else if (orderStatus === '1' || orderStatus === '2') {
        hasStatus1or2 = true
      }
      if (hasStatus8 && hasStatus1or2) {
        return true
      }
    }
    return false
  }

  const getStreams = (record) => { 
    if(record === undefined) {
      return null
    } else  {
      const bidStream = record.bid
        ?
        record.bid.stream_instrument.stream.account_name === ""
          ?
          record.bid.stream_instrument.stream.connector.name
          :
          record.bid.stream_instrument.stream.account_name
        : ''
      const askStream = record.ask
        ? 
        record.ask.stream_instrument.stream.account_name === ""
          ?
          record.ask.stream_instrument.stream.connector.name
          :
          record.ask.stream_instrument.stream.account_name
        : ''
      return askStream + "/" + bidStream
    }
  }

  const weightedAvgAggFunc = (values) => {
    let sumProduct = 0
    let sumLots = 0
    values.values.forEach((value) => {
      if (value && value.fill_size && value.net) {
        sumProduct += value.net * value.fill_size
        sumLots += value.fill_size
      }
    })
    return sumLots ? {net: (sumProduct / sumLots), fill_size: sumLots} : null
  }

  const minMaxCompletedAtAggFunc = (values) => {
    let minDate = null
    let maxDate = null
    values.values.forEach(value => {
      const minValue = value.minDate
      const maxValue = value.maxDate
      if (minDate === null || minValue < minDate) {
        minDate = minValue 
      }
      if (maxDate === null || maxValue > maxDate) {
        maxDate = maxValue
      }
    })
    return { minDate: minDate, maxDate: maxDate }
  }

  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState([
    {
      headerName: 'Status',
      valueGetter: (params) => {
        return(params.data)
      },
      cellRenderer: ({ value }) => 
        value === undefined ?
          null
          :
          <>
            {
              value.ask ?
                checkOrderStatus(value.ask.report_group) ? fix_status_map_bold[3]
                  :
                  fix_status_map_bold[value.net && value.ask.order_status === '4' ? '1' : value.ask.order_status]
                : <Tag></Tag>
            }
            <Divider type='vertical' /> 
            {
              value.bid ?
                checkOrderStatus(value.bid.report_group) ? fix_status_map_bold[3]
                  :
                  fix_status_map_bold[value.net && value.bid.order_status === '4' ? '1' : value.bid.order_status]
                : <Tag></Tag>
            }
          </>
    },
    {
      headerName: 'Troupe',
      colId: 'troupe',
      field: 'operation.troupe.name',
      rowGroup: true,
      filter: true,
      enableRowGroup: true,
    },
    { 
      headerName: 'Rule',
      field: 'operation.type',
      rowGroup: true,
      enableRowGroup: true,
      filter: true,
      cellRenderer: ({value}) => 
        value
          ?
          <Tag color={value === 'trade' ? 'blue' : 'gold'}>
            {value}
          </Tag>
          : null
    },
    { 
      headerName: 'Strategy',
      rowGroup: true,
      enableRowGroup: true,
      filter: true,
      valueGetter: (params) => {
        if(params.data === undefined) {
          return null
        } else  {
          const data = params.data.operation.config.data
          return(params.data.operation.type === 'trade'
            ? data.trade_rule.strategy_type === 'lock'
              ? data.trade_rule.lock_type
              : data.trade_rule.strategy_type
            : data.resolve_rule.strategy_type === 'lock'
              ? data.resolve_rule.lock_type
              : data.resolve_rule.strategy_type

          )
        }
      },
      cellRenderer: ({value}) => {
        if(value === undefined) {
          return null
        } else {
          const val = value ? value.value ? value.value : value : null 
          return (
            val === null ? null
              :
              <Tag
                color={
                  val === 'price-trigger' ? 'geekblue'
                    : val === 'time-delay' ? 'magenta'
                      : val === 'immediate' ? 'green'
                        : val === 'trailing-stop' ? 'purple'
                          : val === 'standard' ? '' 
                            : val === 'master-slave' ? 'red' : 'blue'
                }>
                {val}
              </Tag>
          )
        }
      }
    },
    { 
      headerName: 'Trade Mode',
      rowGroup: true,
      enableRowGroup: true,
      filter: true,
      valueGetter: (params) => {
        if(params.data === undefined) {
          return null
        } else  {
          return(params.data.operation.type === 'trade'
            ? params.data.operation.config.data.trade_rule.trade_mode
            : params.data.operation.config.data.resolve_rule.trade_mode
          )
        }
      },
      cellRenderer: ({value, data}) => 
        value ?
          <Tag 
            color={
              value=== 'static' ? 'lime'
                : value === 'dynamic' ? 'cyan'
                  : value === 'martingale' ? 'magenta' : ''
            }
          >
            {value}
          </Tag>
          : null
    },
    {
      headerName: 'Streams',
      colId: 'stream',
      filter: true,
      valueGetter: (params) => {
        return getStreams(params.data)
      },
      rowGroup: true,
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: 'Lots',
      field: 'fill_size',
      colId: 'fill_size',
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Spread',
      field: 'spread',
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({value, data}) => {
        const decimalPlaces = data?.ask?.stream_instrument?.instrument?.decimal_pip || 5
        if(value === undefined) {
          return null
        }
        else {
          return value ? value.value ? value.value.toFixed(decimalPlaces) : value.toFixed(decimalPlaces) : null
        }
      }
    },
    {
      headerName: 'Net',
      field: 'net',
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value, data }) => {
        const decimalPlaces = data?.ask?.stream_instrument?.instrument?.decimal_pip || 5
        if(value === undefined || value === null) {
          return null
        }
        else {
          const displayValue = value.value ? value.value.toFixed(decimalPlaces) : value.toFixed(decimalPlaces)
          const valueStyle = displayValue > 0 ? { color: '#3f8600', fontSize: 14 }
            : displayValue < 0 ? { color: '#cf1322', fontSize: 14 }
              : { color: 'grey', fontSize: 14 }
          const prefix = displayValue === 0 || displayValue === null ? null
            : displayValue > 0 ? <ArrowUpOutlined />
              : <ArrowDownOutlined />
    
          return (
            <Statistic
              value={displayValue}
              valueStyle={valueStyle}
              prefix={prefix}
            />
          )
        }
      }
    },
    {
      headerName: 'Net (USD)',
      field: 'net',
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value, data }) => {
        const decimalPlaces = data?.ask?.stream_instrument?.instrument?.decimal_pip || 5
        const suffix = ''//data?.ask?.stream_instrument?.instrument?.name.split('/')[1] || 'USD'
        const lots = data?.ask?.fill_size || 1
        if(value === undefined || value === null) {
          return null
        }
        else {
          const displayValue = value.value ? value.value.toFixed(decimalPlaces) : value.toFixed(decimalPlaces)
          const valueStyle = displayValue > 0 ? { color: '#3f8600', fontSize: 14 }
            : displayValue < 0 ? { color: '#cf1322', fontSize: 14 }
              : { color: 'grey', fontSize: 14 }
          const prefix = displayValue === 0 || displayValue === null ? null
            : displayValue > 0 ? <ArrowUpOutlined />
              : <ArrowDownOutlined />
    
          return (
            <Statistic
              value={displayValue*lots}
              valueStyle={valueStyle}
              prefix={prefix}
              suffix={suffix}
            />
          )
        }
      }
    },
    {
      headerName: 'Weighted Net',
      valueGetter: (params) => {
        return( params && params.data ? {net: params.data.net, fill_size: params.data.fill_size} : null)
      },
      valueFormatter: (params) => {
        if (!params.value) {
          return ''
        }
        const decimalPlaces = params.data?.ask?.stream_instrument?.instrument?.decimal_pip || 5
        return params.value.net ? params.value.net.toFixed(decimalPlaces) : ''
      },
      aggFunc: weightedAvgAggFunc,
      enableValue: true,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        let netA = valueA ? valueA.net : null
        let netB = valueB ? valueB.net : null
        if (netA == null && netB == null) {
          return 0
        } else if (netA == null) {
          return -1
        } else if (netB == null) {
          return 1
        }
        return netA > netB ? 1 : (netA < netB ? -1 : 0)
      },
      cellRenderer: ({ value, data }) => {
        const decimalPlaces = data?.ask?.stream_instrument?.instrument?.decimal_pip || 5
        if(value === undefined || value === null) {
          return null
        }
        else {
          const displayValue = value.net ? value.net.toFixed(decimalPlaces) : value.net.toFixed(decimalPlaces)
          const valueStyle = displayValue > 0 ? { color: '#3f8600', fontSize: 14 }
            : displayValue < 0 ? { color: '#cf1322', fontSize: 14 }
              : { color: 'grey', fontSize: 14 }
          const prefix = displayValue === 0 || displayValue === null ? null
            : displayValue > 0 ? <ArrowUpOutlined />
              : <ArrowDownOutlined />
    
          return (
            <Statistic
              value={displayValue}
              valueStyle={valueStyle}
              prefix={prefix}
            />
          )
        }
      }
    },
    {
      headerName: 'Weighted Net (USD)',
      valueGetter: (params) => {
        return( params && params.data ? {net: params.data.net, fill_size: params.data.fill_size} : null)
      },
      aggFunc: weightedAvgAggFunc,
      enableValue: true,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        let netA = valueA ? valueA.net : null
        let netB = valueB ? valueB.net : null
        if (netA == null && netB == null) {
          return 0
        } else if (netA == null) {
          return -1
        } else if (netB == null) {
          return 1
        }
        return netA > netB ? 1 : (netA < netB ? -1 : 0)
      },
      cellRenderer: ({ value, data }) => {
        const decimalPlaces = data?.ask?.stream_instrument?.instrument?.decimal_pip || 5
        const suffix = ''//data?.ask?.stream_instrument?.instrument?.name.split('/')[1] || 'USD'
        const lots = data?.ask?.fill_size || 1
        if(value === undefined || value === null) {
          return null
        }
        else {
          const displayValue = value.net ? value.net.toFixed(decimalPlaces) : value.net.toFixed(decimalPlaces)
          const valueStyle = displayValue > 0 ? { color: '#3f8600', fontSize: 14 }
            : displayValue < 0 ? { color: '#cf1322', fontSize: 14 }
              : { color: 'grey', fontSize: 14 }
          const prefix = displayValue === 0 || displayValue === null ? null
            : displayValue > 0 ? <ArrowUpOutlined />
              : <ArrowDownOutlined />
    
          return (
            <Statistic
              value={displayValue*lots}
              valueStyle={valueStyle}
              prefix={prefix}
              suffix={suffix}
            />
          )
        }
      }
    },
    {
      headerName: 'Vix',
      aggFunc: 'avg',
      filter: 'agNumberColumnFilter',
      valueGetter: (params) => {
        return( params && params.data ?
          params.data.bid ? params.data.bid.vix : params.data.ask ? params.data.ask.vix : null
          : null)
      },
      cellRenderer: ({ value }) =>
        value === 'number' ? value.toFixed(1)
          : value && typeof value === 'object' && typeof value.value === 'number'
            ? value.value.toFixed(1)
            : 0
    },
    {
      headerName: 'Completed At',
      field: 'completed_at',
      aggFunc: minMaxCompletedAtAggFunc,
      valueGetter: (params) => {
        return( params && params.data ? {minDate: params.data.completed_at, maxDate: params.data.completed_at} : null)
      },
      cellRenderer: (params) => {
        if (params.node.group) {
          const minMax = params.value
          const minDate = minMax.minDate ? moment(minMax.minDate/1000000).format('MM-DD HH:mm') : 'N/A'
          const maxDate = minMax.maxDate ? moment(minMax.maxDate/1000000).format('MM-DD HH:mm') : 'N/A'
          return `${minDate} - ${maxDate}`
        } else {
          return params.value ? moment.utc(params.value.minDate/1000000).local().format('YYYY-MM-DD HH:mm:ss') : null
        }
      },
    },
  ]) 

  return (
    <div style={containerStyle}>
      <div className="example-wrapper">
        <div style={{ marginBottom: '8px', display: 'flex', flexWrap: 'wrap' }}>
          <Button onClick={onBtDefault}>0 - Default</Button>
          <Divider type='vertical' /> 
          <Button onClick={onBtNormal}>1 - Grouping Active</Button>
          <Divider type='vertical' /> 
          <Button onClick={onBtPivotMode}>2 - Grouping Active with Pivot Mode</Button>
          <Divider type='vertical' /> 
          <Button onClick={onBtFullPivot}>3 - Grouping Active with Pivot Mode and Pivot Active</Button>
          <DatePicker.RangePicker
            style={{marginLeft: 'auto'}}
            format="YYYY-MM-DD"
            placeholder={[
              moment.utc(date.start/1000000).local().format('YYYY-MM-DD HH:mm:ss'),
              moment.utc(date.end/1000000).local().format('YYYY-MM-DD HH:mm:ss'),
            ]}
            onChange={(selectedDates) => {
              const startDate = selectedDates[0] ? moment.utc(selectedDates[0].startOf('day')).unix() * 1000000000 : null
              const endDate = selectedDates[1] ? moment.utc(selectedDates[1].endOf('day')).unix() * 1000000000 : null
              setDate({ start: startDate, end: endDate })
            }}
            onCalendarChange={(selectedDates) => {
              const startDate = selectedDates[0] ? moment.utc(selectedDates[0].startOf('day')).unix() * 1000000000 : null
              const endDate = selectedDates[1] ? moment.utc(selectedDates[1].endOf('day')).unix() * 1000000000 : null
              setDate({ start: startDate, end: endDate })
            }}
            allowClear={true}
          />
        </div>
        <div
          style={gridStyle}
          className={
            getTheme() === 'light' ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'
          }
        >
          <AgGridReact
            rowData={data.opportunity_report}
            columnDefs={colDefs}
            ref={gridRef}
            autoGroupColumnDef={autoGroupColumnDef}
            sideBar={'columns'}
            onGridRead={!loading}
          />
        </div>
      </div>
    </div>
  )
}

export default Statements 
