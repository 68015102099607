import React, { useEffect, useMemo, useState, Component } from 'react'
import { Mutation } from 'react-apollo'
import { useSubscription } from '@apollo/react-hooks'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { Table, InputNumber, Form, Select, Checkbox, Input, Button, Col, Row } from 'antd'
import gql from 'graphql-tag'
import _ from 'lodash'

import SubscriptionTable from 'components/SubscriptionTable'
import { VolatilityProfileSelect } from '../Settings/RuleSelect'

const UPDATE_VOLATILITY_PROFILE = gql`
  mutation($current_volatility_id: Int) {
    update_runtime(where: {}, _set: {current_volatility_id: $current_volatility_id}) {
      affected_rows
    }
  }
`

const VOLATILITY_PROFILE_SUBSCRIPTION = gql`
subscription {
  volatility_profile(where: {is_visible: {_eq: true}}) {
    id
    name
    key: id
  }
} 
`

const CREATE_VOLATILITY_PROFILE = gql`
  mutation ($input: volatility_profile_insert_input!) {
    insert_volatility_profile (
      objects: [$input],
      on_conflict: {
        constraint: volatility_profile_name_key,
        update_columns: [
          is_visible
        ]
      }
    ) {
      returning {
        id
      }
    }
  }
`

function AddInput({ onSubmit, ...restProps }) {
  const [value, setValue] = useState('')
  return (
    <Input.Search
      {...restProps}
      value={value}
      enterButton={<PlusOutlined />}
      onChange={({ target: { value } }) => { setValue( value ) }}
      onSearch={value => {
        onSubmit && onSubmit(value)
        setValue('')
      }}
    />
  )
}

function VolatilityTable({ props }) {
  const subscription = useSubscription(VOLATILITY_PROFILE_SUBSCRIPTION)

  const columns = [
    {
      title: <span>Volatility Profile</span>,
      dataIndex: 'name',
      render: text => <span style={{color:'#1890ff'}}>{text}</span>,
      align: 'center',
    }
  ]
  return (
    <SubscriptionTable
      subscription={subscription}
      rowKey='key'
      pagination={false}
      size='small'
      showHeader={true}
      columns={columns}
      footer={() =>
        <Mutation mutation={CREATE_VOLATILITY_PROFILE}>
          {(createStream) => (
            <AddInput
              size='small'
              onSubmit={name => {
                createStream({
                  variables: {
                    input: {
                      name: name,
                      is_visible: true,
                    }
                  }
                })
              }}
            />
          )}
        </Mutation>
      }
      {...props}
    />
  )
}

export default VolatilityTable 
