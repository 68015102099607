import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import { getAuthenticationToken } from '../selectors/Authentication'

import { getMainDefinition } from 'apollo-utilities'
import { ApolloLink, split } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { InMemoryCache } from 'apollo-cache-inmemory'

const addTypename = true
const dataIdFromObject = ({ id, __typename }) => `${__typename}:${id}`
const clientOptions = {
  queryDeduplication: true,
}

export const withRouter = (Component) =>{
  const Wrapper = (props) =>{
    const history = useNavigate()
    return <Component history={history} {...props}/>
  }
  return Wrapper
} 

class ApolloWithAuthenticationProvider extends Component {
  state = {
    client: null,
    wsLink: null,
  }

  UNSAFE_componentWillMount = () => {
    this.connectApollo()
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.token !== nextProps.token) {
      if (this.state.client) {
        // disconnect the previous connection
        this.state.wsLink.subscriptionClient.close(false, false)
      }
      if (nextProps.token) {
        // we have a new token
        this.connectAuthenticatedApollo(nextProps.httpUri, nextProps.wsUri, nextProps.token)
      } else {
        // we have no token
        this.connectGuestApollo(nextProps.httpUri, nextProps.wsUri)
      }
    }
  }
  
  connectGuestApollo = (httpUri, wsUri) => {
    const httpLink = new HttpLink({
      uri: httpUri,
    })
    
    const wsLink = new WebSocketLink({
      uri: wsUri,
    })
    
    const terminatingLink = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query)
        return (
          kind === 'OperationDefinition' && operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )
    
    const link = ApolloLink.from([terminatingLink])
    
    const cache = new InMemoryCache({
      addTypename,
      dataIdFromObject,
    })
    
    
    const defaultOptions = { 
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      }
    }
    
    const client = new ApolloClient({
      link,
      cache,
      defaultOptions,
      ...clientOptions
    })

    this.setState({ client, wsLink })
  }

  connectAuthenticatedApollo = (httpUri, wsUri, token) => {
    const Authorization = `Bearer ${token}`
    const httpLink = new HttpLink({
      uri: httpUri,
      credentials: 'include',
      headers: {
        Authorization,
      },
    })

    const wsLink = new WebSocketLink({
      uri: wsUri,
      options: {
        onError: (error) => {
          console.log('error', error)
        },
        lazy: true,
        reconnect: true,
        connectionParams: {
          headers: {
            Authorization,
          },
        },
      },
    })

    const terminatingLink = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query)
        return (
          kind === 'OperationDefinition' && operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )
    
    const link = ApolloLink.from([terminatingLink])
    
    const cache = new InMemoryCache({
      addTypename,
      dataIdFromObject,
    })
    
    
    const defaultOptions = { 
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      }
    }
    
    const client = new ApolloClient({
      link,
      cache,
      defaultOptions,
      ...clientOptions
    })

    this.setState({ client, wsLink })
  }

  connectApollo = () => {
    const { token, httpUri, wsUri } = this.props
    if (token) this.connectAuthenticatedApollo(httpUri, wsUri, token)
    else this.connectGuestApollo(httpUri, wsUri)
  }

  render() {
    const { children } = this.props
    const { client } = this.state
    return (
      <ApolloProvider client={client}>
        {children}
      </ApolloProvider>
    )
  }
}


export default compose(
  withRouter,
  connect(
    (state, props) => ({
      token: getAuthenticationToken(state),
    })
  )
)(
  ApolloWithAuthenticationProvider 
)


