import React, { useMemo, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { convert } from 'crontzconvert'
import {
  Card,
  Table,
  Tag,
  Col,
  Spin,
  Alert,
  Checkbox,
  Divider,
  Select,
  Button,
  Popconfirm,
  Input,
} from 'antd'
import { PlusOutlined, SyncOutlined, FolderFilled } from '@ant-design/icons'
import gql from 'graphql-tag'
import { Navigate } from 'react-router-dom'
import { useQuery, useSubscription, useMutation } from '@apollo/react-hooks'
import cronstrue from 'cronstrue'
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import { setNotification } from 'actions/Notification'
import { getNotification } from 'selectors/Notification'
import { useSelector, useDispatch } from 'react-redux'
import { getTheme, setTheme } from 'utils/theme'

import SubscriptionTable from 'components/SubscriptionTable'
import './Trader/index.css'

const CLEAR_ORDERS= gql`
mutation{
  delete_order(where: {id : {_neq: -999999}}){
    returning {
      id
    }
  }
  delete_execution_report(where: {id: {_neq: -999999}}) {
    returning{
      id
    }
  }
}
`

const GET_SCHEDULED_STOP = gql`
subscription {
  command_schedule(order_by: {id: asc}) {
    id
    command
    cron_time
    enabled
    synched
  }
}
`

const GET_CRON_SCHEDULE = gql`
subscription {
  cron_job(where: {jobname: {_neq: "update_current_positions_job"}}, order_by: {jobname: asc}) {
    jobid
    id: jobname
    command: command_type
    cron_time: schedule
    enabled: active
  }
}
`

const SYNCH_CRON_SCHEDULE = gql`
mutation {
  wrapper_create_command_cron_jobs {
    id
    cron_time
    command
    enabled
  }
} 
`

const DELETE_SCHEDULE_ENTRY = gql`
mutation ($id: Int!) {
  delete_command_schedule(
    where: {id: {_eq: $id}}
  ) {
    returning {
      id
    }
  }
}
`
const UPDATE_SCHEDULE_ENTRY = gql`
mutation ($id: Int!, $input: command_schedule_set_input!) {
  update_command_schedule(where: {id: {_eq: $id}}, _set: $input) {
    returning {
      id
      command
      cron_time
      enabled
    }
  }
} 
`

const ADD_SCHEDULE_ENTRY = gql`
mutation ($input: command_schedule_insert_input!) {
  insert_command_schedule(objects: [$input]) {
    returning {
      id
      command
      cron_time
      enabled
    }
  }
} 
`

const FOREX_FACTORY_CAL = gql`
query ($impact: [String]) {
  getForexFactoryCal (impact: $impact) {
    id
    key: id  
    title
    country
    impact
    forecast
    date
    day
    time
  }
}
`

const INSERT_SCHEDULED_COMMAND = gql`
mutation ($command: command_type, $cron: String) {
  insert_command_schedule(objects: {command: $command, cron_time: $cron}) {
    returning {
      id
      command
      cron_time
      enabled
    }
  }
}
`

function Loading() {
  return (
    <Col span={24} style={{ padding: 10 }}>
      <Spin tip="Loading...">
        <Alert
          message="Please wait for the trade group to load."
          type="info"
        />
      </Spin>
    </Col>
  )
}

function ForexFactory() {
  const query = useQuery(FOREX_FACTORY_CAL, {variables: {impact: ["High", "Medium"]}})
  const [insertScheduledCommand] = useMutation(INSERT_SCHEDULED_COMMAND)
  const [increment, setIncrement] = useState({})
  const [scheduledCommand, setScheduledCommand] = useState({})

  useEffect(() => {
    if (query.data) {
      const dataSource = query.data.getForexFactoryCal
      const sortedDataSource = dataSource.sort((a, b) => moment(a.date).unix() - moment(b.date).unix())
      const timeMap = mergeCells(sortedDataSource, 'date', 'time')
      const initialIncrement = {}
      const initialCommand = {}
      Object.keys(timeMap).forEach(key => {
        initialIncrement[key] = 0
        initialCommand[key] = 'stop'
      })
      setIncrement(initialIncrement)
      setScheduledCommand(initialCommand)
    }
  }, [query.data]) 

  useEffect(() => {
    console.log('update')
  }, [scheduledCommand])

  if(query.loading) <Loading />

  const mergeCells = (data, key, frame) => {
    const map = {}
    const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date))
    sortedData.forEach((item, index) => {
      const value = frame === 'day'
        ?
        moment(item[key]).local().format('YYYY-MM-DD')
        : moment(item[key]).local().format('YYYY-MM-DD HH:mm')
      if (!map[value]) {
        map[value] = {
          index,
          count: 1,
        }
      } else {
        map[value].count++
      }
    })
    return map
  }

  const dataSource = query.data ? query.data.getForexFactoryCal : []
  const sortedDataSource = dataSource.sort((a, b) => moment(a.date).unix() - moment(b.date).unix()) 
  const dateMap = mergeCells(sortedDataSource, 'date', 'day')
  const timeMap = mergeCells(sortedDataSource, 'date', 'time')

  const submitToSchedule = (command, cron) => {
    const date = new Date(cron)
    const minute = date.getMinutes()
    const hour = date.getHours()
    const day = date.getDate()
    const month = date.getMonth() + 1
    const dayOfWeek = date.getDay()

    const cronString = `${minute} ${hour} ${day} ${month} ${dayOfWeek}`

    insertScheduledCommand({variables: {command: command, cron: cronString}}) 
  } 

  const handleIncrement = (time, value) => {
    setIncrement(prevIncrement => ({
      ...prevIncrement,
      [time]: prevIncrement[time] + value 
    }))
  } 

  const handleSelect = (time, value) => {
    setScheduledCommand(prevScheduledCommand => ({
      ...prevScheduledCommand,
      [time]: value
    }))
  } 


  const columns = [
    {
      title: 'Impact',
      dataIndex: 'impact',
      key: 'impact',
      render: (impact, record) =>
        <FolderFilled
          style={moment(record.date).isBefore(today) ? { color: 'gray' } : impact === "High" ? { color: 'red' } : {color: 'orange'}}
        />
    },
    {
      title: 'Currency',
      dataIndex: 'country',
      key: 'country',
    },
    { 
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'day',
      aligh: 'center',
      render: (text, row, index) => {
        const day = moment(text).local().format('YYYY-MM-DD')
        const obj = {
          children: moment(day).format('dddd, YYYY-MM-DD'),
          props: {},
        }
        if (index === dateMap[day].index) {
          obj.props.rowSpan = dateMap[day].count
        } else {
          obj.props.rowSpan = 0
        }
        return obj
      },
    },
    {
      title: 'Time',
      dataIndex: 'date',
      key: 'time',
      align: 'center',
      render: (text, row, index) => {
        const time = moment(text).local().format('YYYY-MM-DD HH:mm')
        const obj = {
          children: moment(time).format('HH:mm'),
          props: {},
        }
        if (index === timeMap[time].index) {
          obj.props.rowSpan = timeMap[time].count
        } else {
          obj.props.rowSpan = 0
        }
        return obj
      },
    },
    {
      title: 'Action',
      dataIndex: 'date',
      key: 'time',
      aligh: 'center',
      render: (text, row, index) => {
        const time = moment(text).local().format('YYYY-MM-DD HH:mm')
        const disabled = moment(row.date).isBefore(today)
        const obj = {
          children: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '5px',
              }}
            >
              <Select
                defaultValue='stop'
                onSelect={ value =>
                  handleSelect(time, value)
                }
                disabled={disabled}
                style={{
                  width: 120,
                }}
                options={[
                  {
                    value: 'halt',
                    label: 'Reset',
                  },
                  {
                    value: 'stop',
                    label: 'Stop',
                  },
                  {
                    value: 'trade',
                    label: 'Trade',
                  },
                  {
                    value: 'resolve',
                    label: 'Resolve',
                  },
                ]}
              />
              <Button
                type='default'
                disabled={disabled}
                style={{ margin: '5px' }}
                onClick={() => handleIncrement(time, -1)}
              >
                {'-1 min'}
              </Button>
              <Button
                type='default'
                disabled={disabled}
                style={{ margin: '5px' }}
                onClick={() => handleIncrement(time, 1)}
              >
                {'+1 min'}
              </Button>
              <Tag color={disabled ? '' : 'blue'} style={disabled ? {color:'#a0a0a0', margin: '5px'} : { margin: '5px' }}>
                {moment(text).add(increment[time], 'minutes').local().format('YYYY-MM-DD HH:mm')}
              </Tag>
              <Button
                type='primary'
                disabled={disabled}
                style={{ margin: '5px' }}
                onClick={() => submitToSchedule(scheduledCommand[time], moment(text).add(increment[time], 'minutes').utc().format('YYYY-MM-DD HH:mm'))}
              >
                Commit
              </Button>
            </div>
          ),
          props: {},
        }
        if (index === timeMap[time].index) {
          obj.props.rowSpan = timeMap[time].count
        } else {
          obj.props.rowSpan = 0
        }
        return obj
      },
    },
  ]

  const today = moment()

  return (
    <Card title='Forex Factory Calendar' size='small' type='inner' bordered={false}>
      <Table
        columns={columns}
        dataSource={sortedDataSource}
        size='small'
        rowKey='id'
        pagination={{
          current: 1,
          pageSize: 100,
        }}
        rowClassName={
          ({ date }) => {
            return (moment(date).isBefore(today))
              ? 'gray-out'
              : null
          }
        }
      />
    </Card>
  )
}

function CronSchedule() {
  const getCronSchedule = useSubscription(GET_CRON_SCHEDULE)
  const [cronJobs, setCronJobs] = useState([])

  const columns = [
    {
      title: 'Jobname',
      dataIndex: 'id',
    }, {
      title: 'Command',
      dataIndex: 'command',
      render: (command) => 
        <Tag color={
          command === 'stop' ? 'magenta' :
            command === 'trade' ? 'processing' :
              command === 'resolve' ? 'warning' :
                'geekblue'
        }>
          {command}
        </Tag>
    }, {
      title: 'Local Time',
      dataIndex: 'cron_time',
      render: (cron_time) => cronstrue.toString(convert(cron_time, 'UTC', moment.tz.guess()))
    }, {
      title: 'Server Time',
      dataIndex: 'cron_time',
      render: (cron_time) => cronstrue.toString(cron_time)
    },
  ]

  return (
    <Card
      title='Cron Command Schedule'
      size='small'
      type='inner'
      bordered={false}
    >
      <SubscriptionTable
        subscription={getCronSchedule}
        rowKey='id'
        pagination={false}
        size='small'
        showHeader={true}
        columns={columns}
      />
    </Card>
  )
}

function StopTimer() {
  const subscription = useSubscription(GET_SCHEDULED_STOP)
  const [deleteScheduledCommand] = useMutation(DELETE_SCHEDULE_ENTRY)
  const [updateScheduledCommand] = useMutation(UPDATE_SCHEDULE_ENTRY)
  const [insertScheduledCommand] = useMutation(ADD_SCHEDULE_ENTRY)
  const [synchCronScheduled] = useMutation(SYNCH_CRON_SCHEDULE)
  const [editing, setEditing] = useState({selected: -1, editing: false})
  const [value, setValue] = useState('30 5 * * 1,6')
  const [command, setCommand] = useState('stop')



  console.log(moment.tz.guess())
  console.log(value)
  console.log(convert(value, moment.tz.guess(), 'UTC'), 'ERRORRO MAYBE DUNNO')

  const columns = [
    {
      title: 'Operation',
      dataIndex: 'id',
      render: (id, record) =>
        <span>
          <a
            onClick={() => {
              setEditing({selected: id, editing: editing['selected'] != id ? true : !editing['editing']})
              setValue(convert(record.cron_time, 'UTC', moment.tz.guess()).substring(2)) 
              setCommand(record.command) 
            }}
          >
            {editing['selected'] == id && editing['editing'] ? 'Cancel' : 'Edit'}
          </a>
          <Divider type='vertical' />
          { editing['selected'] == id && editing['editing'] ?
            <a 
              onClick={() => {
                updateScheduledCommand({ variables: { id: record.id, input: {cron_time: convert(value, moment.tz.guess(), 'UTC').substring(2), command: command} } })
                setEditing({selected: id, editing: false})
              }}
            >
              Save
            </a>
            :
            <Popconfirm
              title="Are you sure you want to delete scheduled command?"
              onConfirm={() =>
                deleteScheduledCommand({ variables: { id: id } })
              }
              okText="Yes"
              cancelText="No"
            >
              <a href='#'>
                Delete
              </a>
            </Popconfirm> 
          }
        </span>
    }, {
      title: 'id',
      dataIndex: 'id',
      render: (id) => 'job_' + id
    }, {
      title: 'Command',
      dataIndex: 'command',
      render: (command, record) => {
        return editing['selected'] == record.id && editing['editing'] ?
          <Select
            defaultValue={command}
            onSelect={ command =>
              setCommand(command)
            }
            style={{
              width: 120,
            }}
            options={[
              {
                value: 'halt',
                label: 'Reset',
              },
              {
                value: 'stop',
                label: 'Stop',
              },
              {
                value: 'trade',
                label: 'Trade',
              },
              {
                value: 'resolve',
                label: 'Resolve',
              },
            ]}
          />
          :
          <Tag color={
            command === 'stop' ? 'magenta' :
              command === 'trade' ? 'processing' :
                command === 'resolve' ? 'warning' :
                  'geekblue'
          }>
            {command}
          </Tag>
      }
    }, {
      title: 'Local Time',
      dataIndex: 'cron_time',
      render: (cron_time, record) =>
        editing['selected'] == record.id && editing['editing'] ?
          <Cron value={value} setValue={setValue} />
          :
          cronstrue.toString(convert(cron_time, 'UTC', moment.tz.guess()).substring(2))
    }, {
      title: 'Server Time',
      dataIndex: 'cron_time',
      render: (cron_time, record) => cronstrue.toString(cron_time)
    }, {
      title: 'Status',
      dataIndex: 'enabled',
      render: (check, record) => 
        <Popconfirm
          title="Are you sure you want to delete scheduled command?"
          onConfirm={() =>
            updateScheduledCommand({ variables: { id: record.id, input: {enabled: !check} } })
          }
          okText="Yes"
          cancelText="No"
        >
          <a style={check ? {} : {color: 'red'}}>{check ? 'Enabled' : 'Disabled'}</a>
        </Popconfirm>
    }, {
      title: 'Active',
      dataIndex: 'synched',
      render: (synched) => <Checkbox checked={synched} />
    }
  ]

  const getCronNow = () => {
    const now = new Date()
    const minute = now.getMinutes()
    const hour = now.getHours()
    const dayOfMonth = now.getDate()
    const month = now.getMonth() + 1
    const dayOfWeek = now.getDay()
    return `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`
  }

  return (
    <>
      <CronSchedule/>
      <br/>
      <Card
        title='Staging Command Schedule'
        size='small'
        type='inner'
        bordered={false}
        actions={[
          <PlusOutlined
            onClick={ () =>
              insertScheduledCommand({ variables: {
                "input": {
                  "cron_time": getCronNow(),
                  "command": "stop",
                  "enabled": false
                }
              }})
            }
            key='add'
          />,
          <SyncOutlined
            onClick={() => synchCronScheduled()}
            key='synch'
          />
        ]}
      >
        <SubscriptionTable
          subscription={subscription}
          rowKey='id'
          pagination={false}
          size='small'
          showHeader={true}
          columns={columns}
        />
      </Card>
    </>
  )
}

function ThemePreferences() {
  const currentTheme = getTheme()
  return (
    <Card title='Theme' size='small' type='inner' bordered={false}>
      <Select value={currentTheme} onChange={setTheme}>
        <Select.Option value='light'>Light</Select.Option>
        <Select.Option value='dark'>Dark</Select.Option>
      </Select>
    </Card>
  )
}

function NotificationPreferences() {
  const dispatch        = useDispatch()
  const notificationBoolean    = useSelector(getNotification)
  const setNotificationBoolean = useMemo(
    () => (evt) => dispatch(setNotification(evt.target.name, evt.target.checked)),
    [dispatch],
  )

  return (
    <Card title='Notification' size='small' type='inner' bordered={false}>
      <Checkbox name='trade' checked={notificationBoolean.trade} onChange={setNotificationBoolean}>Trade</Checkbox>
      <Checkbox name='stop'  checked={notificationBoolean.stop}  onChange={setNotificationBoolean}>Stop</Checkbox>
      <Checkbox name='error' checked={notificationBoolean.error} onChange={setNotificationBoolean}>Error</Checkbox>
    </Card>
  )
}

function ClearOrders() {
  const [confirmValue, setConfirmValue] = useState('')
  const [validationStatus, setValidationStatus] = useState('')
  const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false)
  const [clearOrders] = useMutation(CLEAR_ORDERS)

  const showPopconfirm = () => {
    setIsPopconfirmVisible(true)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setConfirmValue(value)
    setValidationStatus(value === 'CONFIRM' ? '' : 'error')
  }

  const handleCancel = () => {
    setIsPopconfirmVisible(false)
    setValidationStatus('')
    setConfirmValue('')
  } 

  const confirmAction = async () => {
    if (confirmValue === 'CONFIRM') {
      //Perform Delete
      await clearOrders()

      setIsPopconfirmVisible(false) // Close Popconfirm
      setValidationStatus('') // Reset validation status
      setConfirmValue('')
      window.location.reload()
    } else {
      setValidationStatus('error') // Set error status
    }
  }

  return (
    <Card title='Clear Orders' size='small' type='inner' bordered={false}>
      <Button type='danger' onClick={showPopconfirm} >Clear Orders</Button>
      <Popconfirm
        title={
          <div>
            Type <strong>&quot;CONFIRM&quot;</strong> to clear all Orders! 
            <Input
              value={confirmValue}
              onChange={handleInputChange}
              status={validationStatus}
              style={{ marginTop: 10 }}
            />
          </div>
        }  
        visible={isPopconfirmVisible}
        onConfirm={confirmAction}
        onCancel={handleCancel}
        okText='Confirm'
        cancelText='Cancel'
      />
    </Card>
  )
}

function Preferences() {
  return (
    <>
      <ThemePreferences />
      <br />
      <NotificationPreferences />
      <br />
      <ClearOrders />
      <br />
      <StopTimer/>
      <br />
      <ForexFactory/>
    </>
  )
}

export default Preferences 
