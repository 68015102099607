import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { authenticationLogout } from 'actions/Authentication'

function Logout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(authenticationLogout())
    navigate('/login')
  }, [dispatch, navigate])

}

export default Logout
