import React, { useEffect } from 'react'
import { useSubscription } from '@apollo/react-hooks'
import {
  Alert,
  Row,
  Col
} from 'antd'
import {
  UserDeleteOutlined,
  CodepenOutlined,
  CoffeeOutlined
} from '@ant-design/icons'
import gql from 'graphql-tag'

const USER_STATUS = gql`
subscription{
  user(where: {status: {_neq: "active"}}){
    id
    name
    status
  }
}
`

function UserStatus() {
  const { loading, error, data } = useSubscription(USER_STATUS)

  useEffect(() => {
    console.log('update status')
  }, [data])

  if(loading)
    return <></>

  return (
    <div>
      {data.user.length > 0 ?
        <Alert key='user-status'
          message={data.user[0].status === 'developing' ? 'Trader status' : 'User status'}
          description=
            <Row>
              {data.user.map((user) =>
                <Col span={24/data.user.length} key={user.id}>
                  { 
                    user.status === 'developing' ?
                      <></>
                      :
                      user.status === 'lunch' ?
                        <CoffeeOutlined
                          style={{color:'orange'}}
                          key={user.name+'-icon'}
                        />
                        :
                        <UserDeleteOutlined
                          style={user.status === 'busy' ? {color:'red'} : user.status === 'away' ? {color: 'blue'} : {color:'gray'}}
                          key={user.name+'-icon'}
                        />
                  }
                  { user.status === 'developing' ?
                    <span key={user.name}> 
                      We regret to inform you that our system is currently undergoing a critical development update, and as a result, it is temporarily unavailable during this time. We understand the inconvenience this may cause and appreciate your patience.
                    </span>
                    :
                    <span key={user.name}> 
                      {' ' + user.name} is {user.status === 'lunch' ? ' having ' + user.status : user.status}
                    </span>
                  }
                </Col>
              )}
            </Row>
          type='warning'
        />
        :
        <></>
      }
    </div>
  )
}

export default UserStatus
