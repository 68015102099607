import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { 
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useQuery, useSubscription, useMutation } from '@apollo/react-hooks'
import { Mutation } from 'react-apollo'
import SubscriptionTable from 'components/SubscriptionTable'
import {
  Input,
  AutoComplete,
  Button,
  Checkbox,
  Popconfirm,
  Spin,
  Col,
  Form,
  Table,
  Alert
} from 'antd'
import { DeleteOutlined, PlusOutlined, MenuOutlined } from '@ant-design/icons'
import debounce from "lodash/debounce"


const TROUPE_SUBSCRIPTION = gql`
subscription trade_groupes {
  troupe (where: {is_visible: {_eq: true}}, order_by: {priority: asc}) {
    id
    name
    key: priority
    stream_instruments {
      id
      name
      stream {
        id
        account_name
        connector {
          id
          name
        }
      }
    }
  }
}
`

const STREAMS_SUBSCRIPTION = gql`
subscription streams {
  stream(
    where: {
      is_visible: { _eq: true }
    }
    order_by: { created_at: asc }
  ) {
    id
    account_name
    connector {
      id
      name
    }
  }
}
`

const CREATE_TROUPE = gql`
mutation create_trade_group($name: String!) {
  insert_troupe(
    objects: {name: $name, is_visible: true},
    on_conflict: {
      constraint: troupe_name_key,
      update_columns: [
        name,
        is_visible
      ]
  }) {
    returning {
      id
      name
      is_visible
    }
  }
}
`

const SEARCH_STREAM_INSTRUMNET = gql`
query searchStreamInstrument($streamId: Int!, $searchTerm: String!){
  stream_instrument(where: {stream_id: {_eq: $streamId} _and: [{name: {_like: $searchTerm}}, {is_visible: {_eq: true}}]}) {
    id
    value: name
  }
}
`

const UPDATE_TROUPE = gql`
mutation ($id: Int!, $troupe_id: Int!, $stream_id: Int!) {
  remove: update_stream_instrument(
    where: {stream_id: {_eq: $stream_id}, _and: {troupe_id: {_eq: $troupe_id}}}
    _set: {troupe_id: null}
    ) {
    returning {
      id
      troupe_id
    }
  }
  update_stream_instrument(
    where: {id: {_eq: $id}, _and: {stream_id: {_eq: $stream_id}}},
    _set: {troupe_id: $troupe_id}) {
    returning {
      id
      name
      stream_id
      troupe_id
    }
  }
}
`

const UPDATE_TROUPE_ORDER = gql`
mutation ($troupe_id: Int!, $priority: Int!){
  update_troupe(
    where: {id: {_eq: $troupe_id}},
    _set: {priority: $priority},
  ){
    returning{
      id
      key: priority
    }
  }
}
`

const DELETE_TROUPE = gql`
mutation ($id: Int!) {
  update_troupe (where: {id: {_eq: $id}}, _set: {is_visible: false}) {
    returning {
      id
    }
  }
}
`

function AddInput({ onSubmit, ...restProps }) {
  const [value, setValue] = useState('')

  return (
    <Input.Search
      {...restProps}
      value={value}
      enterButton={<PlusOutlined />}
      onChange={({ target: { value } }) => { setValue(value) }}
      onSearch={value => {
        onSubmit && onSubmit(value)
        setValue('')
      }}
    />
  )
}

function Loading() {
  return (
    <Col span={24} style={{ padding: 10 }}>
      <Spin tip="Loading...">
        <Alert
          message="Please wait for the trade group to load."
          type="info"
        />
      </Spin>
    </Col>
  )
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  onSearch,
  onSelect,
  setSearch,
  options,
  stream_id,
  ...restProps
}) => {
  return (
    <td {...restProps} style={{textAlign: 'center'}}>
      {editing ? (
        <Form.Item
          key={title + '-' + record.id}
          name={dataIndex}
          style={{
            minWidth: 90,
            margin: 0,
          }}
        >
          <AutoComplete
            onSelect={(value, option) => onSelect(value, option, stream_id)}
            onSearch={(e) => onSearch(e, stream_id)}
            onFocus={() => setSearch({searchTerm: '', streamId: 0})}
          >
            {options.map((option) => (
              <AutoComplete.Option key={option.id} value={option.value} id={option.id} />
            ))}
            <AutoComplete.Option key={-1} value={''} id={-1} > </AutoComplete.Option>
          </AutoComplete>
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const transformData = (data) => {
  return data.troupe.map(troupe => {
    if(Array.isArray(troupe.stream_instruments)) {
      const mapped_stream_instruments = {}
      troupe.stream_instruments.map(stream_instruments => {
        mapped_stream_instruments[stream_instruments.stream.connector.name+''+stream_instruments.stream.account_name] = stream_instruments.name
        //        mapped_stream_instruments[stream_instruments.stream.connector.name+'-id'] = stream_instruments.stream.id
      })
      troupe = {...troupe, ...mapped_stream_instruments}
      troupe['key'] = troupe.id 
    }
    return troupe
  })
}

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  })

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? 
      {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          })
        }
        return child
      })}
    </tr>
  )
}

const TradeGroup = () => {
  const subscription = useSubscription(TROUPE_SUBSCRIPTION)
  const {data, loading} = useSubscription(STREAMS_SUBSCRIPTION)
  const [createTroupe] = useMutation(CREATE_TROUPE)
  const [updateTroupe] = useMutation(UPDATE_TROUPE)
  const [updateTroupeOrder] = useMutation(UPDATE_TROUPE_ORDER)
  const [search, setSearch] = useState({searchTerm: '', streamId: 0})
  const result = useQuery(SEARCH_STREAM_INSTRUMNET, {variables: {...search}})
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  const [updates, setUpdates] = useState({})
  const setSearchDebounced = debounce(setSearch, 500)


  const isEditing = (record) => record.key === editingKey

  const edit = (record) => {
    const streamField = {}
    data.stream.map((stream) => streamField[stream.connector.name+''+stream.account_name] = '')
    form.setFieldsValue({
      ...streamField,
      ...record,
    })
    setEditingKey(record.key)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const save = async (key) => {
    const row = await form.validateFields()
    console.log(updates)
    for (const key in updates) {
      const response = await updateTroupe({
        variables: {
          troupe_id: editingKey,
          stream_id: key,
          id: updates[key]
        }
      })
    }
    setUpdates({})
    setEditingKey('')
  }

  const columns = [
    {
      key: 'sort',
    },
    {
      title: 'Edit',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <a
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Mutation mutation={DELETE_TROUPE}>
              {(deleteTroupe) => (
                <a
                  style={{
                    marginRight: 8,
                  }}
                  onClick={() => {
                    deleteTroupe({
                      variables: {
                        id: record.key,
                      }
                    })
                    setEditingKey('')
                  }}
                >
                  Delete 
                </a>
              )}
            </Mutation>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <a disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </a>
        )
      },
    },
    {
      title: 'Trade Group',
      dataindex: 'name',
      key: 'name',//_instruments[0].troupe.name',
      render: name => <span style={{color:'#1890ff'}}>{name.name}</span>,
    },
  ]

  if (!loading && !subscription.loading) {
    data.stream.map((stream) => columns.push({
      title: stream.account_name,
      dataIndex: stream.connector.name+''+stream.account_name,
      id: stream.id,
      editable: true,
      render: name => <span style={{color:'#1890ff'}}>{name}</span>
    }))
  }


  useEffect(() => {
    if (!subscription.loading ) {
      subscription.data.troupe = transformData(subscription.data)
    }
  })

  const onSelect = (value, option, stream_id) => {
    updates[stream_id] = option.id
  }

  const onSearch = async (searchText, streamId) => {
    setSearchDebounced({searchTerm: '%' + searchText.toUpperCase() + '%', streamId: streamId})
  }
  
  if(subscription.loading)
    return <Loading />

  subscription.data.troupe = transformData(subscription.data)

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        setSearch: setSearch,
        onSelect: onSelect,
        onSearch: onSearch,
        options: result.data ? result.data.stream_instrument : [],
        stream_id: col.id,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const onDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id) {
      const previous = subscription.data.troupe 
      const activeIndex = previous.findIndex((i) => i.key === active.id)
      const overIndex = previous.findIndex((i) => i.key === over?.id)

      const newOrder = arrayMove(previous, activeIndex, overIndex)
      subscription.data.troupe = [...newOrder]

      const mutationPromises = newOrder.map((currentTroupe, index) => {
        return updateTroupeOrder({
          variables: {
            troupe_id: currentTroupe.id,
            priority: index,
          }
        })
      })

      try {
        await Promise.all(mutationPromises)
        // If successful, subscription will eventually update the state
      } catch (error) {
        // If there's an error, revert to the previous state
        subscription.data.troupe = [...previous]
      }

      return subscription.data.troupe
    }
  }

  const CustomHeaderCell = ({ children, ...props }) => (
    <th
      {...props}
      style={{
        overflow: 'visible',
        whiteSpace: 'nowrap',
        textOverflow: 'initial',
      }}
    >
      {children}
    </th>
  )

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        // rowKey array
        items={subscription.data.troupe.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <Form form={form} component={false}>
          <Table
            tableLayout='auto'
            scroll={{ x: 'max-content' }}
            components={{
              header: {
                cell: CustomHeaderCell,
              },
              body: {
                cell: EditableCell,
                row: Row,
              },
            }}
            sticky={true}
            bordered={true}
            error={subscription.error}
            loading={subscription.loading}
            dataSource={subscription.data.troupe}
            pagination={false}
            rowKey='key'
            size='small'
            showHeader={true}
            columns={mergedColumns}
            rowClassName="editable-row"
            footer={() => (
              <AddInput
                size='small'
                onSubmit={name => {
                  createTroupe({
                    variables: {
                      name,
                    }
                  })
                }}
              />
            )}
          />
        </Form>
      </SortableContext>
    </DndContext>
  )
}

export default TradeGroup
