
import {
  SET_NOTIFICATION, 
} from '../actions/Notification'


export const initialState = {
  trade: true,
  stop: false,
  error: true,
}

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_NOTIFICATION: {
    const [key, value] = action.payload
    return {
      ...state,
      [key]: value, 
    }
  }
  default: return state
  }
}

export default NotificationReducer

