import React from 'react'
import {createRoot} from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { persistCombineReducers, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/es/integration/react'

import { compose, createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import ApolloWithAuthenticationProvider from 'components/ApolloWithAuthenticationProvider'
import NotificationManager from 'components/NotificationManager'
import 'utils/theme'

import { AppRoutes } from './routes'
import reducers from './reducers'

// FIXME remove base url default string
const GRAPHQL_BASE_URL = process.env.HASURA_HOST || 'trader-hasura.prop-oneroyal.com'
const GRAPHQL_HTTP_URL = `https://${GRAPHQL_BASE_URL}/v1/graphql`
const GRAPHQL_WS_URL   = `wss://${GRAPHQL_BASE_URL}/v1/graphql`

const history = createBrowserHistory()
const middlewares = [
  routerMiddleware(history),
]
const enhancers = compose(
  applyMiddleware(...middlewares)
)
const config = {
  whitelist: ['authentication', 'notification'],
  key: 'primary',
  storage,
  stateReconciler: hardSet,
}

const persistedReducers = persistCombineReducers(config, reducers)
const store = createStore(persistedReducers, { }, enhancers)
const persistor = persistStore(store, null, () => store.getState())

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <PersistGate
      loading={<div>loading</div>}
      persistor={persistor}>
      <BrowserRouter history={history}>
        <ApolloWithAuthenticationProvider
          httpUri={GRAPHQL_HTTP_URL}
          wsUri={GRAPHQL_WS_URL}>
          <NotificationManager />
          <AppRoutes />
        </ApolloWithAuthenticationProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)
