import UIfx from 'uifx'
import tradeMp3 from './trade.mp3'
import errorMp3 from './error.mp3'
import stopMp3 from './stop.mp3'

export const tradeSound = new UIfx(tradeMp3, {
  volume: 1.0,
  throttleMs: 40,
})

export const errorSound = new UIfx(errorMp3, {
  volume: 1.0,
  throttleMs: 2000,
})

export const stopSound = new UIfx(stopMp3, {
  volume: 1.0,
  throttleMs: 2000,
})

