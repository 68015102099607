import React from 'react'
import gql from 'graphql-tag'
import _ from 'lodash'
import { useMutation, useSubscription } from '@apollo/react-hooks'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
} from 'antd'

import SubscriptionTable from 'components/SubscriptionTable'
import UserModal from './UserModal'

const USERS = gql`
  subscription {
    user {
      id
      name
      email
      role
      password
      key: email
    }
  }
`

const UPDATE_USER = gql`
  mutation($id: uuid!, $user: user_set_input!) {
    update_user(
      _set: $user,
      where: { id: { _eq: $id } }
    ) {
      success: affected_rows
    }
  }
`


const INSERT_USER = gql`
  mutation($email: String!, $name: String!, $password: String!, $role: role_type!) {
    insert_user(objects: { email: $email, name: $name, password: $password, role: $role }) {
      success: affected_rows
    }
  }
`


const DELETE_USER = gql`
  mutation($id: uuid!) {
    delete_user(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`

function AddInput() {
  const [insertUser] = useMutation(INSERT_USER)
  return (
    <UserModal
      insertUser={insertUser}
      title='Create User'
    >
      {showModal => (
        <Button
          size='small'
          icon={<PlusOutlined />}
          onClick={showModal}
        >
          Create User
        </Button>
      )}
    </UserModal>
  )
}



function UsersTable() {
  const subscription = useSubscription(USERS)
  const [deleteUser] = useMutation(DELETE_USER)
  const [updateUser] = useMutation(UPDATE_USER)

  const columns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: text => <span style={{color:'#1890ff'}}>{text}</span>,
  }, {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: text => <span style={{color:'#1890ff'}}>{text}</span>,
  }, {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: text => _.startCase(text),
  }, {
    align: 'right',
    render: (user) => (
      <UserModal
        title='Edit User'
        user={user}
        deleteUser={deleteUser}
        updateUser={updateUser}
      >
        {(showModal) => (
          <Button
            size='small'
            icon={<EditOutlined />}
            onClick={showModal}
          />
        )}
      </UserModal>
    )
  }]

  return (
    <SubscriptionTable
      subscription={subscription}
      pagination={false}
      size='small'
      showHeader={true}
      columns={columns}
      footer={() =>
        <div style={{ textAlign: 'center' }}>
          <AddInput />
        </div>
      }
    />
  )
}


export default UsersTable
