import React from 'react'

import Table from './Table'

export function SubscriptionTable({
  subscription: { loading, error, data },
  ...restProps
}) {
  return (
    <Table
      error={error}
      loading={loading}
      dataSource={data}
      dataSourceIndex={Object.keys(data || {})[0]}
      {...restProps}
    />
  )
}

export default SubscriptionTable
