import React, { Component } from 'react'
import gql from 'graphql-tag'
import { Subscription } from 'react-apollo'
import { Select } from 'antd'


const SUBSCRIPTION_RULES = gql`
  subscription {
    trade_rule {
      id
      name
    }
  }
`

const SUBSCRIPTION_RESOLVE_RULES = gql`
  subscription {
    resolve_rule {
      id
      name
    }
  }
`

const VOLATILITY_PROFILE_SUBSCRIPTION = gql`
subscription {
  volatility_profile(where: {is_visible: {_eq: true}}) {
    id
    name
  }
} 
`

class RuleSelect extends Component {
  render() {
    const props = this.props
    return (
      <Subscription
        subscription={SUBSCRIPTION_RULES}
      >
        {({ data, loading, subscribeToMore }) => {
          if (!data) {
            return null
          }
    
          if (loading) {
            return <span>Loading ...</span>
          }

          return (
            <Select 
              size='small'
              showSearch
              value={props.currentruleid}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              {...props}
            >
              <Select.Option value=''>
                {''}
              </Select.Option>
              {data.trade_rule.map(({ id, name }) => (
                <Select.Option key={id} value={id}>{name}</Select.Option>
              ))}
            </Select>
          )
        }}
      </Subscription>
    )
  }
}

class ResolveRuleSelect extends Component {
  render() {
    const props = this.props
    return (
      <Subscription
        subscription={SUBSCRIPTION_RESOLVE_RULES}
      >
        {({ data, loading, subscribeToMore }) => {
          if (!data) {
            return null
          }
    
          if (loading) {
            return <span>Loading ...</span>
          }

          return (
            <Select
              showSearch
              size='small'
              value={props.currentruleid}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              {...props}
            >
              <Select.Option value=''>
                {''}
              </Select.Option>
              {data.resolve_rule.map(({ id, name }) => (
                <Select.Option key={id} value={id}>{name}</Select.Option>
              ))}
            </Select>
          )
        }}
      </Subscription>
    )
  }
}

class VolatilityProfileSelect extends Component {
  render() {
    const props = this.props
    return (
      <Subscription
        subscription={VOLATILITY_PROFILE_SUBSCRIPTION}
      >
        {({ data, loading, subscribeToMore }) => {
          if (!data) {
            return null
          }
    
          if (loading) {
            return <span>Loading ...</span>
          }

          return (
            <Select 
              size='small'
              showSearch
              value={props.currentruleid}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              {...props}
            >
              {data.volatility_profile.map(({ id, name }) => (
                <Select.Option key={id+'-'+name} value={id}>{name}</Select.Option>
              ))}
            </Select>
          )
        }}
      </Subscription>
    )
  }
}

export { ResolveRuleSelect,  RuleSelect, VolatilityProfileSelect }
