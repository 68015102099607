
export const getAuthenticationToken = (state) => {
  return state.authentication.token
}

export const getUserId = (state) => {
  return state.authentication.id
}

export const getUserName = (state) => {
  return state.authentication.name
}

export const getUserRoles = (state) => {
  return state.authentication.roles
}

export const isAuthenticated = (state) => {
  return state.authentication.isAuthenticated
}

export const isAdmin = (state) => {
  return state.authentication.roles.includes('admin')
}

export const isManager = (state) => {
  return state.authentication.roles.includes('admin') || state.authentication.roles.includes('manager')
}
