import React, { useState } from 'react'
import {
  Input,
  Button,
  Checkbox,
} from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useSubscription, useMutation } from '@apollo/react-hooks'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'


import SubscriptionTable from 'components/SubscriptionTable'
import { RuleSelect, ResolveRuleSelect } from './RuleSelect'

const TROUPES = gql`
  subscription {
    troupe(where:{is_visible: {_eq: true}}, order_by: {name: asc}) {
      id
      name
      trade_rule_id
      resolve_rule_id
      auto_resolve
      auto_trade
    }
  }
`
const UPDATE_TROUPE = gql`
  mutation($id: Int, $trade_rule_id: Int) {
    update_troupe(where: { id: { _eq: $id } }, _set: { trade_rule_id: $trade_rule_id}) {
      affected_rows
    }
  }
`
const UPDATE_TROUPE_RESOLVE = gql`
  mutation($id: Int, $resolve_rule_id: Int) {
    update_troupe(where: { id: { _eq: $id } }, _set: { resolve_rule_id: $resolve_rule_id}) {
      affected_rows
    }
  }
`
const UPDATE_TROUPE_AUTO_RESOLVE = gql`
  mutation($id: Int, $auto_resolve: Boolean) {
    update_troupe(where: { id: { _eq: $id } }, _set: { auto_resolve: $auto_resolve}) {
      affected_rows
    }
  }
`

const UPDATE_TROUPE_AUTO_TRADE = gql`
  mutation($id: Int, $auto_trade: Boolean) {
    update_troupe(where: { id: { _eq: $id } }, _set: { auto_trade: $auto_trade}) {
      affected_rows
    }
  }
`

const CREATE_TROUPE = gql`
  mutation ($input: instrument_insert_input!) {
    insert_troupe(
      objects: [$input],
      on_conflict: {
        constraint: instrument_name_key,
        update_columns: [
          is_visible
        ]
      }
    ) {
      returning {
        id
      }
    }
  }
`

const DELETE_TROUPE = gql`
 mutation($id: Int) {
   update_troupe(where: {id: {_eq: $id}}, _set: {is_visible: false}) {
     returning {
       id
     }
   }
 }
`

const columns = [{
  title: 'Instruments',
  dataIndex: 'name',
  key: 'name',
  render: text => <span style={{color:'#1890ff'}}>{text}</span>,
}, {
  title: 'Trade Rule', 
  render: (row) => (
    <Mutation mutation={UPDATE_TROUPE}>
      {(updateInstrument) => (
        <RuleSelect
          size='small'
          style={{ width: '100%' }}
          currentruleid={row.trade_rule_id || ''}
          onSelect={(value) => {
            updateInstrument({
              variables: {
                id: row.id,
                trade_rule_id : value || null,
              }
            })
          }}
        /> 
      )}
    </Mutation>
  )
}, {
  title: 'Resolve Rule', 
  render: (row) => (
    <Mutation mutation={UPDATE_TROUPE_RESOLVE}>
      {(updateInstrument) => (
        <ResolveRuleSelect
          size='small'
          style={{ width: '100%' }}
          currentruleid={row.resolve_rule_id || ''}
          onSelect={(value) => {
            updateInstrument({
              variables: {
                id: row.id,
                resolve_rule_id : value || null,
              }
            })
          }}
        /> 
      )}
    </Mutation>
  )
}, {
  title: 'Auto Resolve', 
  align: 'center',
  render: (row) => (
    <Mutation mutation={UPDATE_TROUPE_AUTO_RESOLVE}>
      {(toggleAutoResolve) => (
        <Checkbox
          size='small'
          checked={row.auto_resolve}
          onClick={() => {
            toggleAutoResolve({
              variables: {
                id: row.id,
                auto_resolve: !row.auto_resolve
              }
            })
          }}
        />
      )}
    </Mutation>
  )
}, {
  title: 'Auto Trade', 
  align: 'center',
  render: (row) => (
    <Mutation mutation={UPDATE_TROUPE_AUTO_TRADE}>
      {(toggleAutoTrade) => (
        <Checkbox
          size='small'
          checked={row.auto_trade}
          onClick={() => {
            toggleAutoTrade({
              variables: {
                id: row.id,
                auto_trade: !row.auto_trade
              }
            })
          }}
        />
      )}
    </Mutation>
  )
}, {
  align: 'right',
  render: ({ id }) => (
    <Mutation mutation={DELETE_TROUPE}>
      {(deleteInstrument) => (
        <Button
          size='small'
          icon={<DeleteOutlined />}
          onClick={() => {
            deleteInstrument({
              variables: {
                id,
              }
            })
          }}
        />
      )}
    </Mutation>
  )
}]


function InstrumentsTable(props) {
  const subscription = useSubscription(TROUPES)

  return (
    <SubscriptionTable
      subscription={subscription}
      pagination={false}
      rowKey='name'
      size='small'
      showHeader={true}
      columns={columns}
    />
  )
}



export default InstrumentsTable
