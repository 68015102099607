import React from 'react'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/react-hooks'

import SubscriptionTable from 'components/SubscriptionTable'

const ERROR_REPORT_SUBSCRIPTION = gql`
subscription {
  error_report(order_by: { created_at: desc }) {
    created_at
    id
    command {
      id
      type
    }
    runtime_id
    operation {
      id
      type
    }
    type
    message
  }
}
`

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
  }, {
    title: 'Runtime',
    dataIndex: 'runtime_id',
    key: 'runtime_id',
    width: '12%',
  }, {
    title: 'Operation',
    key: 'operation',
    render: ({ command, operation }) => {
      return (
        (command && command.type) ||
        (operation && operation.type) ||
        ''
      )
    },
  }, {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
  }, {
    title: 'Message',
    key: 'message',
    dataIndex: 'message',
  }
]

function ErrorReport(props) {
  const subscription = useSubscription(ERROR_REPORT_SUBSCRIPTION)
  return (
    <SubscriptionTable
      subscription={subscription}
      rowKey='id'
      size='small'
      columns={columns}
      defaultExpandAllRows={false}
    />
  )
}

export default ErrorReport 

