
export const SET_NOTIFICATION = 'SET_NOTIFICATION'


export const setNotification = (key, value) => {
  return ({
    type: SET_NOTIFICATION,
    payload: [key, value],
  })
}

