import React from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import { useSubscription } from '@apollo/react-hooks'

import SubscriptionTable from 'components/SubscriptionTable'

const REJECTED_ORDERS_SUBSCRIPTION = gql`
subscription {
  execution_report(
      where: {execution_type: {_neq: "F"}, _and: {execution_type: {_neq: "0"}}},
      order_by: {received_at: desc}) {
    id
    received_at
    client_order_id
    execution_type
    order_status
    side
    requested_price
    fill_price
    requested_size
    operation_id
    order_serial
    stream_instrument_id
    stream_instrument {
      stream {
        id
        account_name
        connector {
          id
          name
        }
      }
    }
    message
  }
}
`
const fix_status_map = {
  0 : 'New',
  1 : 'Partial fill',
  2 : 'Fill',
  3 : 'Done for day',
  4 : 'Canceled',
  5 : 'Replaced',
  6 : 'Pending Cancel',
  7 : 'Stopped',
  8 : 'Rejected',
  9 : 'Suspended',
  A : 'Pending New',
  B : 'Calculated',
  C : 'Expired',
  F : 'Trade (partial fill or fill)',
}

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: 'Client Order Id',
    dataIndex: 'client_order_id',
    key: 'client_order_id',
  }, {
    title: 'Execution Type',
    dataIndex: 'execution_type',
    key: 'execution_type',
    render: (stat) => fix_status_map[stat],
  }, {
    title: 'Order Status',
    key: 'order_status',
    dataIndex: 'order_status',
    render: (stat) => fix_status_map[stat],
  }, {
    title: 'Side',
    key: 'side',
    dataIndex: 'side',
  }, {
    title: 'Requested Price',
    key: 'requested_price',
    dataIndex: 'requested_price',
  }, {
    title: 'Fill Price',
    key: 'fill_price',
    dataIndex: 'fill_price',
  }, {
    title: 'Requested Size',
    key: 'requested_size',
    dataIndex: 'requested_size',
  }, {
    title: 'Operation Id',
    key: 'operation_id',
    dataIndex: 'operation_id',
  }, {
    title: 'Order Serial',
    key: 'order_serial',
    dataIndex: 'order_serial',
  }, {
    title: 'Stream',
    key: 'stream_instrument',
    render: ({ stream_instrument, stream_instrument_id }) => stream_instrument.stream ? stream_instrument.stream.account_name || stream_instrument.stream.connector.name : stream_instrument_id
  }, {
    title: 'Message',
    key: 'message',
    dataIndex: 'message',
  }
]

function RejectedOrders(props) {
  const subscription = useSubscription(REJECTED_ORDERS_SUBSCRIPTION)
  return (
    <SubscriptionTable
      subscription={subscription}
      rowKey='id'
      size='small'
      columns={columns}
      defaultExpandAllRows={false}
    />
  )
}

export default RejectedOrders 

