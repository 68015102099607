import React, { useState } from 'react'
import { Form, Col } from 'antd'
import { useSubscription } from '@apollo/react-hooks'

import { Label, Tooltip, Legend, ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Line } from 'recharts'

import { tradingSessionAtHour } from 'utils/misc'

import gql from 'graphql-tag'

import Panel from 'components/Panel'
import SubscriptionSelect from 'components/SubscriptionSelect'


const INSTRUMENTS = gql`
  subscription {
    instrument(
      where: { is_visible: {_eq: true } }
      order_by: { created_at: asc }
    ) {
      id
      name
      trade_rule_id
      resolve_rule_id
      auto_resolve
    }
  }

`

const STREAMS = gql`
  subscription {
    stream(
      where: { is_visible: { _eq: true } }
      order_by: { created_at: asc }
    ) {
      id
      account_name
      connector {
        id
        name
      }
    }
  }
`

const SLIP_HOURLY = gql`
  subscription($instrument_id: Int!, $stream_id: Int!) {
    slip_hourly(
      where: {
        instrument_id: { _eq: $instrument_id }
        stream_id: { _eq: $stream_id }
      }
      order_by: { hour: asc  }
    ) {
      instrument {
        id
        name
      }
      stream {
        id
        account_name
      }
      low
      high
      total
      median
      hour
    }
  }
`


const labelProps =  {
  style: {
    fill: 'rgba(127, 127, 127, 1)',
  },
}


function SlipGraphChart({ variables }) {
  const subscription = useSubscription(
    SLIP_HOURLY, {
      variables,
    },
  )
  if (subscription.error || subscription.loading) {
    return null
  }

  return (
    <ResponsiveContainer height='100%' width='100%' minHeight='400px'>
      <LineChart
        data={subscription.data.slip_hourly}
        margin={{ top: 10, bottom: 25, left: 10, right: 20 }}
      >
        <XAxis dataKey="hour">
          <Label value="Hour" position="bottom" offset={30} {...labelProps} />
        </XAxis>
        <YAxis>
          <Label value="Slip" position="left" offset={-20} {...labelProps} />
        </YAxis>
        <Tooltip
          labelFormatter={
            hour => (
              <span>
                {hour}
                {tradingSessionAtHour(hour - 3).map(({ flag }) => <>&nbsp;{flag}</>)}
              </span>
            )
          }
        />
        <Legend />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
        <Line type="monotone" dataKey="high" stroke="#82ca9d" />
        <Line type="monotone" dataKey="median" stroke="#999999" />
        <Line type="monotone" dataKey="low" stroke="#ca8482" />
        <Line type="monotone" dataKey="total" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer> 
  )
}

function InstrumentSelect(props) {
  const subscription = useSubscription(
    INSTRUMENTS,
  )

  return (
    <SubscriptionSelect
      subscription={subscription}
      valueIndex='id'
      labelIndex='name'
      {...props}
    />
  )
}



function StreamSelect(props) {
  const subscription = useSubscription(
    STREAMS,
  )

  return (
    <SubscriptionSelect
      subscription={subscription}
      valueIndex='id'
      labelRender={
        x => x.account_name ? `${x.connector.name} (${x.account_name})` : x.connector.name
      }
      {...props}
    />
  )
}

const formStyle = {
  labelCol: { span: 4 },
  wrapperCol: {  },
  size: 'small',
}



function SlipGraph() {
  const [variables, setVariables] = useState({})
  return (
    <Col span={24} style={{ padding: 10 }}>
      <Panel title='Slip'>
        <Form
          onValuesChange={(oldValues, newValues) => setVariables(newValues)}
          {...formStyle}
        >
          <Form.Item
            label='Stream'
            name='stream_id'
            rules={[{
              required: true,
              message: 'Stream is required',
            }]}
          >
            <StreamSelect />
          </Form.Item>
          <Form.Item
            label='Instrument'
            name='instrument_id'
            rules={[{
              required: true,
              message: 'Instrument is required',
            }]}
          >
            <InstrumentSelect />
          </Form.Item>
        </Form>
        <br />
        <SlipGraphChart variables={variables} />
      </Panel>
    </Col>
  )
}


export default SlipGraph

