import React from 'react'
import { Table, Divider, Checkbox, Form, Select, InputNumber } from 'antd'

const FormItemWrapper = ({ fields, children, ...rest}) => {
  return (
    <Form.Item
      shouldUpdate={
        (prevValues, currentValues) => {
          for (const field of fields) {
            if (prevValues[field] !== currentValues[field]) {
              return true
            }
          }
          return false
        }
      }
      {...rest}
    >
      {({ getFieldValue, ...subRest }) => (
        children({
          ...subRest,
          fields: Object.fromEntries(fields.map(key => [key, getFieldValue(key)]))
        })
      )}
    </Form.Item>
  )
}



const StrategyFormItem = ({handleLockType, ruleType, rule}) => (
  <>
    <Form.Item label="Strategy" name='strategy_type'
      rules={[
        {
          required: true,
          message: 'Please select a strategy type',
        },
      ]} >
      <Select onChange={e => handleLockType(e)} >
        <Select.Option value="standard">Standard</Select.Option>
        <Select.Option value="lock">Lock</Select.Option>
      </Select>
    </Form.Item>

    <FormItemWrapper noStyle fields={['strategy_type']}>
      {({ fields: { strategy_type } }) => {
        switch (strategy_type) {
        case 'standard':
          return (
            <Form.Item label="Use Limit Order" name='use_limit_order' valuePropName='checked'>
              <Checkbox></Checkbox>
            </Form.Item>
          )
        case 'lock':
          return (
            <LockFormItem handleLockType={handleLockType} ruleType={ruleType} rule={rule}/>
          )
        default:
          return null
        }}}
    </FormItemWrapper>
  </>
)


const ReverseMartingaleTable = (rule) => {
  const columns = [
    { title: 'Count Increment', dataIndex: 'count_increment', key: 'count_increment', align: 'center' },
    { title: 'Trigger', dataIndex: 'trigger', key: 'trigger', align: 'center' },
    { title: 'Volume', dataIndex: 'volume', key: 'volume', align: 'center' },
  ]

  const data = []
  for(let i = 0; i < rule.count_increment; i++) {
    data.push({
      'count_increment': i+1,
      'trigger': Math.round((rule.spread + rule.trailing_trigger + (rule.trailing_step*(i))) * 100000) / 100000 ,
      'volume': Math.max(Math.min(rule.spot_increment_base+ i*rule.spot_increment_step, rule.spot_increment_max), rule.spot_increment_min)
    })
  }
  console.log(rule)
  console.log(data)

  return (
    <div style={{ marginLeft: 100, marginRight: 100, textAlign: 'center' }} >
      <Table 
        size='small'
        columns={columns}
        dataSource={data}
        rowKey='layer'
      />
    </div>
  )
}


const LockFormItem = ({handleLockType, ruleType, rule}) => {
  return (
    <>
      <Form.Item
        label='Lock Type'
        name='lock_type'
        rules={[
          {
            required: true,
            message: 'Please select a lock type',
          },
        ]}
      >
        <Select onChange={e => handleLockType(e)} >
          <Select.Option value="immediate">Immediate</Select.Option>
          <Select.Option value="time-delay">Time Delay</Select.Option>
          <Select.Option value="price-trigger">Price Trigger</Select.Option>
          <Select.Option value="trailing-stop">Trailing Stop</Select.Option>
          {ruleType === 'trade-rule' ? 
            <Select.Option value="reverse-martingale">Reverse Martingale</Select.Option>
            : <></>
          }
        </Select>
      </Form.Item>


      <FormItemWrapper noStyle fields={['lock_type']}>
        {({ fields: { lock_type } }) => {
          switch (lock_type) {
          case 'price-trigger':
            return (
              <>
                <Form.Item
                  label='Take Profit'
                  name='take_profit'
                  rules={[
                    {
                      required: true,
                      message: 'Please input take profit',
                    },
                  ]} 
                >
                  <InputNumber min={0} />
                </Form.Item> 

                <Form.Item
                  label='Stop Loss'
                  name='stop_loss'
                  rules={[
                    {
                      required: true,
                      message: 'Please input stop loss',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item> 
              </>
            )
          case 'trailing-stop':
            return (
              <>
                <Form.Item
                  label='Trailing Trigger'
                  name='trailing_trigger'
                  rules={[
                    {
                      required: true,
                      message: 'Please input trailing trigger',
                    },
                  ]} 
                >
                  <InputNumber min={0} />
                </Form.Item> 

                <Form.Item
                  label='Initial Step'
                  name='initial_step'
                  rules={[
                    {
                      required: true,
                      message: 'Please input initial trigger',
                    },
                  ]} 
                >
                  <InputNumber min={0} />
                </Form.Item> 

                <Form.Item
                  label='Trailing Step'
                  name='trailing_step'
                  rules={[
                    {
                      required: true,
                      message: 'Please input trailing trigger',
                    },
                  ]} 
                >
                  <InputNumber min={0} />
                </Form.Item> 

                <Form.Item
                  label='Stop Loss'
                  name='stop_loss'
                  rules={[
                    {
                      required: true,
                      message: 'Please input stop loss',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item> 
              </>
            )
          case 'reverse-martingale':
            return (
              <>
                <Form.Item
                  label='Trailing Trigger'
                  name='trailing_trigger'
                  rules={[
                    {
                      required: true,
                      message: 'Please input trailing trigger',
                    },
                  ]} 
                >
                  <InputNumber min={0} />
                </Form.Item> 

                <Form.Item
                  label='Initial Step'
                  name='initial_step'
                  rules={[
                    {
                      required: true,
                      message: 'Please input initial trigger',
                    },
                  ]} 
                >
                  <InputNumber min={0} />
                </Form.Item> 

                <Form.Item
                  label='Trailing Step'
                  name='trailing_step'
                  rules={[
                    {
                      required: true,
                      message: 'Please input trailing trigger',
                    },
                  ]} 
                >
                  <InputNumber min={0} />
                </Form.Item> 

                <Form.Item
                  label='Stop Loss'
                  name='stop_loss'
                  rules={[
                    {
                      required: true,
                      message: 'Please input stop loss',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item> 
                <Form.Item
                  label='Count Increment'
                  name='count_increment'
                  rules={[
                    {
                      required: true,
                      message: 'Please input count increment',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item> 
                <Form.Item
                  label='Volume Increment Base'
                  name='spot_increment_base'
                  rules={[
                    {
                      required: true,
                      message: 'Please input volume increment',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item> 
                <Form.Item
                  label='Volume Increment Step'
                  name='spot_increment_step'
                  rules={[
                    {
                      required: true,
                      message: 'Please input volume increment step',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item> 
                <Form.Item
                  label='Volume Increment Max'
                  name='spot_increment_max'
                  rules={[
                    {
                      required: true,
                      message: 'Please input volume increment max',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item> 
                <Form.Item
                  label='Volume Increment Min'
                  name='spot_increment_min'
                  rules={[
                    {
                      required: true,
                      message: 'Please input volume increment min',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item> 
                {ReverseMartingaleTable(rule)}
              </>
            )
          case 'time-delay':
            return (
              <Form.Item
                label='Delay'
                name='delay'
                rules={[
                  {
                    required: true,
                    message: 'Please input time delay',
                  },
                ]} 
              >
                <InputNumber min={0} />
              </Form.Item> 
            )
          default:
            return null
          }}}
      </FormItemWrapper>
      <Divider />
    </>
  )
}

export {
  StrategyFormItem,
  LockFormItem,
  FormItemWrapper,
}

