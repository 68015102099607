import React, { useState } from 'react'
import {
  Divider,
  Layout,
  Menu,
  Dropdown,
  PageHeader,
  Badge,
} from 'antd'
import Icon, {
  MenuFoldOutlined, 
  MenuUnfoldOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import UserStatusMenu from './UserStatusMenu'

import { getTheme } from 'utils/theme'
import { getUserName, getUserId, getUserRoles } from 'selectors/Authentication'
import { routes } from 'routes'


const { Content, Sider } = Layout


const getPathIndex = (routes, pathname) => {
  console.log(pathname)
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].path.endsWith(pathname)) {
      return [`${i}`]
    }
  }
  return []
}

const getUserRoutes = (routes, userRoles) => {
  return routes.filter(route => route.roles.filter(x => userRoles.includes(x)).length)
}

function App({ children }) {
  const [collapsed, setCollapsed] = useState(false)
  const toggle = () => setCollapsed(!collapsed)

  const userRoles = useSelector(getUserRoles)
  const userId = useSelector(getUserId)
  const userName = useSelector(getUserName)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const theme = getTheme()

  const sidebarRoutes = getUserRoutes(routes, userRoles).filter(x => x.menu.includes('sidebar'))
  const topbarRoutes = getUserRoutes(routes, userRoles).filter(x => x.menu.includes('topbar'))


  window.localStorage.setItem('user-id', userId)

  const menu = (
    <Menu>
      <UserStatusMenu/>
      {topbarRoutes.map(({ path, icon, name }, index) => (
        <Menu.Item key={index} onClick={() => navigate(path)}>
          {icon}
          <span className="nav-text">{name}</span>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Layout style={{ height: '100%', width: '100%', position: 'absolute', top: 0, bottom: 0 }}>
      <Sider
        theme={theme}
        collapsed={collapsed}
        collapsedWidth="0"
        onBreakpoint={(broken) => {  }}
      >
        <div className="logo">
          <img src={`/logo-${theme}.png`} width="100%" alt="logo"/>
        </div>
        <Menu
          mode="inline"
          selectedKeys={getPathIndex(sidebarRoutes, pathname)}
        >
          {sidebarRoutes.map(({ path, icon, name, badge}, index) => (
            <Menu.Item key={index} onClick={() => navigate(path)}>
              {icon}
              <span className="nav-text">{name}</span>
              {badge ? badge() : null}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout style={{ padding: 0, margin: 0 }}>
        <PageHeader className="page-header" ghost={false}>
          <Icon
            component={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
            onClick={toggle}
            className='page-header-collapse-button'
          />
          <Divider type="vertical" />
          <Routes>
            {routes.map(({ path, icon, name }, index) => (
              <Route path={path} key={index}
                element={
                  <span className="page-header-title">
                    {icon}
                    &nbsp;
                    {name}
                  </span>
                }
              />
            ))}
          </Routes>
          <div className='page-header-right-menu'>
            <Divider type="vertical" />
            <Dropdown overlay={menu}>
              <div style={{ height: 'calc(100% - 4px)', display: 'inline-block' }}>
                <UserOutlined className='user-button'/>
                <span style={{paddingRight: '10px'}}>{userName}</span>
              </div>
            </Dropdown>
          </div>
        </PageHeader>
        <Content style={{ overflow: 'auto' }}>
          <div style={{ margin: '24px 16px 24px', padding: 24, minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default App
