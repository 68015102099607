import React from 'react'
import { Result } from 'antd'

export const FileNotFound = () => {
  return (
    <Result
      status='404'
      title='404'
      subTitle='Sorry, the page you attempted to access could not be found.'
    />
  )
}

export default FileNotFound
