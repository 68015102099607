import React from 'react'
import {
  Row,
  Col,
} from 'antd'
import { useSelector } from 'react-redux'

import OpportunityTable from './OpportunityTable'
import Control from './Control'
import UserStatus from './UserStatus'

function Trader() {
  return (
    <Row>
      <Col span={24} style={{ padding: 10 }}>
        <UserStatus />
      </Col>
      <Col span={24} style={{ padding: 10 }}>
        <Control />
      </Col>
      <Col span={24} style={{ padding: 10 }}>
        <OpportunityTable />
      </Col>
    </Row>
  )
}

export default Trader 
