import React from 'react'
import {
  Row,
  Col,
} from 'antd'

import InstrumentsTable from './InstrumentsTable'
import StreamsTable from './StreamsTable'
import TradeConfigTable from './TradeConfigTable'
import ResolveConfigTable from './ResolveConfigTable'
import VolatilityTable from './VolatilityTable'

function Settings() {
  return (
    <Row>
      <Col span={24} style={{ padding: 10 }}>
        <VolatilityTable/>
      </Col>
      <Col xs={24} xxl={12} style={{ padding: 10 }}>
        <TradeConfigTable />
      </Col>
      <Col xs={24} xxl={12} style={{ padding: 10 }}>
        <ResolveConfigTable />
      </Col>
      <Col span={24} style={{ padding: 10 }}>
        <StreamsTable/>
      </Col>
    </Row>
  )
}

export default Settings 
