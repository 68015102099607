import React from 'react'
import {
  Row,
} from 'antd'

import OpportunityTable from './OpportunityTable'

function Debug() {
  return (
    <Row>
      <OpportunityTable />
    </Row>
  )
}

export default Debug 
