import React from 'react'
import gql from 'graphql-tag'
import { notification } from 'antd'
import { useSubscription } from '@apollo/react-hooks'
import { useSelector } from 'react-redux'

import { getNotification } from 'selectors/Notification'
import { tradeSound, errorSound, stopSound } from 'sounds'
import { branchSelector, useChange, useDebounce } from 'utils/misc'

const ERROR_REPORT_SUBSCRIPTION = gql`
subscription {
  error_report(
    order_by: { id: desc }
    limit: 1
  ) {
    id
  }
}
`

const ORDER_SUBSCRIPTION = gql`
subscription {
  order(
    where: { operation_id: { _is_null: false } }
    order_by: { created_at: desc }
    limit: 1
  ) {
    id
    lot
    stream_instrument {
      id
      name
      troupe {
        id
        name
      }
    }
  }
}
`


const STATE_SUBSCRIPTION = gql`
subscription {
  state(where: { troupe_id: { _is_null: false } }) { 
    type
    troupe {
      id
      name
    }
  }
}
`


function NotificationManager() {
  const notificationBoolean = useSelector(getNotification)
  const { data: { error_report } = { } } = useSubscription(ERROR_REPORT_SUBSCRIPTION)
  const { data: { order } = { } } = useSubscription(ORDER_SUBSCRIPTION)
  const { data: { state } = { } } = useSubscription(STATE_SUBSCRIPTION)
  const debouncedState = useDebounce(state, 7000)

  useChange(
    error_report,
    ([error_report]) => {
      if (notificationBoolean.error) {
        notification.open({
          message: 'Error',
          description: `Report #${error_report ? error_report.id : null}.`,
        })
        errorSound.play()
      }
    },
    (x) => Array.isArray(x)
  )

  useChange(
    order,
    ([order]) => {
      if (notificationBoolean.trade) {
        notification.open({
          key: order.id,
          message: `Filled: `,
          description: (<>
            Trade Group: {order.stream_instrument.troupe.name}<br />
            Order #{order.id}
          </>),
        })
        tradeSound.play()
      }
    },
    (x) => Array.isArray(x)
  )

  useChange(
    debouncedState,
    (state, previousState) => {
      const previousStateTable = Object.fromEntries(
        previousState.map(({ type, troupe: { id } }) => [id, type])
      )
      state.forEach(({ type, troupe: { id, name } }) => {
        const previous = previousStateTable[id]
        if (notificationBoolean.stop) {
          if (type === 'stop' && previous !== 'stop') {
            notification.open({
              key: id,
              message: `Stopped: ${name}`,
              description: `${previous} -> stop`,
            })
            stopSound.play()
          }
        }
      })
    },
    (x) => Array.isArray(x)
  )

  return null
}

export default NotificationManager
