import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import { useQuery, useSubscription, useMutation } from '@apollo/react-hooks'
import { Mutation } from 'react-apollo'
import SubscriptionTable from 'components/SubscriptionTable'
import {
  Table,
  Col,
  Divider,
  Popconfirm,
  AutoComplete,
  Modal,
  Button,
  Select,
  InputNumber,
  Row,
  Form,
  Input,
} from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import debounce from "lodash/debounce"
const { Option } = Select


const SYMBOL = gql`
subscription {
  symbol {
    id
    key: id
    name
  }
}
`

const INSTRUMENTS = gql`
subscription {
  instrument (where: {is_visible: {_eq: true}}) {
    id
    key: id
    name
    quote_symbol {
      id
      key: id
      value: name
      name
    }
    base_symbol {
      id
      key: id
      value: name
      name
    }
    decimal_pip
  }
}
`

const STREAM_INSTRUMENTS = gql`
subscription {
  stream_instrument (where: {is_visible: {_eq: true}}, order_by:{instrument: {name: asc}} ) {
    id
    key: id
    name
    maturity_date
    multiplier
    max_volume
    instrument{
      key: id
      id
      name
    }
    stream_id
    stream{
      id
      key: id
      account_name
      connector {
        id
        name
      }
    }
  }
}
`
const CREATE_SYMBOL = gql`
mutation create_symbol($name: String!) {
  insert_symbol(
    objects: {name: $name},
    on_conflict: {
      constraint: symbol_name_key,
      update_columns: [
        name,
      ]
  }) {
    returning {
      id
      name
    }
  }
}
`

const CREATE_INSTRUMENT = gql`
mutation create_instrument($name: String!) {
  insert_instrument(
    objects: {name: $name, is_visible: true},
    on_conflict: {
      constraint: instrument_name_key,
      update_columns: [
        name,
        is_visible
      ]
  }) {
    returning {
      id
      name
      is_visible
    }
  }
}
`

const STREAMS = gql`
subscription {
  stream {
    id
    account_name
    connector {
      id
      name
    }
  }
}
`

const SEARCH_SYMBOL = gql`
query searchSymbol($searchTerm: String!) {
  symbol(where: {name: {_like: $searchTerm}}) {
    id
    value: name
  }
}
`

const UPDATE_INSTRUMENT = gql`
mutation ($id: Int!, $baseId: Int, $quoteId: Int, $decimalPip: Int) {   
  update_instrument (
    where: {id: {_eq: $id}}, _set: {
      base_symbol_id: $baseId,
      quote_symbol_id: $quoteId
      decimal_pip: $decimalPip
    }){
    returning {
      id
      name
      quote_symbol {
        id
        name
      }
      base_symbol {
        id
        name
      }
      decimal_pip
    }
  }
}
`
const CREATE_STREAM_INSTRUMENT = gql`
mutation ($input: [stream_instrument_insert_input!]!){
  insert_stream_instrument(objects: $input,
    on_conflict: {constraint: stream_instrument_instrument_id_stream_id_maturity_date_key,
    update_columns: [name, is_visible, stream_id, maturity_date, multiplier, instrument_id]}){
    returning {
      id
      name
      stream_id
      maturity_date
      multiplier
      max_volume
      instrument_id
      is_visible
    }
  }
} 
`

const UPDATE_STREAM_INSTRUMENT = gql`
mutation ($id: Int!, $name: String!, $stream_id: Int!, $maturity: String, $multiplier: Int!, $max_volume: Int!, $instrument_id: Int!) {
  update_stream_instrument(where: {id: {_eq: $id}}, _set: {name: $name, stream_id: $stream_id, maturity_date: $maturity, multiplier: $multiplier, max_volume: $max_volume, instrument_id: $instrument_id}) {
    returning {
      id
      name
      stream_id
      maturity_date
      multiplier
      max_volume
      instrument_id
      is_visible
    }
  }
}
`

const DELETE_INSTRUMENT = gql`
mutation ($id: Int!) {
  update_instrument(where: {id: {_eq: $id}}, _set: {is_visible: false}) {
    returning {
      id
    }
  }
}
`
const DELETE_STREAM_INSTRUMENT = gql`
mutation ($id: Int!) {
  update_stream_instrument(where: {id: {_eq: $id}}, _set: {is_visible: false}) {
    returning {
      id
      is_visible
    }
  }
}
`

const TROUPE_SUBSCRIPTION = gql`
subscription trade_groupes {
  troupe (where: {is_visible: {_eq: true}}, order_by: {priority: asc}) {
    id
    name
    key: priority
    mt_name
  }
}
`

const UPDATE_TROUPE_MT_MAP = gql`
mutation($troupe_id: Int!, $mt_name: String!) {
  update_troupe(where: {id: {_eq: $troupe_id}}, _set: {mt_name: $mt_name}) {
    returning{
      id
      name
      mt_name
    }
  }
}
`

function AddInput({ onSubmit, ...restProps }) {
  const [value, setValue] = useState('')

  return (
    <Input.Search
      {...restProps}
      value={value}
      enterButton={<PlusOutlined />}
      onChange={({ target: { value } }) => { setValue(value) }}
      onSearch={value => {
        onSubmit && onSubmit(value)
        setValue('')
      }}
    />
  )
}


const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  onSearch,
  onSelect,
  setSearch,
  options,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          key={title + '-' + record.id}
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          { title === 'Decimal Pip' ?
            <Input />
            :
            <AutoComplete
              onSelect={(value, option) => onSelect(value, option, title )}
              onSearch={(e) => onSearch(e)}
              onFocus={() => setSearch({searchTerm: ''})}
            >
              {options.map((option) => (
                <AutoComplete.Option key={option.id} value={option.value} id={option.id} />
              ))}
            </AutoComplete>
          }
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const TroupeMtNameMap = () => {
  const { data, loading } = useSubscription(TROUPE_SUBSCRIPTION)
  const [updateTroupeMtMap] = useMutation(UPDATE_TROUPE_MT_MAP)
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  
  const isEditing = (record) => record.id === editingKey

  const edit = (record) => {
    form.setFieldsValue({ ...record })
    setEditingKey(record.id)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const save = async (key) => {
    try {
      const row = await form.validateFields()
      await updateTroupeMtMap({
        variables: {
          troupe_id: key,
          mt_name: row.mt_name,
        },
      })
      setEditingKey('')
    } catch (err) {
      console.log('Save failed:', err)
    }
  }

  const troupeColumns = [
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <a
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <a disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </a>
        )
      },
    },
    {
      title: 'Troupe',
      dataIndex: 'name',
    },
    {
      title: 'MT Name',
      dataIndex: 'mt_name',
      editable: true,
    },
  ]

  const mergedColumns = troupeColumns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  if (loading) return <span>Loading...</span>

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data.troupe}
        columns={mergedColumns}
        rowClassName="editable-row"
        rowKey="id"
        pagination={false}
      />
    </Form>
  )
}

const StreamProfile = () => {
  const instrument_subscription = useSubscription(INSTRUMENTS)
  const symbol_subscription = useSubscription(SYMBOL)
  const stream_instrument_subscription = useSubscription(STREAM_INSTRUMENTS)
  const stream_subscription = useSubscription(STREAMS)
  const [createInstrument] = useMutation(CREATE_INSTRUMENT)
  const [createSymbol] = useMutation(CREATE_SYMBOL)
  const [updateInstrument] = useMutation(UPDATE_INSTRUMENT)
  const [createStreamInstrument] = useMutation(CREATE_STREAM_INSTRUMENT)
  const [updateStreamInstrument] = useMutation(UPDATE_STREAM_INSTRUMENT)

  const [search, setSearch] = useState({searchTerm: ''})
  const symbolResult = useQuery(SEARCH_SYMBOL, {variables: {...search}})
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  const [symbolUpdates, setSymbolUpdates] = useState({})
  const setSearchDebounced = debounce(setSearch, 500)
  const [modal, contextHolder] = Modal.useModal()
  const [visible, setVisible] = useState(false) 
  const [modalForm] = Form.useForm()
  const [modalOptions, setModalOptions] = useState([])
  const [instrumentId, setInstrumentId] = useState(-1)
  const [streamInstrumentId, setStreamInstrumentId] = useState(-1)
  const [isAdding, setIsAdding] = useState(true)

  const showModal = () => setVisible(true) 

  const hideModal = () => {
    setVisible(false) 
    setIsAdding(true) 
    setInstrumentId(-1) 
    setStreamInstrumentId(-1) 
  }

  const isEditing = (record) => record.key === editingKey

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    })
    setEditingKey(record.key)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const save = async (key) => {
    const row = await form.validateFields()
    const response = await updateInstrument({
      variables: {
        id: editingKey,
        quoteId: symbolUpdates.quoteId ? symbolUpdates.quoteId : form.getFieldValue('quote_symbol').id,
        baseId:  symbolUpdates.baseId ? symbolUpdates.baseId : form.getFieldValue('base_symbol').id,
        decimalPip: form.getFieldValue('decimal_pip') 
      }
    })

    setSymbolUpdates({})
    setEditingKey('')
  }

  const onSelect = (value, option, title) => {
    title === 'Quote' ? symbolUpdates.quoteId = option.id : symbolUpdates.baseId = option.id
  }

  const onSearch = async (searchText) => {
    setSearchDebounced({searchTerm: '%' + searchText.toUpperCase() + '%'})
  }

  const onFinish = async (values) => {
    let response = {}
    if(isAdding) {
      const input = values.stream.map(streamId => ({
        stream_id: streamId,
        name: values.name,
        maturity_date: values.maturity_date ? values.maturity_date : '',
        multiplier: values.multiplier,
        instrument_id: instrumentId,
        is_visible: true
      }))
      response = await createStreamInstrument({
        variables: {
          input: input
        }
      }) 
    } else {
      response = await updateStreamInstrument({
        variables: {
          id: streamInstrumentId,
          name: values.name,
          stream_id: values.stream,
          maturity: values.maturity_date ? values.maturity_date : '',
          multiplier: values.multiplier,
          max_volume: values.max_volume,
          instrument_id: instrumentId
        }
      })
    }
    form.resetFields()
    setVisible(false)
    setIsAdding(true) 
    setInstrumentId(-1) 
    setStreamInstrumentId(-1) 
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const onSearchModal = (searchText) => {
    setModalOptions(instrument_subscription.data.instrument.filter(option => option.name.includes(searchText.toUpperCase())))
  }

  const checkValue = () => {
    console.log(modalForm.getFieldValue('stream'))
  } 

  useEffect(() => {
    if (!instrument_subscription.loading && !modalOptions) {
      setModalOptions(instrument_subscription.data.instrument) 
    }
    checkValue()
  }, [instrument_subscription])

  if (symbol_subscription.loading || instrument_subscription.loading || stream_subscription.loading) {
    return <span>Loading ...</span>
  }

  const symbolFilters = symbol_subscription.data.symbol.map((symbol) => { return {text: symbol.name, value: symbol.name}})
  const instrumentFilters = instrument_subscription.data.instrument.map((instrument) => { return {text: instrument.name, value: instrument.name}})
  const streamFilter = stream_subscription.data.stream.map((stream) => { 
    const name = stream.account_name ? stream.account_name : stream.connector.name
    return  {text: name, value: name}
  }) 

  const symbol_columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      filters: symbolFilters,
      onFilter: (value, record) => record.name.startsWith(value),
      filterMode: 'menu',
      filterSearch: true,
      width: '40%',
    },
  ]

  const instrument_columns = [
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <a
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Mutation mutation={DELETE_INSTRUMENT}>
              {(deleteInstrument) => (
                <Popconfirm
                  title="Are you sure to delete this instrument?"
                  onConfirm={
                    () => {
                      deleteInstrument({
                        variables: {
                          id: record.key,
                        }
                      })
                      setEditingKey('')
                    }
                  }
                  onCancel={() => console.log('close Modal')}
                  okText="Yes"
                  cancelText="No"
                >
                  <a
                    style={{
                      marginRight: 8,
                    }}
                  >
                      Delete 
                  </a>
                </Popconfirm>
              )}
            </Mutation>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <a disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </a>
        )
      },
    },
    {
      title: 'Name',
      filters: instrumentFilters,
      onFilter: (value, record) => record.name.startsWith(value),
      filterMode: 'menu',
      filterSearch: true,
      dataIndex: 'name',
    },
    {
      title: 'Base',
      dataIndex: 'base_symbol',
      editable: true,
      render: (x) => x ? x.name : '',
    },
    {
      title: 'Quote',
      dataIndex: 'quote_symbol',
      editable: true,
      render: (x) => x ? x.name : '',
    },
    {
      title: 'Decimal Pip',
      dataIndex: 'decimal_pip',
      editable: true,
    },
  ]


  const merged_instrument_columns = instrument_columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        setSearch: setSearch,
        onSelect: onSelect,
        onSearch: onSearch,
        options: symbolResult.data ? symbolResult.data.symbol : [],
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const stream_instrument_columns = [
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => 
        <span>
          <Mutation mutation={DELETE_STREAM_INSTRUMENT}>
            {(deleteStreamInstrument) => (
              <Popconfirm
                title="Are you sure to delete this stream instrument?"
                onConfirm={ () => {
                  deleteStreamInstrument({
                    variables: {
                      id: record.key,
                    }
                  })
                }
                }
                onCancel={() => console.log('close Modal')}
                okText="Yes"
                cancelText="No"
              >
                <a
                  style={{
                    marginRight: 8,
                  }}
                >
                    Delete 
                </a>
              </Popconfirm>
            )}
          </Mutation>
          <a
            style={{
              marginRight: 8,
            }}
            onClick={() => {
              setIsAdding(false)
              modalForm.setFieldsValue({
                name: record.name,
                stream: record.stream.id,
                maturity_date: record.maturity_date,
                multiplier: record.multiplier,
                max_volume: record.max_volume,
                instrument: record.instrument.name
              })
              setStreamInstrumentId(record.id) 
              setInstrumentId(record.instrument.id) 
              showModal()
            }}
          >
            Edit 
          </a>
        </span>
      ,
    },
    {
      title: 'Stream',
      dataIndex: 'stream',
      filters: streamFilter,
      onFilter: (value, record) => {
        const stream = record.stream
        const name = stream.account_name ? stream.account_name : stream.connector.name
        return name.startsWith(value) 
      },
      render: (x) => x.connector.name + (x.account_name === '' ? '' : '-') + x.account_name,
    },
    {
      title: 'Stream Instrument Name',
      dataIndex: 'name',
    },
    {
      title: 'Max Trade Szie',
      dataIndex: 'max_volume',
      render: (max_volume) => max_volume.toLocaleString(),
    },
    {
      title: 'Maturity',
      dataIndex: 'maturity_date',
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
    },
    {
      title: 'Instrument',
      dataIndex: 'instrument',
      filters: instrumentFilters,
      onFilter: (value, record) => record.name.startsWith(value),
      filterMode: 'menu',
      filterSearch: true,
      render: (x) => x.name,
    },
  ]

  const onSelectAll = (selectAll) => {
    modalForm.setFieldsValue({
      stream: selectAll ? stream_subscription.data.stream.map(option => option.id) : []
    })
  }

  return (
    <Row gutter={16}>
      <Col span={12}>
        <SubscriptionTable
          title={() => <h3>Symbols</h3>}
          subscription={symbol_subscription}
          pagination={true}
          rowKey='name'
          size='small'
          showHeader={true}
          columns={symbol_columns}
          footer={() => (
            <AddInput
              size='small'
              onSubmit={name => {
                createSymbol({
                  variables: {
                    name,
                  }
                })
              }}
            />)}
        />
      </Col>
      <Col span={12}>
        <Form form={form} component={false}>
          <SubscriptionTable
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            title={() => <h3>Instruments</h3>}
            subscription={instrument_subscription}
            pagination={true}
            rowKey='name'
            size='small'
            showHeader={true}
            columns={merged_instrument_columns}
            footer={() => (
              <AddInput
                size='small'
                onSubmit={name => {
                  createInstrument({
                    variables: {
                      name,
                    }
                  })
                }}
              />)}
          />
        </Form>
      </Col>
      <Col span={24}>
        <SubscriptionTable
          subscription={stream_instrument_subscription}
          title={() => { return (
            <div style={{marginBottom: 30}}>
              <div style={{ float: 'left' }}>
                <h3>Stream Instruments</h3>
              </div>
              <div style={{ float: 'right' }}>
                <Button
                  type='primary'
                  onClick={ () => {
                    showModal()
                    modalForm.resetFields()
                  }}
                >
                  Add Stream Instrument 
                </Button>
              </div>
            </div>
          )}}
          pagination={false}
          rowKey='id'
          size='small'
          showHeader={true}
          columns={stream_instrument_columns}
          footer={() => 
            <div style={{ textAlign: 'right' }}>
              <Button
                type='primary'
                onClick={ () => {
                  showModal()
                  modalForm.resetFields()
                }}
              >
                Add Stream Instrument 
              </Button>
            </div>
          }
        />
        <Modal
          title='Add Stream Instrument'
          width='35%'
          visible={visible}
          onOk={() => {
            modalForm
              .validateFields()
              .then((values) => {
                modalForm.resetFields()
                onFinish(values)
              })
              .catch((info) => {
                console.log('Validate Failed:', info)
              })
          }}
          closable={false}
          onCancel={hideModal}
          okText='Add'
          cancelText='Cancel'
        >
          <Form form={modalForm} onFinish={onFinish} component={false} onFinishFailed={onFinishFailed} initialValues={{stream: []}} >
            <Form.Item label='Stream' name='stream'
              rules={[
                {
                  required: true,
                  message: 'Please input stream',
                },
              ]} >
              <Select
                mode='multiple'
                dropdownRender={menu => (
                  <>
                    <div
                      style={{ padding: '8px', cursor: 'pointer' }}
                      onMouseDown={(e) => e.preventDefault()}
                      // Prevent the dropdown from being closed
                      onClick={() => {
                        const values = modalForm.getFieldValue('stream')
                        onSelectAll(values.length < stream_subscription.data.stream.length)
                      }}
                    >
                      {modalForm?.getFieldValue('stream').length < stream_subscription.data.stream.length ? 'Select All' : 'Deselect All'}
                    </div>
                    {menu}
                  </>
                )}
              >
                {
                  stream_subscription.data.stream.map((stream) => 
                    <Option key={stream.id} value={stream.id}>{stream.connector.name + (stream.account_name === '' ? '' : '-') + stream.account_name}</Option>
                  ) 
                }
              </Select>
            </Form.Item>
            <Form.Item label="Stream Insturment Name" name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input stream instrument name',
                },
              ]} >
              <Input />
            </Form.Item> 
            <Form.Item label='Maturity' name='maturity_date' >
              <Input />
            </Form.Item> 
            <Form.Item label='Multiplier' name='multiplier'
              rules={[
                {
                  required: true,
                  message: 'Please input multiplier',
                },
              ]} >
              <InputNumber />
            </Form.Item> 
            <Form.Item label='Max Trade Size' name='max_volume'
              rules={[
                {
                  required: true,
                  message: 'Please input max trade size',
                },
              ]} >
              <InputNumber />
            </Form.Item> 
            <Form.Item label='Instrument' name='instrument'
              rules={[
                {
                  required: true,
                  message: 'Please input instrument',
                },
              ]} >
              <AutoComplete onSearch={onSearchModal} onSelect={
                (value, option) => {
                  setInstrumentId(option.id) 
                }
              }>
                {modalOptions.map((option) => (
                  <AutoComplete.Option key={option.key} value={option.name} id={option.id} />
                ))}
              </AutoComplete>
            </Form.Item> 
          </Form>
        </Modal>
      </Col>
      <Divider />
      <Col span={24}>
        <TroupeMtNameMap />
      </Col>
    </Row>
  )
}

export default StreamProfile 
